import React, { useState } from 'react';
import { FormControlLabel } from '@material-ui/core';
import { useFetch } from 'use-http';

import { ApiRoutes } from 'constants/api';
import { Checkbox } from 'ui-components/v2/Checkbox';
import { isFeatureEnabled } from 'utils/featureFlags';
import InfoPopover from 'components/InfoPopover/InfoPopover';

import {
  LivstickCartLine,
  LivstickPrice,
  LivstickInfoContainer,
} from './styled';

interface LivstickVideoCheckProps {
  sessionId: string;
  livstickChecked: boolean;
  deliveryDate: string;
  cartId: number;
  formAuthenticityToken: string;
}
interface LivstickCheckResponse {
  livstick_video?: boolean;
  message?: string;
}

const LivstickVideoCheck = (props: LivstickVideoCheckProps) => {
  const {
    sessionId,
    livstickChecked,
    deliveryDate,
    cartId,
    formAuthenticityToken,
  } = props;
  const [isChecked, setIsChecked] = useState(livstickChecked);
  const { patch } = useFetch<LivstickCheckResponse>(
    ApiRoutes.LivstickVideoCheck,
    {
      headers: {
        'X-CSRF-Token': formAuthenticityToken,
      },
    },
  );

  const handleCheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setIsChecked(checked);
    patch({
      cart: {
        session_id: sessionId,
        livstick_video: checked,
        delivery_date: deliveryDate,
        id: cartId,
      },
    }).then((res) => {
      if (!res.message) {
        window.location.reload();
      }
    });
  };

  return (
    <LivstickCartLine>
      <LivstickInfoContainer
        className={isFeatureEnabled('newCart') ? 'mb-6' : 'my-6'}
      >
        <FormControlLabel
          control={
            <Checkbox
              className="!h-auto !py-0"
              checked={isChecked}
              onChange={handleCheck}
            />
          }
          label={
            <div className="flex">
              <b className="text-[15px]">
                Add Personalized Video Message For $2.95
              </b>
              <InfoPopover text="Once your order has been placed, we’re unable to add, remove, or make any changes to your personalized video selection." />
            </div>
          }
        />
        <span className="px-7 text-justify leading-normal max-sm:text-sm">
          Add a special touch to your order by adding a digitally-delivered
          personalized video message (recorded by you!) to your order.
          <br />
          We’ll share steps on how to record this after you complete your
          purchase.
        </span>
      </LivstickInfoContainer>
      {!isFeatureEnabled('newCart') && isChecked ? (
        <LivstickPrice className="price-basket livstick_price">
          $2.95
        </LivstickPrice>
      ) : null}
    </LivstickCartLine>
  );
};

export default LivstickVideoCheck;
