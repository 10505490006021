/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useEffect } from 'react';
import useFetch from 'use-http';
import { Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';

import { Button } from 'ui-components/v2/Button';
import { Radio } from 'ui-components/v2/Radio';

import Modal from '../Modal';

import {
  AddressListContainer,
  AddressSectionHeading,
  DisclaimerText,
  FormControlLabelStyled,
  LinkStyled,
  RadioGroupStyled,
  SelectButtonContainer,
} from './styled';
import {
  AddressPost,
  AddressSuggestionResponse,
  UserFormValues,
} from './interface';

interface IAddressVerificationModalProps {
  show: boolean;
  nothingToSuggest: boolean;
  authenticity_token: string;
  userFormValues: UserFormValues;
  suggestionResponse: AddressSuggestionResponse;
}

type AddressChoice = 'entered' | 'verified';

interface FormValues {
  verifyAddress: AddressChoice;
  stickWithEntered: boolean;
}

// eslint-disable-next-line max-len
const CustomerAddressVerificationModal = ({
  show,
  nothingToSuggest,
  authenticity_token,
  userFormValues,
  suggestionResponse,
}: IAddressVerificationModalProps) => {
  const { state, postcode, street_1, street_2, city } =
    suggestionResponse || {};

  const { put, loading } = useFetch<any>();

  const [showModal, setShowModal] = useState(show);
  const [errorSubmitting, setErrorSubmitting] = useState('');
  const { control, watch, setValue } = useForm<FormValues>({
    defaultValues: {
      verifyAddress: 'verified',
      stickWithEntered: false,
    },
  });

  const handleSelectClick = async () => {
    // if (!suggestionResponse) return;
    setErrorSubmitting('');

    let addressToPost = {
      authenticity_token,
      id: String(userFormValues?.id),
      sales_order: {
        delivery_date: userFormValues?.delivery_date,
        card_message: userFormValues?.card_message,
        shipping_address_attributes: {
          id: userFormValues?.shipping_address_attributes.id,
          first_name: userFormValues?.shipping_address_attributes.first_name,
          last_name: userFormValues?.shipping_address_attributes.last_name,
          street_1: suggestionResponse.street_1,
          street_2: suggestionResponse.street_2,
          city: suggestionResponse.city,
          region: suggestionResponse.state,
          postcode: suggestionResponse.postcode,
          phone: userFormValues?.shipping_address_attributes.phone,
          business_name:
            userFormValues?.shipping_address_attributes.business_name,
        },
        customer_attributes: {
          email: userFormValues?.customer_attributes.email,
          id: userFormValues?.customer_attributes.id,
        },
      },
      keep_or_use: 'use',
    } as AddressPost;

    if (watch()?.stickWithEntered) {
      addressToPost = {
        ...addressToPost,
        sales_order: {
          ...addressToPost.sales_order,
          shipping_address_attributes: {
            ...addressToPost.sales_order.shipping_address_attributes,
            street_1: userFormValues?.shipping_address_attributes?.street_1,
            street_2: userFormValues?.shipping_address_attributes?.street_2,
            city: userFormValues?.shipping_address_attributes?.city,
            region: userFormValues?.shipping_address_attributes?.region,
            postcode: userFormValues?.shipping_address_attributes?.postcode,
            business_name:
              userFormValues?.shipping_address_attributes?.business_name,
          },
        },
        keep_or_use: 'keep',
      };
    }

    await put(
      `/account/sales_orders/${userFormValues?.id}`,
      addressToPost,
    ).then((res: { redirect_to: string; error: any }) => {
      if (res?.error) {
        setErrorSubmitting(res?.error);
      } else {
        window.location.href = '/account/open_orders';
      }
    });
  };

  useEffect(() => {
    if (nothingToSuggest) {
      setValue('stickWithEntered', true);
    }
  }, [nothingToSuggest]);

  return (
    <Modal
      handleClose={() => setShowModal(false)}
      show={showModal}
      title="Please Verify Address"
    >
      {show && !nothingToSuggest && (
        <>
          <Typography variant="body2" paragraph>
            We’re unable to verify your shipping address. Please select the
            verified address below or proceed with your entered address.
          </Typography>
          <Controller
            control={control}
            name="verifyAddress"
            render={({ field }) => (
              <RadioGroupStyled {...field} defaultValue="verified">
                <div>
                  <AddressSectionHeading>
                    verified address:
                  </AddressSectionHeading>
                  <FormControlLabelStyled
                    value="verified"
                    control={
                      <Radio
                        onChange={() => setValue('stickWithEntered', false)}
                      />
                    }
                    label={
                      <AddressListContainer>
                        <Typography variant="body2">{street_1}</Typography>
                        {street_2 && (
                          <Typography variant="body2">{street_2}</Typography>
                        )}
                        <Typography variant="body2">{city}</Typography>
                        <Typography variant="body2">
                          {state} {postcode}
                        </Typography>
                      </AddressListContainer>
                    }
                  />
                </div>
                <div>
                  <AddressSectionHeading>
                    entered address:
                  </AddressSectionHeading>
                  <FormControlLabelStyled
                    value="entered"
                    control={
                      <Radio
                        onChange={() => setValue('stickWithEntered', true)}
                      />
                    }
                    label={
                      <AddressListContainer>
                        <Typography variant="body2">
                          {
                            userFormValues?.shipping_address_attributes
                              ?.street_1
                          }
                        </Typography>
                        {userFormValues?.shipping_address_attributes
                          ?.street_2 && (
                          <Typography variant="body2">
                            {
                              userFormValues?.shipping_address_attributes
                                ?.street_2
                            }
                          </Typography>
                        )}
                        <Typography variant="body2">
                          {userFormValues?.shipping_address_attributes?.city}
                        </Typography>
                        <Typography variant="body2">
                          {userFormValues?.shipping_address_attributes?.region}{' '}
                          {
                            userFormValues?.shipping_address_attributes
                              ?.postcode
                          }
                        </Typography>
                        <LinkStyled
                          variant="body2"
                          color="primary"
                          onClick={() => setShowModal(false)}
                        >
                          EDIT
                        </LinkStyled>
                      </AddressListContainer>
                    }
                  />
                </div>
              </RadioGroupStyled>
            )}
          />
          <DisclaimerText variant="body2">
            <b>PLEASE NOTE:</b> Unverified addresses may lead to delivery delays
            while parcel carriers confirm the recipient’s address.
          </DisclaimerText>
        </>
      )}
      {show && nothingToSuggest && (
        <>
          <Typography variant="body2" paragraph>
            We’re unable to verify your shipping address. If you’d like us to
            proceed with this address as entered, click “Continue” below.
          </Typography>
          <AddressListContainer>
            <AddressSectionHeading>entered address:</AddressSectionHeading>
            <Typography variant="body2">
              {userFormValues?.shipping_address_attributes.street_1}
            </Typography>
            {userFormValues?.shipping_address_attributes.street_2 && (
              <Typography variant="body2">
                {userFormValues?.shipping_address_attributes.street_2}
              </Typography>
            )}
            <Typography variant="body2">
              {userFormValues?.shipping_address_attributes.city}
            </Typography>
            <Typography variant="body2">
              {userFormValues?.shipping_address_attributes.region}{' '}
              {userFormValues?.shipping_address_attributes.postcode}
            </Typography>
            <LinkStyled
              variant="body2"
              color="primary"
              onClick={() => setShowModal(false)}
            >
              EDIT
            </LinkStyled>
          </AddressListContainer>
          <DisclaimerText variant="body2">
            <b>PLEASE NOTE:</b> Unverified addresses may lead to delivery delays
            while parcel carriers confirm the recipient’s address.
          </DisclaimerText>
        </>
      )}
      <SelectButtonContainer>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSelectClick}
        >
          {loading ? 'Loading...' : 'Continue'}
        </Button>
      </SelectButtonContainer>
      {errorSubmitting && (
        <Typography variant="body2" color="error">
          {errorSubmitting}
        </Typography>
      )}
    </Modal>
  );
};

export default CustomerAddressVerificationModal;
