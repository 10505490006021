import React from 'react';
import { MenuItem as MUIMenuItem, MenuItemProps } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { COLORS } from 'theme/colors';

export const MenuItem = withStyles(() => ({
  root: {
    color: COLORS.black,
    backgroundColor: COLORS.white,
    cursor: 'pointer',
    '&.Mui-selected': {
      backgroundColor: COLORS.white,
      color: COLORS.black,
      '&&:hover': {
        backgroundColor: COLORS.quicksand,
        color: COLORS.black,
      },
    },
    '&:hover': {
      backgroundColor: COLORS.quicksand,
    },
    '&:focus-visible': {
      border: 'none',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      outline: 'none',
    },
    '& + .MuiList-root.MuiMenu-list.MuiList-padding': {
      padding: 0,
    },
  },
  selected: {},
}))((props: MenuItemProps) => (
  <MUIMenuItem divider {...props} button={undefined} />
));
