import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';

import { down } from 'utils/breakpointHelpers';
import { Button } from 'ui-components/v2/Button';
import { ContainerStyled, ContainerPosition } from './styled';

interface StickyCheckoutProps {
  href: string;
  isCartValid: boolean;
}

const StickyCheckoutButton = ({ href, isCartValid }: StickyCheckoutProps) => {
  const [displayStyle, setDisplay] = useState({ top: -84 });
  const matches = useMediaQuery(down('sm'));

  useEffect(() => {
    const scrollFunction = () => {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        setDisplay({ top: 0 });
      } else {
        setDisplay({ top: -84 });
      }
    };
    window.addEventListener('scroll', scrollFunction);
    return () => window.removeEventListener('scroll', scrollFunction);
  }, []);

  return matches ? (
    <ContainerPosition aria-hidden style={displayStyle}>
      <ContainerStyled>
        <Button
          fullWidth
          href={href}
          disabled={!isCartValid}
          color="primary"
          variant="contained"
        >
          Checkout
        </Button>
      </ContainerStyled>
    </ContainerPosition>
  ) : null;
};

export default StickyCheckoutButton;
