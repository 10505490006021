import React from 'react';
import { Typography } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

import { FiltersButtonProps } from './interface';
import { FiltersButtonContainer, IconContainer } from './styled';

const FiltersButton = ({ show }: FiltersButtonProps) => (
  <FiltersButtonContainer onClick={show}>
    <Typography variant="body2">Filter</Typography>
    <IconContainer>
      <FilterListIcon fontSize="small" />
    </IconContainer>
  </FiltersButtonContainer>
);

export default FiltersButton;
