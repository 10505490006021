import React from 'react';
import { Typography } from '@material-ui/core';
import { useWatch } from 'react-hook-form';

import { FlowerTypeProps } from './interface';
import { CheckboxGroup } from '../CheckboxGroup';
import { FilterGroup } from '../../interface';

export function FlowerType({ control }: FlowerTypeProps) {
  const flowerType = useWatch({
    control,
    name: FilterGroup.FlowerType,
  });

  return (
    <>
      {flowerType && flowerType.length > 0 && (
        <>
          <Typography variant="body1">
            <strong>Flower Type</strong>
          </Typography>
          <CheckboxGroup
            control={control}
            fields={flowerType}
            category={FilterGroup.FlowerType}
          />
        </>
      )}
    </>
  );
}
