import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['tabContent', 'tab'];
  }

  static get values() {
    return {
      defaultTab: { type: Number, default: 0 },
    };
  }

  connect() {
    this.activeClasses = ['opacity-100', 'border-b-black'];
    this.inactiveClasses = ['opacity-70', 'border-b-0'];

    this.showTab(this.defaultTabValue);
  }

  selectTab(event) {
    const index = Number(event.currentTarget.dataset.tabsIndex);
    this.showTab(index);
  }

  showTab(index) {
    this.tabContentTargets.forEach((content, i) => {
      content.classList.toggle('absolute', i !== index);
      content.classList.toggle('invisible', i !== index);
    });

    this.tabTargets.forEach((tab, i) => {
      if (i === index) {
        tab.classList.add(...this.activeClasses);
        tab.classList.remove(...this.inactiveClasses);
      } else {
        tab.classList.remove(...this.activeClasses);
        tab.classList.add(...this.inactiveClasses);
      }
    });
  }
}
