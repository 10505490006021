import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';

import Modal from 'ui-components/v2/Modal';
import { LoaderContainer, VideoUploadPortal, WidgetInModal } from './styled';

interface LivstickWidgetProps {
  cartId: string;
  cartEmail: string;
  livstickAPIKey: string;
  showWidget: boolean;
  setShowWidget: React.Dispatch<React.SetStateAction<boolean>>;
}

const LivstickWidget = ({
  cartId,
  cartEmail,
  livstickAPIKey,
  showWidget,
  setShowWidget,
}: LivstickWidgetProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://s3.livstick.com/livstick-widget/js/bundle.js';
    script.async = true;

    script.setAttribute('id', 'livstick-plugin-script');
    script.setAttribute('data-uid', cartId);
    script.setAttribute('data-email', cartEmail);
    script.setAttribute('data-lang', 'en');
    script.setAttribute('data-key', livstickAPIKey);
    script.setAttribute('data-giftee-mandatory', 'true');

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  setTimeout(() => {
    setHasLoaded(true);
  }, 2500);

  return (
    <Modal
      open={showWidget}
      setOpen={setShowWidget}
      primaryButton={null}
      style={{ width: 'max-content' }}
    >
      <WidgetInModal
        ref={contentRef}
        style={{ minHeight: contentRef.current?.clientHeight }}
      >
        <Typography gutterBottom variant="h2">
          Let's send your message
        </Typography>
        <VideoUploadPortal
          style={
            !hasLoaded ? { visibility: 'hidden', position: 'absolute' } : {}
          }
        >
          <div id="livstick-plugin" />
        </VideoUploadPortal>
        {!hasLoaded ? (
          <LoaderContainer>
            <CircularProgress size={50} />
          </LoaderContainer>
        ) : null}
      </WidgetInModal>
    </Modal>
  );
};

export default LivstickWidget;
