import dayjs, { Dayjs } from 'dayjs';
import { UNAVAILABLE_DAY_DATE_FORMAT } from 'constants/time';
import { dateFormatter } from 'utils/dateHelpers';
import { AvailableDate } from '../interface';

function isDateAvailableHoc(availableDates: AvailableDate[]) {
  return (dateToCheck: Dayjs) => {
    const formattedDateToCheck = dateToCheck.format(
      UNAVAILABLE_DAY_DATE_FORMAT,
    );

    return availableDates.some((obj) => obj.date === formattedDateToCheck);
  };
}

// Get next delivery window dates
export function getNextAvailableDeliveryDate(
  initialDate: Dayjs,
  availableDates: AvailableDate[],
): Dayjs | undefined {
  let nextAvailableDay: Dayjs | undefined;
  const isDateAvailable = isDateAvailableHoc(availableDates);

  // The limit for order from current day is 6 months only
  const maxDaysOffset = 365 / 2;
  let daysCounter = 0;

  for (; daysCounter < maxDaysOffset; daysCounter += 1) {
    const nextDate = initialDate.add(daysCounter, 'day');
    const nextDateDayNumber = nextDate.day();

    // Available deliver dates should be Mon-Sat
    // and should be in available list from backend
    if (
      nextDateDayNumber > 0 &&
      nextDateDayNumber < 7 &&
      isDateAvailable(nextDate)
    ) {
      nextAvailableDay = nextDate;
      break;
    }
  }

  return nextAvailableDay;
}

// Get default value for datepicker
export const getDefaultDatePickerDate = (
  filterDate: string | null,
  availableDates: AvailableDate[],
) => {
  if (!filterDate) return getNextAvailableDeliveryDate(dayjs(), availableDates);

  const filterDateObj = dayjs(dateFormatter(filterDate));
  const isFilterDateAvailable = availableDates.some(
    (obj) => obj.date === filterDateObj.format(UNAVAILABLE_DAY_DATE_FORMAT),
  );

  return isFilterDateAvailable
    ? filterDateObj
    : getNextAvailableDeliveryDate(dayjs(), availableDates);
};

// Deadline of delivery dates window
export function getMaxDeliveryDate(initialDate: Dayjs): Dayjs {
  const weekDayNumber = initialDate.day();

  // Deadline of delivery date can't be on weekends.
  // Unless saturday delivery is enabled and selected date is Friday.
  if (weekDayNumber <= 5) {
    return initialDate.add(1, 'day');
  }

  // Deadline of delivery date can't be on weekends.
  // Assume that user can't select weekends
  return initialDate.add(3, 'day');
}
