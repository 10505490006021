/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';
import DOMPurify from 'dompurify';

export default class extends Controller {
  static get targets() {
    return ['textInput', 'textColor', 'backgroundColor', 'preview'];
  }

  connect() {
    this.updatePreview(); // set initial preview
  }

  textInputTargetConnected(target) {
    target.addEventListener('input', this.updatePreview.bind(this));
  }

  textColorTargetConnected(target) {
    target.addEventListener('input', this.updatePreview.bind(this));
  }

  backgroundColorTargetConnected(target) {
    target.addEventListener('input', this.updatePreview.bind(this));
  }

  updatePreview() {
    this.previewTarget.style.color = this.textColorTarget.value;
    this.previewTarget.style.backgroundColor = this.backgroundColorTarget.value;
    let html = this.textInputTarget.value;
    if (!html || html === '') {
      html = 'Preview will appear here.';
    }
    this.previewTarget.innerHTML = DOMPurify.sanitize(html);
  }
}
