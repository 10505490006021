import React, { useCallback, MouseEventHandler, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandIcon from '@material-ui/icons/Add';
import CollapseIcon from '@material-ui/icons/Remove';

import { DATE_FORMAT } from 'constants/time';
import { getNextAvailableDeliveryDate } from '../helpers/getNextDeliveryDate';
import { getDateLabel } from '../helpers/getDateLabel';
import {
  DialogStyled,
  DialogTitleStyled,
  TypographyStyled,
  AccordionStyled,
  IconButtonStyled,
  AccordionTypographyStyled,
  ButtonStyled,
  LabelStyled,
  BodyTypographyStyled,
  FormTitleTypographyStyled,
  AccordionDetailsStyled,
  AccordionSummaryStyled,
} from './styled';
import { DatesRangePopperProps } from './interface';

function renderBodyText(
  nextDate: Dayjs | undefined,
  initialSelectedDate: Dayjs,
) {
  if (!nextDate) {
    return (
      <>
        Shucks! There's only one delivery window available.
        <br />
        Please select this delivery window or return to the calendar for more
        options.
      </>
    );
  }

  return (
    <>
      These are the closest delivery windows to the date you selected:{' '}
      <strong>{initialSelectedDate.format('dddd, MMMM D')}</strong>.
    </>
  );
}

const DatesRangePopper = ({
  setFormDate,
  initialDate,
  modalOpen,
  toggleModal,
  availableDates,
}: DatesRangePopperProps) => {
  const [selectedWindow, setSelectedWindow] = useState(
    initialDate ? initialDate.format(DATE_FORMAT) : '',
  );
  const [expanded, setExpanded] = useState(false);
  const handleClose = useCallback(() => {
    toggleModal(false);
  }, [toggleModal]);

  /**
   * This callback is intentionally put in click handlers of individual
   * elements rather then whole RadioGroup onChange handler,
   * with intention of catching events when a checkbox is checked but the value has not changed
   * (for example, this happens when you select the same delivery window twice in a row)
   */
  const handleChange: MouseEventHandler<HTMLLabelElement> = (event) => {
    const { value } = event.target as HTMLInputElement;

    if (!value) return;
    setSelectedWindow(value);
  };

  const handleSubmit = () => {
    const newDate = dayjs(selectedWindow, DATE_FORMAT);
    setFormDate(newDate);
    setTimeout(handleClose, 300);
  };

  const nextDate = getNextAvailableDeliveryDate(initialDate, availableDates);

  const headerText = nextDate
    ? 'Which delivery window do you prefer?'
    : 'Limited availability';
  const bodyText = renderBodyText(nextDate, initialDate);

  return (
    <DialogStyled
      open={modalOpen}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') handleClose();
      }}
    >
      <DialogTitleStyled disableTypography>
        <TypographyStyled variant="h5">{headerText}</TypographyStyled>
        <IconButtonStyled size="small" aria-label="Close" onClick={handleClose}>
          <CloseIcon />
        </IconButtonStyled>
      </DialogTitleStyled>
      <DialogContent>
        <BodyTypographyStyled>{bodyText}</BodyTypographyStyled>
        <FormTitleTypographyStyled>
          Please select one:
        </FormTitleTypographyStyled>
        <RadioGroup
          aria-label="deliver-date"
          name="deliver-date"
          value={selectedWindow}
        >
          <FormControlLabel
            value={initialDate ? initialDate.format(DATE_FORMAT) : ''}
            control={<Radio color="primary" />}
            label={<LabelStyled>{getDateLabel(initialDate)}</LabelStyled>}
            onClick={handleChange}
          />
          {nextDate && (
            <FormControlLabel
              value={nextDate ? nextDate.format(DATE_FORMAT) : ''}
              control={<Radio color="primary" />}
              label={<LabelStyled>{getDateLabel(nextDate)}</LabelStyled>}
              onClick={handleChange}
            />
          )}
        </RadioGroup>
        <AccordionStyled elevation={0}>
          <AccordionSummaryStyled
            expandIcon={expanded ? <CollapseIcon /> : <ExpandIcon />}
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <AccordionTypographyStyled>
              <strong>What's a delivery window?</strong>
            </AccordionTypographyStyled>
          </AccordionSummaryStyled>
          <AccordionDetailsStyled>
            <AccordionTypographyStyled paragraph>
              It's a two day window of your choosing when you can expect your
              Farmgirl Flowers order to arrive.
            </AccordionTypographyStyled>
            <AccordionTypographyStyled>
              Psst - Delivery windows are based on a number of factors like
              flower (or plant!) availability and our shipping partners'
              capacity and are subject to sell out.
            </AccordionTypographyStyled>
          </AccordionDetailsStyled>
        </AccordionStyled>
        <ButtonStyled
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          fullWidth
        >
          Confirm
        </ButtonStyled>
      </DialogContent>
    </DialogStyled>
  );
};

export default DatesRangePopper;
