import dayjs from 'dayjs';
import { dateFormatter } from 'utils/dateHelpers';

export const getProductDateText = (
  soonestDate: string,
  filterDate: string | null,
) => {
  const showDate = dayjs(dateFormatter(filterDate || soonestDate)).format(
    'ddd, MMM D',
  );

  return `${filterDate ? 'Deliver on' : 'Soonest Delivery'} ${showDate}`;
};
