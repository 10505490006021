import { Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Button } from 'ui-components/v2/Button';
import { down, up } from 'utils/breakpointHelpers';

export const PageContainer = styled('main')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [down('lg')]: {
    paddingInline: 90,
  },
  [down('sm')]: {
    paddingInline: 5,
  },
});

export const HeaderStyled = styled(Typography)({
  fontSize: 48,
  marginBottom: 40,
  lineHeight: '56px',
  textTransform: 'none',
  fontFamily: 'quincy-cf',
  fontWeight: 500,
  [down('sm')]: {
    marginBottom: 26,
    fontSize: 24,
    lineHeight: '30px',
  },
});

export const SubHeaderStyled = styled(Typography)({
  fontSize: 24,
  marginBottom: 12,
  lineHeight: '28px',
  [down('sm')]: {
    fontSize: 18,
    lineHeight: '24px',
  },
});

export const BodyStyled = styled(Typography)({
  marginBottom: 40,
});

export const ButtonStyled = styled(Button)({
  width: '100%',
  marginBottom: 42,
  [up('sm')]: {
    width: 'auto',
    marginBottom: 56,
    paddingInline: 48,
  },
});

export const CardsHeaderStyled = styled(Typography)({
  fontSize: 24,
  marginBlock: 28,
  lineHeight: '30px',
  textTransform: 'none',
  fontFamily: 'quincy-cf',
  fontWeight: 500,
  [down('sm')]: {
    marginBlock: 16,
    fontSize: 18,
    lineHeight: '22px',
  },
});

export const SliderStyled = styled('div')({
  width: 'calc(100% + 40px)',
});
