import { useEffect, useMemo, useState } from 'react';
import useFetch from 'use-http';

import { ApiRoutes } from 'constants/api';
import { debounce } from 'utils/debounce';

interface ZipCodeApiResult {
  id: number;
  zip_code: string;
  state_code: string;
  city_name: string;
}

export const useZipSearch = () => {
  const [zipCodeInput, setZipCodeInput] = useState<string | null>(null);
  const { data, loading, get } = useFetch<ZipCodeApiResult[]>(
    ApiRoutes.ZipCodes,
  );

  const debounced = useMemo(
    () => debounce((zip: string) => get(`?zip_code=${zip}`), 300),
    [],
  );

  useEffect(() => {
    if (zipCodeInput && zipCodeInput.length >= 2 && zipCodeInput.length <= 5) {
      debounced(zipCodeInput);
    }
  }, [zipCodeInput]);

  const options = useMemo(
    () => data?.map((option) => option.zip_code) || [],
    [data],
  );

  return {
    loading,
    options,
    zipCodeInput,
    setZipCodeInput,
  };
};
