import React, { useRef } from 'react';
import { CloseRounded } from '@material-ui/icons';
import { Collection } from '../Collection';
import { FilterBy } from '../FilterBy';
import { Occasion } from '../Occasion';
import { FlowerType } from '../FlowerType';
import { PriceSelect } from '../PriceSelect';
import { ResetButton } from '../ResetButton';
import { MobileFiltersProps } from './interface';
import {
  FiltersContainer,
  FiltersHeader,
  FiltersModal,
  MainSeparator,
  Separator,
  Title,
  WhiteSpace,
} from './styled';

const MobileFilters = ({
  control,
  reset,
  hide,
  priceRange,
}: MobileFiltersProps) => {
  const ref = useRef(null);

  const closeClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === ref.current) {
      hide();
    }
  };

  return (
    <FiltersModal ref={ref} onClick={(e) => closeClickHandler(e)}>
      <WhiteSpace>
        <FiltersHeader>
          <Title variant="h4">FILTER</Title>
          <ResetButton onClick={reset} />
          <CloseRounded onClick={() => hide()} />
        </FiltersHeader>
        <MainSeparator />
        <FiltersContainer>
          <FilterBy control={control} />
          <Separator />
          <PriceSelect control={control} priceRange={priceRange} />
          <Separator />
          <Occasion control={control} />
          <Separator />
          <Collection control={control} />
          <Separator />
          <FlowerType control={control} />
          <Separator />
        </FiltersContainer>
      </WhiteSpace>
    </FiltersModal>
  );
};

export default MobileFilters;
