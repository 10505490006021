import { ToggleButton as MUIBUtton } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';

import { COLORS } from 'theme/colors';

export const ToggleButton = withStyles(() => ({
  root: {
    boxShadow: 'none',
    height: 38,
    width: '100%',
    color: COLORS.black,
    fontSize: 14,
    lineHeight: '18px',
    '&:hover': {
      boxShadow: 'none',
      color: COLORS.black,
      borderColor: COLORS.black,
      backgroundColor: COLORS.white,
    },
    '&.Mui-selected': {
      color: COLORS.white,
      backgroundColor: COLORS.black,
    },
    '&.Mui-selected:hover': {
      color: COLORS.white,
      backgroundColor: COLORS.black,
    },
  },
}))(MUIBUtton);
