import React from 'react';

import DatePicker from './DeliveryDatePicker';
import { useDeliveryDates } from './useDeliveryDates';
import { CartCalendarProps } from './interface';

const CartCalendar = ({ initialDate }: CartCalendarProps) => {
  const {
    date,
    reactKey,
    unavailableDatesState,
    handleDateChange,
    handleDateAccept,
  } = useDeliveryDates(initialDate);

  return (
    <DatePicker
      date={date}
      mountKey={reactKey}
      onDateChange={handleDateChange}
      onDateAccept={handleDateAccept}
      unavailableDates={unavailableDatesState}
    />
  );
};

export default CartCalendar;
