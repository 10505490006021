import React from 'react';
import Typography from '@material-ui/core/Typography';

import Image from 'ui-components/Image';
import ProductPrice from 'components/ProductPrice';
import { Addon } from 'components/Addons/interface';
import { RootStyled, CardHeaderStyled } from './styled';

const AddonItem = ({
  name,
  price,
  sales_price: salesPrice,
  cloudinary_id: image,
  quantity,
}: Addon) => (
  <RootStyled>
    <Image src={image} alt={name} width="100%" isSquare />
    <CardHeaderStyled variant="body1" noWrap>
      {name}
    </CardHeaderStyled>
    <Typography variant="body1" noWrap>
      Price: <ProductPrice price={price} salesPrice={salesPrice} />
    </Typography>
    <Typography variant="body1">Qty: {quantity}</Typography>
  </RootStyled>
);

export default AddonItem;
