import { Button as MUIBUtton } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { COLORS } from 'theme/colors';
import { down } from 'utils/breakpointHelpers';

export const Button = withStyles(() => ({
  root: {
    boxShadow: 'none',
    padding: '12px 20px',
    height: 46,
    minWidth: 'max-content',
    [down('sm')]: {
      width: (props) => (props.fullWidth ? '100%' : 78),
      height: 38,
      fontSize: '14px',
      padding: '10px 24px',
      textTransform: 'initial',
    },
  },
  contained: {},
  containedPrimary: {
    background: 'var(--fgf-contained-button-bg) !important',
    color: 'var(--fgf-contained-button-color) !important',
    border: 'none',
    '&:hover': {
      color: 'var(--fgf-contained-button-hover-color) !important',
      background:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)) var(--fgf-contained-button-hover-bg) !important',
    },
    '&:active': {
      color: 'var(--fgf-contained-button-hover-color) !important',
      background:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)) var(--fgf-contained-button-hover-bg) !important',
    },
    '&&.Mui-disabled': {
      opacity: 0.5,
      color: `${COLORS.white} !important`,
      background: `${COLORS.black} !important`,
    },
  },
  containedSecondary: {
    background: `${COLORS.white} !important`,
    color: `${COLORS.black} !important`,
    border: `1px solid ${COLORS.black20} !important`,
    '&:hover': {
      color: `${COLORS.white} !important`,
      background: `${COLORS.black} !important`,
      borderColor: `${COLORS.black} !important`,
    },
    '&:active': {
      color: `${COLORS.white} !important`,
      background: `${COLORS.black70} !important`,
      borderColor: `${COLORS.black70} !important`,
    },
    '&&.Mui-disabled': {
      opacity: 0.5,
      color: `${COLORS.black30} !important`,
      background: `${COLORS.white} !important`,
    },
  },
  outlinedPrimary: {
    background: `${COLORS.neonChartreuse} !important`,
    color: `${COLORS.black} !important`,
    border: `2px solid ${COLORS.neonChartreuse} !important`,
    '&:hover': {
      color: `${COLORS.black} !important`,
      background: `${COLORS.white} !important`,
      border: `2px solid ${COLORS.neonChartreuse} !important`,
    },
    '&:active': {
      color: `${COLORS.black} !important`,
      background: `${COLORS.neonChartreuse20} !important`,
      borderColor: `${COLORS.neonChartreuse20} !important`,
    },
    '&&.Mui-disabled': {
      opacity: 0.5,
      color: `${COLORS.black} !important`,
      background: `${COLORS.neonChartreuse} !important`,
      borderColor: `${COLORS.neonChartreuse} !important`,
    },
  },
  outlinedSecondary: {
    background: `${COLORS.white} !important`,
    color: `${COLORS.black} !important`,
    border: 'none',
    '&:hover': {
      color: `${COLORS.fluorescentPink} !important`,
      background: `${COLORS.white} !important`,
      border: 'none',
    },
    '&:active': {},
    '&&.Mui-disabled': {
      opacity: 0.5,
      color: `${COLORS.black} !important`,
      background: `${COLORS.white} !important`,
      border: 'none',
    },
  },
}))(MUIBUtton);
