import React from 'react';
import {
  FormControl,
  InputLabel,
  Select as MUISelect,
  SelectProps,
} from '@material-ui/core';
import { styled, withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { COLORS } from 'theme/colors';

export const StyledIcon = styled(ExpandMoreIcon)({
  '&.MuiSelect-icon.Mui-disabled': {
    color: COLORS.black10,
  },
});

export const Select = withStyles(() => ({
  root: {
    padding: '10px 16px',
  },
}))((props: SelectProps) => (
  <FormControl
    variant="outlined"
    required={props.required}
    disabled={props.disabled}
  >
    {props.label ? (
      <InputLabel id="select-label">{props.label}</InputLabel>
    ) : null}
    <MUISelect
      margin="dense"
      IconComponent={StyledIcon}
      inputProps={{ 'aria-labelledby': 'select-label' }}
      {...props}
    />
  </FormControl>
));
