import React from 'react';

import SignUpForms from './signUpForms';

const Footer = () => (
  <>
    <SignUpForms />
  </>
);

export default Footer;
