/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';
import { setInputValueWithEvent } from '../lib/dom_utils';

export default class extends Controller {
  fill(event) {
    event.preventDefault();

    const addressObject = JSON.parse(event.target.dataset.address);

    Object.entries(addressObject).forEach(([key, value]) => {
      const input = document.querySelector(`[name="cart[delivery_${key}]"]`);
      if (input) {
        setInputValueWithEvent(input, value);
      }
    });
  }
}
