import { Box, Button, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Z_INDEX } from 'constants/zIndex';
import { COLORS } from 'theme/colors';
import { down, up } from 'utils/breakpointHelpers';

export const RootStyled = styled('div')({
  padding: '0 12px 32px',
  [up('lg')]: {
    maxWidth: '90%',
  },

  '& .slick-list': {
    paddingLeft: '0 !important',
  },
  '& .slick-slide': {
    outline: 'none',
  },
});

export const RootCartStyled = styled('div')({
  '& .slick-list': {
    paddingLeft: '0 !important',
  },
  '& .slick-slide': {
    outline: 'none',
  },
});

export const RootStyledProgress = styled('div')({
  padding: 50,
  display: 'flex',
  justifyContent: 'center',
});

export const HeaderStyled = styled(Typography)({
  fontSize: 24,
  lineHeight: '28px',
  paddingBottom: 12,
  [down('sm')]: {
    fontSize: 20,
    lineHeight: '24px',
    paddingBottom: 8,
  },
});

export const ArrowButtonStyled = styled(Box)({
  borderRadius: '50%',
  position: 'absolute',
  width: 45,
  height: 45,
  top: '50%',
  right: -28,
  marginTop: -70,
  color: COLORS.black,
  zIndex: Z_INDEX.AddonsArrowButton,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ArrowNextButtonStyled = styled(ArrowButtonStyled)({
  right: -10,
  background: COLORS.white,
});

export const CounterContainerStyled = styled(Box)({
  height: 38,
  borderRadius: 4,
  background: '#FFF',
  padding: 8,
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  border: '1px solid #000',
});

export const CounterStyled = styled(Typography)({
  flexGrow: 1,
  textAlign: 'center',
});

export const ButtonStyled = styled(Button)({
  height: '100%',
  minWidth: 'auto',
  '&.Mui-disabled': {
    color: '#CCC',
  },
});
