import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { ReactComponentOrRenderFunction } from 'react-on-rails/node_package/lib/types';
import { ServerStyleSheets, Theme } from '@material-ui/core';

import { RailsContextType, WithWrappersProps } from './interface';
import { RailsContext } from './RailsContext';
import { ReactKeyContext } from './ReactKeyContext';
import { generateClassName, Wrappers } from './Wrappers';

const renderApp = (Component: React.FC<any>, customTheme?: Theme) => {
  const WrappedComponent = ({
    'data-react-key': reactKey,
    railsContext,
    ...restProps
  }: WithWrappersProps) => (
    <Wrappers reactKey={reactKey} customTheme={customTheme}>
      <ReactKeyContext.Provider value={reactKey}>
        <RailsContext.Provider value={railsContext}>
          <Component {...restProps} />
        </RailsContext.Provider>
      </ReactKeyContext.Provider>
    </Wrappers>
  );

  // see https://material-ui.com/guides/server-rendering/
  const renderedApp = (
    props: WithWrappersProps,
    railsContext: RailsContextType,
  ) => {
    if (railsContext.serverSide) {
      const sheets = new ServerStyleSheets({
        serverGenerateClassName: generateClassName(props['data-react-key']),
      });

      // Render the component to a string.
      const componentHtml = ReactDOMServer.renderToString(
        sheets.collect(
          <WrappedComponent {...props} railsContext={railsContext} />,
        ),
      );

      // Grab the CSS from the sheets.
      const css = sheets.toString();

      const renderedHtml = {
        componentHtml,
        css,
      };
      return { renderedHtml };
    }

    return () => <WrappedComponent {...props} railsContext={railsContext} />;
  };

  return renderedApp as unknown as ReactComponentOrRenderFunction;
};

export default renderApp;
