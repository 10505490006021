import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['input', 'output'];
  }

  connect() {
    this.update();
  }

  update() {
    const count = this.inputTarget.value.length;
    if (this.maxLength && this.maxLength !== Infinity) {
      this.setOutputText(`${count} of ${this.maxLength} characters`);
    } else {
      this.setOutputText(`${count} characters`);
    }
  }

  inputTargetConnected() {
    this.inputTarget.addEventListener('input', this.update.bind(this));
    this.update();
  }

  setOutputText(text) {
    this.outputTarget.textContent = text;
  }

  get maxLength() {
    return this.inputTarget.maxLength || Infinity;
  }
}
