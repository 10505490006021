import { Image } from './carousel/interface';

export const HERO_IMAGE =
  'https://fgflocalimages.s3.amazonaws.com/Corporate+Gifting/Corporate+Gifting/corporate_gifting_hero_image.png';
export const GET_IN_TOUCH_BG =
  'https://fgflocalimages.s3.amazonaws.com/Corporate+Gifting/Corporate+Gifting/contact_form_bg.png';
export const SELECT_ICON =
  'https://fgflocalimages.s3.amazonaws.com/Icons/Computer.svg';
export const MONEY_ICON =
  'https://fgflocalimages.s3.amazonaws.com/Icons/Money.svg';
export const HEY_ICON =
  'https://fgflocalimages.s3.amazonaws.com/Icons/Hey+There.svg';

export const PLANTS_CAROUSEL: Array<Image> = [
  {
    id: 1,
    img: 'https://fgflocalimages.s3.amazonaws.com/Corporate+Gifting/Corporate+Gifting/Plants+Carousel/IMG_4152.jpg',
    alt: 'plant_1',
  },
  {
    id: 2,
    img: 'https://fgflocalimages.s3.amazonaws.com/Corporate+Gifting/Corporate+Gifting/Plants+Carousel/IMG_4225.jpg',
    alt: 'plant_2',
  },
  {
    id: 3,
    img: 'https://fgflocalimages.s3.amazonaws.com/Corporate+Gifting/Corporate+Gifting/Plants+Carousel/IMG_4230-2.jpg',
    alt: 'plant_3',
  },
  {
    id: 4,
    img: 'https://fgflocalimages.s3.amazonaws.com/Corporate+Gifting/Corporate+Gifting/Plants+Carousel/IMG_4532+(1).jpg',
    alt: 'plant_4',
  },
];

export const MARKET_BOUQUET_CAROUSEL: Array<Image> = [
  {
    id: 1,
    img: 'https://fgflocalimages.s3.amazonaws.com/Corporate+Gifting/Corporate+Gifting/Market+Bouquets+Carousel/FANCY+TULIPS+-+CLOSE+UO.jpg',
    alt: 'market_bouquet_1',
  },
  {
    id: 2,
    img: 'https://fgflocalimages.s3.amazonaws.com/Corporate+Gifting/Corporate+Gifting/Market+Bouquets+Carousel/IMG_4444.jpg',
    alt: 'market_bouquet_2',
  },
  {
    id: 3,
    img: 'https://fgflocalimages.s3.amazonaws.com/Corporate+Gifting/Corporate+Gifting/Market+Bouquets+Carousel/IMG_9975+(2).jpg',
    alt: 'market_bouquet_3',
  },
  {
    id: 4,
    img: 'https://fgflocalimages.s3.amazonaws.com/Corporate+Gifting/Corporate+Gifting/Market+Bouquets+Carousel/KB+Mday-+SM+bright+gerb+burlap+lite-039+copy.jpg',
    alt: 'market_bouquet_4',
  },
];

export const HAND_TIED_ARRANGEMENT_CAROUSEL: Array<Image> = [
  {
    id: 1,
    img: 'https://fgflocalimages.s3.amazonaws.com/Corporate+Gifting/Corporate+Gifting/Hand-Tied+Arrangements+Carousel/MDay+Small+Burlap+Reshoot+03-23-2022-034+(1).jpg',
    alt: 'hand_tied_arrangement_1',
  },
  {
    id: 2,
    img: 'https://fgflocalimages.s3.amazonaws.com/Corporate+Gifting/Corporate+Gifting/Hand-Tied+Arrangements+Carousel/Nosegay+%2B+Shortbread+cookies-4431+copy.jpg',
    alt: 'hand_tied_arrangement_2',
  },
  {
    id: 3,
    img: 'https://fgflocalimages.s3.amazonaws.com/Corporate+Gifting/Corporate+Gifting/Hand-Tied+Arrangements+Carousel/VDAY-MINIMALST-1+(1).jpg',
    alt: 'hand_tied_arrangement_3',
  },
  {
    id: 4,
    img: 'https://fgflocalimages.s3.amazonaws.com/Corporate+Gifting/Corporate+Gifting/Hand-Tied+Arrangements+Carousel/VVB-MINI-CLOSEUP+(1).jpg',
    alt: 'hand_tied_arrangement_4',
  },
];
