import React, { useEffect } from 'react';
import RoomIcon from '@material-ui/icons/Room';
import { useFetch } from 'use-http';
import { Autocomplete } from '@material-ui/lab';
import { Control, useController, useFormState } from 'react-hook-form';
import { InputAdornment, TextField, TextFieldProps } from '@material-ui/core';

import { ApiRoutes } from 'constants/api';
import { useZipSearch } from 'hooks/useZipSearch';
import { PurchaseFormFieldNames as FIELD_NAMES } from 'constants/form';
import {
  GetZipCodeResponse,
  PostZipCodeResponse,
  ZipCodeFieldProps,
  ZipCodeFormInputs,
} from './interface';

const ZipCodeField = ({
  control,
  label,
  ...rest
}: ZipCodeFieldProps & Partial<TextFieldProps>) => {
  const { loading, options, zipCodeInput, setZipCodeInput } = useZipSearch();
  const { post } = useFetch<PostZipCodeResponse>(ApiRoutes.ZipCodes);
  const { get } = useFetch<GetZipCodeResponse>(ApiRoutes.ZipCode);

  const { errors } = useFormState({
    control: control as Control<ZipCodeFormInputs>,
  });
  const {
    field: { ref, onChange: onZipCodeChange, value, ...zipCodeProps },
  } = useController({
    name: FIELD_NAMES.zipCode,
    control: control as Control<ZipCodeFormInputs>,
  });

  useEffect(() => {
    get().then((val) => onZipCodeChange(val?.zip_code || null));
  }, []);

  const handleChange = (_: React.ChangeEvent<{}>, val: string | null) => {
    if (!val) return;

    post({ zip_code: val }).then((res) => {
      if (!res.message) onZipCodeChange(val);
    });
  };

  return (
    <Autocomplete
      {...zipCodeProps}
      fullWidth
      blurOnSelect
      value={value}
      loading={loading}
      options={options}
      id="zip-code-autocomplete"
      onInputChange={(e, val) => {
        if (e?.type === 'change') setZipCodeInput(val);
        if (val?.length === 5) handleChange(e, val);
      }}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || 'Zip Code'}
          variant="outlined"
          size="small"
          fullWidth
          value={zipCodeInput}
          ref={params.InputProps.ref}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <RoomIcon style={{ width: 15 }} fontSize="small" />
              </InputAdornment>
            ),
          }}
          error={FIELD_NAMES.zipCode in errors}
          helperText={errors[FIELD_NAMES.zipCode]?.message}
          {...rest}
        />
      )}
    />
  );
};

export default ZipCodeField;
