import { Box, Link, Typography, styled } from '@material-ui/core';

import { COLORS } from 'theme/colors';
import { down } from 'utils/breakpointHelpers';

export const CTAContainerStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  maxWidth: 488,
  paddingInline: 12,
  marginBottom: 48,
  [down('md')]: {
    maxWidth: 348,
  },
  [down('sm')]: {
    maxWidth: '100%',
    marginBottom: 56,
  },
  '& > button': {
    width: '100%',
    cursor: 'pointer',
  },
});

export const LinkStyled = styled(Link)({
  alignSelf: 'center',
  fontSize: 12,
  lineHeight: '15px',
  color: '#667085',
  textDecoration: 'underline',
  cursor: 'pointer',
});

export const DisclaimerStyled = styled(Typography)({
  color: COLORS.fluorescentPink,
});
