import { Dayjs } from 'dayjs';
import { UNAVAILABLE_DAY_DATE_FORMAT } from 'constants/time';

// Checking if date is unavailable. A higher order function.
function isDateUnavailableHoc(unavailableDates: string[]) {
  return (dateToCheck: Dayjs) => {
    const formattedDateToCheck = dateToCheck.format(
      UNAVAILABLE_DAY_DATE_FORMAT,
    );

    return unavailableDates.includes(formattedDateToCheck);
  };
}

// Get next delivery window dates
export function getNextAvailableDeliveryDate(
  initialDate: Dayjs,
  unavailableDates: string[],
): Dayjs | undefined {
  let nextAvailableDay: Dayjs | undefined;
  const isDateUnavailable = isDateUnavailableHoc(unavailableDates);

  // The limit for order from current day is 6 months only
  const maxDaysOffset = 365 / 2;
  let daysCounter = 0;

  for (; daysCounter < maxDaysOffset; daysCounter += 1) {
    const nextDate = initialDate.add(daysCounter, 'day');
    const nextDateDayNumber = nextDate.day();

    // Available deliver dates should be Mon-Sat
    // and should not be in unavailable list from backend
    if (
      nextDateDayNumber > 0 &&
      nextDateDayNumber < 7 &&
      !isDateUnavailable(nextDate)
    ) {
      nextAvailableDay = nextDate;
      break;
    }
  }

  return nextAvailableDay;
}
