/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useEffect } from 'react';
import useFetch from 'use-http';
import { Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';

import { Radio } from 'ui-components/v2/Radio';
import { Button } from 'ui-components/v2/Button';

import Modal from '../Modal';

import {
  AddressListContainer,
  AddressSectionHeading,
  DisclaimerText,
  FormControlLabelStyled,
  LinkStyled,
  RadioGroupStyled,
  SelectButtonContainer,
} from './styled';
import {
  AddressPost,
  AddressVerificationFormValues,
  IAddressVerificationModalProps,
} from './interface';

const AddressVerificationModal = ({
  show,
  nothingToSuggest,
  authenticity_token,
  userFormValues,
  suggestionResponse,
}: IAddressVerificationModalProps) => {
  const { state, postcode, street_1, street_2, city } =
    suggestionResponse || {};

  const { put, loading } = useFetch<any>();

  const [showModal, setShowModal] = useState(show);
  const [errorSubmitting, setErrorSubmitting] = useState('');
  const { control, watch, setValue } = useForm<AddressVerificationFormValues>({
    defaultValues: {
      verifyAddress: 'verified',
      stickWithEntered: false,
      locationType: 'residence',
    },
  });

  const handleSelectClick = async () => {
    // if (!suggestionResponse) return;
    setErrorSubmitting('');

    let addressToPost = {
      authenticity_token,
      cart: {
        delivery_first_name: userFormValues?.delivery_first_name,
        delivery_last_name: userFormValues?.delivery_last_name,
        delivery_business_name: userFormValues?.delivery_business_name,
        delivery_phone: userFormValues?.delivery_phone,
        delivery_address_1: suggestionResponse.street_1,
        delivery_address_2: suggestionResponse.street_2 || '',
        delivery_city: suggestionResponse.city,
        delivery_state: suggestionResponse.state,
        delivery_zip_code: suggestionResponse.postcode,
        delivery_location_type: watch()?.locationType,
        cart_deliveries_attributes: [
          {
            message: userFormValues?.message,
            id: userFormValues?.id,
          },
        ],
      },
      keep_or_use: 'use',
    } as AddressPost;

    if (watch()?.stickWithEntered) {
      addressToPost = {
        ...addressToPost,
        cart: {
          ...addressToPost.cart,
          delivery_address_1: userFormValues?.delivery_address_1,
          delivery_address_2: userFormValues?.delivery_address_2,
          delivery_city: userFormValues.delivery_city,
          delivery_state: userFormValues?.delivery_state,
          delivery_business_name: userFormValues?.delivery_business_name,
          delivery_zip_code: userFormValues?.delivery_zip_code.toString(),
          delivery_location_type: watch()?.locationType,
        },
        keep_or_use: 'keep',
      };
    }

    await put('/cart/delivery.js', addressToPost).then(
      (res: { redirect_to: string; error: any }) => {
        if (res?.error) {
          setErrorSubmitting(res?.error);
        } else {
          window.location.href = '/cart/checkout';
        }
      },
    );
  };

  useEffect(() => {
    if (nothingToSuggest) {
      setValue('stickWithEntered', true);
    }
  }, [nothingToSuggest]);

  return (
    <Modal
      handleClose={() => setShowModal(false)}
      show={showModal}
      title="Please Verify Address"
    >
      {show && !nothingToSuggest && (
        <>
          <Typography variant="body2" paragraph>
            We’re unable to verify your shipping address. Please select the
            verified address below or proceed with your entered address.
          </Typography>
          <Controller
            control={control}
            name="verifyAddress"
            render={({ field }) => (
              <RadioGroupStyled {...field} defaultValue="verified">
                <div>
                  <AddressSectionHeading>
                    verified address:
                  </AddressSectionHeading>
                  <FormControlLabelStyled
                    value="verified"
                    control={
                      <Radio
                        onChange={() => setValue('stickWithEntered', false)}
                      />
                    }
                    label={
                      <AddressListContainer>
                        <Typography variant="body2">{street_1}</Typography>
                        {street_2 && (
                          <Typography variant="body2">{street_2}</Typography>
                        )}
                        <Typography variant="body2">{city}</Typography>
                        <Typography variant="body2">
                          {state} {postcode}
                        </Typography>
                      </AddressListContainer>
                    }
                  />
                </div>
                <div>
                  <AddressSectionHeading>
                    entered address:
                  </AddressSectionHeading>
                  <FormControlLabelStyled
                    value="entered"
                    control={
                      <Radio
                        onChange={() => setValue('stickWithEntered', true)}
                      />
                    }
                    label={
                      <AddressListContainer>
                        <Typography variant="body2">
                          {userFormValues?.delivery_address_1}
                        </Typography>
                        {userFormValues?.delivery_address_2 && (
                          <Typography variant="body2">
                            {userFormValues?.delivery_address_2}
                          </Typography>
                        )}
                        <Typography variant="body2">
                          {userFormValues?.delivery_city}
                        </Typography>
                        <Typography variant="body2">
                          {userFormValues?.delivery_state}{' '}
                          {userFormValues?.delivery_zip_code}
                        </Typography>
                        <LinkStyled
                          variant="body2"
                          color="primary"
                          onClick={() => setShowModal(false)}
                        >
                          EDIT
                        </LinkStyled>
                      </AddressListContainer>
                    }
                  />
                </div>
              </RadioGroupStyled>
            )}
          />
          <DisclaimerText variant="body2">
            <b>PLEASE NOTE:</b> Unverified addresses may lead to delivery delays
            while parcel carriers confirm the recipient’s address.
          </DisclaimerText>
        </>
      )}
      {show && nothingToSuggest && (
        <>
          <Typography variant="body2" paragraph>
            We’re unable to verify your shipping address. If you’d like us to
            proceed with this address as entered, click “Continue Checkout”
            below.
          </Typography>
          <AddressListContainer>
            <AddressSectionHeading>entered address:</AddressSectionHeading>
            <Typography variant="body2">
              {userFormValues?.delivery_address_1}
            </Typography>
            {userFormValues?.delivery_address_2 && (
              <Typography variant="body2">
                {userFormValues?.delivery_address_2}
              </Typography>
            )}
            <Typography variant="body2">
              {userFormValues?.delivery_city}
            </Typography>
            <Typography variant="body2">
              {userFormValues?.delivery_state}{' '}
              {userFormValues?.delivery_zip_code}
            </Typography>
            <LinkStyled
              variant="body2"
              color="primary"
              onClick={() => setShowModal(false)}
            >
              EDIT
            </LinkStyled>
          </AddressListContainer>
          <DisclaimerText variant="body2">
            <b>PLEASE NOTE:</b> Unverified addresses may lead to delivery delays
            while parcel carriers confirm the recipient’s address.
          </DisclaimerText>
        </>
      )}
      <SelectButtonContainer>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSelectClick}
        >
          {loading ? 'Loading...' : 'Continue Checkout'}
        </Button>
      </SelectButtonContainer>
      {errorSubmitting && (
        <Typography variant="body2" color="error">
          {errorSubmitting}
        </Typography>
      )}
    </Modal>
  );
};

export default AddressVerificationModal;
