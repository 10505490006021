import { IconButton, Link, styled } from '@material-ui/core';
import { COLORS } from 'theme/colors';

export const ContainerStyled = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  position: 'relative',
});

export const PopupButtonStyled = styled('span')({
  fontSize: 8,
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  position: 'relative',
  background: COLORS.fluorescentPink,
  borderRadius: '50%',
  width: 14,
  height: 14,
  margin: '10px 10px 20px',
});

export const PopupContainer = styled('div')({
  position: 'absolute',
  border: '1px solid #000',
  padding: '10px 20px 10px',
  background: '#FFF',
  top: 38,
  zIndex: 10,
  right: 0,
});

export const Popup = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
});

export const PopupTitle = styled('p')({
  color: '#000',
  fontSize: 14,
  fontWeight: 600,
});

export const PopupText = styled('p')({
  color: '#000',
  fontSize: 14,
});

export const PopupLink = styled(Link)({
  fontSize: 14,
  fontWeight: 600,
  marginTop: 14,
  marginBottom: 14,
});

export const PopupClose = styled(IconButton)({
  width: 10,
  height: 10,
  position: 'absolute',
  right: 20,
  top: 10,
});
