import { useEffect, useContext } from 'react';

import { ReactKeyContext } from 'hocs/ReactKeyProvider/ReactKeyContext';

interface GenericEventPayload<EventPayload> {
  // ReactKey from connected DOM element
  reactKey: ReactKey;
  // Generic data payload from event
  data: EventPayload;
}

// Event listener callback
export type GenericEventHandler<EventPayload> = (payload: EventPayload) => void;

export function useEventListener<EventPayload>(
  // Generic data payload from event
  eventName: string,
  // Event listener callback
  eventHandler: GenericEventHandler<EventPayload>,
) {
  const contextReactKey = useContext(ReactKeyContext);

  useEffect(() => {
    const listener = (evt: CustomEvent<GenericEventPayload<EventPayload>>) => {
      const { reactKey, data } = evt.detail;
      if (contextReactKey === reactKey) eventHandler(data);
    };

    document.addEventListener(eventName, listener as EventListener);

    return () => {
      document.removeEventListener(eventName, listener as EventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextReactKey, eventHandler, eventName]);
}
