/**
 * Since we removed react-router from our application,
 * we need a way to insert page-specific code into our components.
 * Checking for document path is not always reliable (in product page,
 * for example) so this enum is a solution for the problem.
 */
export enum Pages {
  /**
   * Product page, e.g. '/fun-size-burlap-wrapped-bouquet'
   */
  PRODUCT = 'product',
  /**
   * Cart page (path '/cart' in rails app)
   */
  CART = 'cart',

  SHOP = 'shop',
  /**
   * Temporary admin page(path '/admin' in rails app)
   */
  ACCOUNT = 'account',
  /**
   * Temporary admin page(path '/admin' in rails app)
   */
  ADMIN = 'admin',
}
