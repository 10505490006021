import { Box, Link, styled, Typography } from '@material-ui/core';

import { Button } from 'ui-components/v2/Button';
import { down } from 'utils/breakpointHelpers';

export const ContainerStyled = styled(Box)({
  padding: '32px',
  paddingBottom: '48px',
});

export const ActionButtonStyled = styled(Button)({
  width: 'auto',
  minWidth: '82px',
  [down('sm')]: {
    width: 'auto',
    minWidth: '78px',
    paddingInline: '20px',
  },
});

export const TitleStyled = styled(Typography)({
  margin: '3px 0 24px',
  [down('md')]: {
    fontSize: '24px',
    lineHeight: '30px',
    marginBottom: '20px',
  },
  [down('sm')]: {
    fontSize: '20px',
    lineHeight: '24px',
    marginBottom: '9px',
  },
});

export const LinkStyled = styled(Link)({
  textDecoration: 'underline',
  cursor: 'pointer',
});
