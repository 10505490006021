import { useEffect, useState } from 'react';
import { useFetch } from 'use-http';
import { ApiRoutes } from 'constants/api';
import {
  Addon,
  CartAddonsProps,
  GetAddonsResponseData,
  PostAddonsData,
} from './interface';

export const useAddons = ({
  date,
  productId,
  zipCode,
  quantity,
  token,
  cartLineId,
}: Omit<CartAddonsProps, 'cartAddons'>) => {
  const [addons, setAddons] = useState<Addon[]>([]);

  const {
    get,
    data: responseAddons,
    loading: getLoading,
  } = useFetch<GetAddonsResponseData>();

  const {
    put,
    response,
    loading: postLoading,
  } = useFetch(ApiRoutes.CartAddons, {
    headers: {
      'X-CSRF-Token': token || '',
    },
  });

  useEffect(() => {
    if (productId && date && zipCode) {
      const addonsPath = `/products/${productId}/add_ons?quantity=${quantity}&delivery_date=${date}`;
      const queryParams = new URLSearchParams({ zip_code: zipCode });
      get(`${addonsPath}&${queryParams}`);
    }
  }, [get, productId, date, zipCode]);

  useEffect(() => {
    if (responseAddons && Array.isArray(responseAddons)) {
      setAddons(responseAddons);
    }
  }, [responseAddons, setAddons]);

  const handleSubmit = (selectedAddons: PostAddonsData['add_ons']) => {
    const data: PostAddonsData = {
      quantity,
      main_cart_line_id: cartLineId as number,
      add_ons: selectedAddons.map((addon) => ({
        product_id: addon.product_id,
        quantity: addon.quantity,
      })),
    };

    put(data).then(() => {
      if (response.status === 200) window.location.reload();
    });
  };

  return {
    addons,
    getLoading,
    postLoading,
    handleSubmit,
  };
};
