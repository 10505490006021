/* eslint-disable no-undef */
import { Controller } from '@hotwired/stimulus';

/**
 * Creates the hover card element which will contain the hover card's fetched
 * HTML.
 * @returns {HTMLDivElement}
 */
function createHoverCardElement() {
  const hoverCard = document.createElement('div');
  // Note: Do minimal styling here. So most of the styling can be done in the
  // HTML being fetched.
  hoverCard.classList.add(
    'absolute',
    'bg-white',
    'border',
    'border-solid',
    'border-gray-300',
    'rounded-lg',
    'shadow-lg',
    'max-w-screen-sm',
    'min-w-96',
    'overflow-hidden',
    'hidden',
  );
  hoverCard.style.zIndex = 9999;
  return hoverCard;
}

/**
 * This fetches an endpoint on hover and displays the resulting HTML in a hover
 * card.
 *
 * To use:
 *
 * 1. Specifiy the hover-card controller once on the container element
 * (e.g. <body data-controller="hover-card">)
 *
 * 2. Add a data-hover-card-target="hoverable" attribute to the element you want
 * to hover.
 *
 * 3. Add a data-hover-card-url attribute to specify where the HTML will be
 * fetched from.
 */
export default class extends Controller {
  static get values() {
    return {
      url: String,
    };
  }

  static get targets() {
    return ['hoverable'];
  }

  initialize() {
    // Add the hoverCard to the body
    this.hoverCard = createHoverCardElement();
    this.cachedHTMLForURLs = new Map();
    this.currentURL = null;

    this.mouseEnterListener = (event) => {
      const hoverable = event.currentTarget;
      const currentURL = hoverable.dataset.hoverCardUrl;

      const timeoutId = setTimeout(() => {
        this.fetchContent(currentURL);
      }, 200);

      const mouseMoveListener = (mouseMoveEvent) => {
        // Always update mouse position, so whenever showHoverCard() is called,
        // it will show it near the current mouse position.
        this.mouseX = mouseMoveEvent.pageX;
        this.mouseY = mouseMoveEvent.pageY;

        // Remove hoverCard if not hovering over the element or hoverCard
        if (
          !hoverable.contains(mouseMoveEvent.target) &&
          !this.hoverCard.contains(mouseMoveEvent.target)
        ) {
          clearTimeout(timeoutId);
          this.hideHoverCard();
          // Remove listener itself if mouse no longer within the element or hoverCard
          document.body.removeEventListener('mousemove', mouseMoveListener);
        }
      };
      document.body.addEventListener('mousemove', mouseMoveListener);
    };
  }

  connect() {
    // Add hoverCard container to the DOM
    document.body.appendChild(this.hoverCard);
  }

  disconnect() {
    this.hoverCard.remove();
  }

  hoverableTargetConnected(target) {
    target.addEventListener('mouseenter', this.mouseEnterListener);
  }

  hoverableTargetDisconnected(target) {
    target.removeEventListener('mouseenter', this.mouseEnterListener);
  }

  followMouse(pageX, pageY) {
    const offset = 5;
    const hoverCardWidth = this.hoverCard.offsetWidth;
    const windowWidth = window.innerWidth;

    let left = pageX + offset;

    // Adjust left position if hoverCard exceeds right edge of the screen
    if (left + hoverCardWidth > windowWidth) {
      left = windowWidth - hoverCardWidth;
    }
    this.hoverCard.style.left = `${left}px`;
    this.hoverCard.style.top = `${pageY + offset}px`;
  }

  fetchContent(urlToLoad) {
    this.showHoverCard();

    // If cached, render immediately
    const cachedHTML = this.retrieveCachedHTMLForURL(urlToLoad);
    if (cachedHTML && cachedHTML !== '') {
      this.updateHoverCard(cachedHTML);
      return;
    }

    this.updateHoverCard('<span class="p-2">Loading...</span>');
    fetch(urlToLoad).then((response) => {
      if (response.ok) {
        response.text().then((html) => {
          this.updateCachedHTMLForURL(urlToLoad, html);
          this.updateHoverCard(html);
        });
      } else {
        const node = document.createElement('span');
        node.classList.add('text-red-500', 'inline-block', 'p-2');
        node.innerText = `Error fetching preview content (HTTP Status: ${response.status} ${response.statusText})`;
        this.updateHoverCard(node.outerHTML);
      }
    });
  }

  retrieveCachedHTMLForURL(urlToLoad) {
    return this.cachedHTMLForURLs.get(urlToLoad);
  }

  updateCachedHTMLForURL(urlToLoad, html) {
    this.cachedHTMLForURLs.set(urlToLoad, html);
  }

  updateHoverCard(innerHTML) {
    this.hoverCard.innerHTML = innerHTML;
  }

  showHoverCard() {
    this.hoverCard.classList.remove('hidden');
    this.followMouse(this.mouseX, this.mouseY);
  }

  hideHoverCard() {
    this.hoverCard.classList.add('hidden');
  }
}
