import { styled, makeStyles } from '@material-ui/core/styles';

export const NoResults = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid #ECECEC',
  height: '362px',
  borderRadius: '2px',
  marginTop: '60px',
  marginBottom: '32px',
});

export const useEmptyStateStyles = makeStyles(() => ({
  noResultTitle: {
    marginTop: '6px',
  },

  noResultDesc: {
    margin: '16px 0',
    textAlign: 'center',
    color: '#333',
  },
}));
