export enum PurchaseFormFieldNames {
  addons = 'add_ons',
  showCart = 'show_cart',
  date = 'date',
  endDate = 'end_date',
  frequency = 'frequency',
  id = 'product_id',
  noStock = 'no_stock',
  quantity = 'quantity',
  startDate = 'start_date',
  filterDate = 'filter_date',
  subscription = 'subscription',
  zipCode = 'zip_code',
}

export const PURCHASE_FORM_DEFAULT_VALUES = {
  [PurchaseFormFieldNames.addons]: [],
  [PurchaseFormFieldNames.showCart]: true,
  [PurchaseFormFieldNames.date]: null,
  [PurchaseFormFieldNames.endDate]: null,
  [PurchaseFormFieldNames.frequency]: 'weekly',
  [PurchaseFormFieldNames.noStock]: false,
  [PurchaseFormFieldNames.quantity]: '1',
  [PurchaseFormFieldNames.startDate]: null,
  [PurchaseFormFieldNames.filterDate]: null,
  [PurchaseFormFieldNames.subscription]: null,
  [PurchaseFormFieldNames.zipCode]: null,
};
