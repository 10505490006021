import { styled } from '@material-ui/core';
import { Button } from 'ui-components/v2/Button';

export const StyledButton = styled(Button)({
  height: 30,
  width: 80,
  '& > span': {
    fontSize: 12,
    lineHeight: '18px',
  },
});
