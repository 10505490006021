import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { down } from 'utils/breakpointHelpers';
import { BreadCrumbsUseStyleprops, PathBreadcrumbsProps } from './interface';
import { SeparatorStyled } from './styled';

const useStyles = makeStyles((theme) => ({
  root: ({ disablePadding }: BreadCrumbsUseStyleprops) => ({
    padding: disablePadding ? 0 : '56px 120px 16px',
    [down('md')]: {
      padding: disablePadding ? 0 : '40px 51px 16px',
    },
    [down('sm')]: {
      padding: disablePadding ? 0 : '24px 14px 14px',
    },
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  }),
  breadcrumbItem: {
    fontSize: 12,
    color: '#000000',
    display: 'flex',
  },
}));

export function PathBreadcrumbs({
  breadcrumbs,
  disablePadding,
}: PathBreadcrumbsProps) {
  const classes = useStyles({ disablePadding });

  return (
    <div className={classes.root}>
      <Breadcrumbs
        separator={<SeparatorStyled fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs.map((breadcrumb, index, array) =>
          index !== array.length - 1 ? (
            <Link
              className={classes.breadcrumbItem}
              href={breadcrumb.href}
              key={breadcrumb.title}
            >
              {breadcrumb.title}
            </Link>
          ) : (
            <Typography
              className={classes.breadcrumbItem}
              key={breadcrumb.title}
            >
              {breadcrumb.title}
            </Typography>
          ),
        )}
      </Breadcrumbs>
    </div>
  );
}
