/* eslint-disable max-len */
import React from 'react';
import { Typography } from '@material-ui/core';
import { useAboutUsStyles, BoldText, StyledLink } from './styled';

const HERO_IMAGE =
  'https://fgflocalimages.s3.amazonaws.com/About+Us/About+Me+Photos/about-us-hero.jpg';
const SPECIAL_DELIVERY =
  'https://fgflocalimages.s3.amazonaws.com/About+Us/About+Me+Photos/special-delivery.jpg';
const FOUNDER =
  'https://fgflocalimages.s3.amazonaws.com/About+Us/About+Me+Photos/CStembel-1+(2).jpg';
const OUR_MOTTO =
  'https://fgflocalimages.s3.amazonaws.com/About+Us/About+Me+Photos/_MG_5735.jpg';
const WITH_HEART =
  'https://fgflocalimages.s3.amazonaws.com/About+Us/About+Me+Photos/With+Heart.svg';

const AboutUs = () => {
  const styles = useAboutUsStyles();

  return (
    <div className={styles.root}>
      <div className={styles.heroImageContainer}>
        <img
          src={HERO_IMAGE}
          alt="about-us"
          className={`${styles.image} about-us-hero`}
        />
      </div>

      <div className={`${styles.description} hero-section`}>
        <Typography variant="h1" className={styles.h2}>
          Hey there! We're Farmgirl Flowers.
        </Typography>

        <Typography variant="body1" className={styles.paragraph}>
          <i style={{ fontWeight: 500 }}>
            We ship our signature burlap-wrapped flower bouquets, shippable
            vases, single variety arrangements, plants and more to recipients
            (like you!) coast to coast.
          </i>
        </Typography>

        <Typography variant="body1" className={styles.paragraph}>
          Farmgirl Flowers is the only large scale, female-founded and
          predominantly female-run ecommerce flower company.{' '}
          <BoldText>Why is that important? </BoldText>
          The gifting and receiving of flowers is usually done by women, for
          other women. So when it comes to deciding all the little details that
          make a Farmgirl bouquet we go the extra mile because{' '}
          <BoldText>we are the customer</BoldText>. We design the type of
          arrangements we would want to receive ourselves. And that means
          everything - from the type of flower in the bouquet to the bow we wrap
          it up with - matters.
        </Typography>
      </div>

      <div className={`${styles.infoGrid} our-motto`}>
        <div className={styles.infoGridText}>
          <Typography variant="h2" className={styles.h2}>
            Our motto.
          </Typography>

          <Typography variant="body1" className={styles.paragraph}>
            We've always done things a little differently at Farmgirl. Launched
            in San Francisco back in 2010 by founder and CEO Christina Stembel,
            our business model from the beginning has been{' '}
            <BoldText>you order, we pick, they're happy</BoldText>.
          </Typography>

          <Typography variant="body1" className={styles.paragraph}>
            What does this mean? Instead of choosing what goes in your bouquet
            we do the picking from the best of the best our growers have to
            offer. That means all of our mixed arrangements, like our
            burlap-wrapped bouquets and vases, are all a little different -{' '}
            <BoldText>and just as unique as your recipient</BoldText>.
          </Typography>

          <Typography variant="body1" className={styles.paragraph}>
            Like to dot your i's and cross your t's? We get that too! Our single
            variety arrangements, like our tulip bunches and dozen garden roses,
            are the best pick when the type of flower matters.
          </Typography>

          <Typography
            style={{ marginTop: '2em' }}
            variant="body1"
            className={styles.paragraph}
          >
            <BoldText>
              <StyledLink href="/shop" className="default">
                Take me to the flowers
              </StyledLink>
            </BoldText>
          </Typography>
        </div>
        <img src={OUR_MOTTO} alt="Our Motto" className={styles.infoGridImage} />
      </div>

      <div className="special-delivery">
        <div className={styles.description}>
          <Typography variant="h2" className={styles.h2}>
            Special delivery.
          </Typography>

          <Typography variant="body1" className={styles.paragraph}>
            We were born in the Bay Area but in the decade since we started
            we've gone <BoldText>international</BoldText>.
          </Typography>

          <Typography variant="body1" className={styles.paragraph}>
            Today Farmgirl proudly ships to recipients coast to coast from
            distribution centers and farm partner facilities across the United
            States, Colombia and Ecuador. As we have grown so has our network of
            farm partners, and we've racked up a number of frequent flier miles
            to source the best of the best from U.S. and international growers.
          </Typography>

          <Typography variant="body1" className={styles.paragraph}>
            We work with our transportation partners to ensure our flowers
            arrive to your doorstep fresh from the farm and boxed up beautifully
            for you or your recipient to enjoy.
          </Typography>
        </div>

        <div>
          <img
            src={SPECIAL_DELIVERY}
            alt="Special Delivery"
            className={`${styles.image} special-delivery`}
          />
        </div>
      </div>

      <div className={`${styles.description}`}>
        <Typography variant="h2" className={styles.h2}>
          Why burlap?
        </Typography>

        <Typography variant="body1" className={styles.paragraph}>
          The short answer? <i>Coffee, coffee, coffee</i>.
        </Typography>

        <Typography variant="body1" className={styles.paragraph}>
          Back when the company was still just a business plan, Christina wanted
          to figure out a picture perfect and sustainable presentation for
          Farmgirl's first daily arrangements. She created a list of
          alternatives to the single use plastics that contribute to hundreds of
          thousands of tons of plastic waste generated annually from the floral
          industry. At the top? The burlap sacks that potatoes shipped in back
          in her home state of Indiana. But while California was lacking in
          tubers, it was overflowing with coffee. A few calls to local coffee
          roasters later and Farmgirl's signature of the (upcycled!) burlap wrap
          was born.
        </Typography>
      </div>

      <div className={styles.founder}>
        <div className={`${styles.infoGrid} founder`}>
          <img
            src={FOUNDER}
            alt="Our Founder"
            className={`${styles.infoGridImage} founder`}
          />
          <div className={`${styles.infoGridText} founder`}>
            <Typography variant="h2" className={styles.h2}>
              Meet the founder.
            </Typography>

            <Typography variant="body1" className={styles.paragraph}>
              The farm girl <i>behind</i> Farmgirl, Christina Stembel was born
              and raised on a corn and soybean farm in Indiana. An early
              adventurous spirit led her to the Bay Area during the first dot
              com era, where she was bitten with the entrepreneurial bug to
              start her own company. Ten years, and at least a thousand ideas
              later, she created the first business model for Farmgirl -
              offering fewer, better offerings to cut through the noise of the
              hundreds of lackluster options offered by competitors. In November
              2010, she took her life savings of $49K and took the plunge to
              quit her job and launch Farmgirl Flowers from her tiny apartment
              in San Francisco.
            </Typography>

            <Typography variant="body1" className={styles.paragraph}>
              Without a single penny of outside investment, Christina has grown
              Farmgirl over the past 14 years into a wildly successful, beloved,
              national brand & the only large-scale, woman-owned and
              predominantly women-led D2C e-commerce flower company.
            </Typography>

            <Typography variant="body1" className={styles.paragraph}>
              Farmgirl ships signature burlap-wrapped bouquets, centerpieces,
              and plants to recipients from coast to coast. Every arrangement is
              made with heart and filled with the best-of-the-best, freshest,
              seasonal flowers that you’d not only want to send, but receive as
              well. And, in addition to creating more beautiful bouquets
              options, Farmgirl also works hard to create more environmental
              sound alternatives, wrapping their bouquets in compostable burlap
              to combat the floral industry’s plastic waste issues, as well as
              dramatically minimizing floral waste with their daily bouquet
              model.
            </Typography>

            <Typography variant="body1" className={styles.paragraph}>
              Christina is recognized as one of the leading experts on how to
              build & scale a profitable, completely bootstrapped company. She’s
              had the privilege of speaking at many events including for Target,
              Facebook, Google, and Fortune; collaborating with brands like
              Levi’s; and is regularly featured on the TODAY Show, Live with
              Kelly and Mark, Marketplace, Bloomberg Businessweek, People
              magazine, Forbes, Entrepreneur, Fast Company, CNN, MSNBC, Fortune,
              Consumer Reports, Marie Claire, Vogue and more.
            </Typography>
          </div>
        </div>
      </div>

      <div className={styles.donations}>
        <img src={WITH_HEART} alt="With Heart" height="150px" width="150px" />

        <Typography variant="h2" className={styles.h2}>
          Our heart.
        </Typography>

        <Typography variant="body1" className={styles.paragraph}>
          <BoldText>
            We're about good flowers, and we're about doing good too.
          </BoldText>
        </Typography>

        <Typography variant="body1" className={styles.paragraph}>
          We partner with nonprofits and other organizations as part of our With
          Heart program to donate to causes that are close to our team's heart.
          Since 2016 we've contributed almost $1M to organizations that work in
          the gender and racial equity spaces, in healthcare, relief efforts and
          more.
        </Typography>

        {/* <Typography className={styles.paragraph}>
                  Check out our current With Heart offerings{' '}<StyledLink href="">here</StyledLink>.
              </Typography> */}
      </div>
    </div>
  );
};

export default AboutUs;
