import { styled, withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

export const DialogStyled = withStyles(() => ({
  paper: {
    maxWidth: 550,
  },
}))(Dialog);

export const DialogTitleStyled = styled(DialogTitle)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: 0,
});

export const TypographyStyled = styled(Typography)({
  padding: '10px 0 5px',
  lineHeight: '34px',
  fontSize: '28px',
  fontWeight: 500,
});

export const FormTitleTypographyStyled = styled(Typography)({
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 700,
  marginBottom: '5px',
  textTransform: 'uppercase',
});

export const BodyTypographyStyled = styled(Typography)({
  fontSize: '18px',
  lineHeight: '24px',
  marginBottom: '30px',
});

export const LabelStyled = styled(Typography)({
  lineHeight: '20px',
  fontSize: '16px',
});

export const AccordionTypographyStyled = styled(Typography)({
  lineHeight: '16px',
  fontSize: '14px',
});

export const AccordionStyled = styled(Accordion)({
  marginTop: '24px !important',
  marginBottom: '20px !important',
  backgroundColor: '#F3F3F3',
  borderRadius: '4px',
  '& .MuiAccordion-root:before': {
    display: 'none',
  },
});

export const AccordionSummaryStyled = styled(AccordionSummary)({
  height: '38px',
  minHeight: '38px !important',
});

export const AccordionDetailsStyled = styled(AccordionDetails)({
  flexDirection: 'column',
  paddingTop: 0,
});

export const IconButtonStyled = styled(IconButton)({
  alignSelf: 'flex-start',
  position: 'relative',
  right: '-10px',
});

export const ButtonStyled = styled(Button)({
  margin: '5px auto 13px',
  padding: '12px 0 9px',
  '& > span': {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
  },
});
