import CloseRounded from '@material-ui/icons/CloseRounded';
import { Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

export const FiltersContainer = styled('aside')({
  display: 'flex',
  flexDirection: 'column',
  width: 320,
  minWidth: 320,
  padding: '20px 20px 24px 20px',
  border: '1px solid #ECECEC',
  borderRadius: 2,
  background: '#ffffff',
  height: '100%',
  overflowY: 'scroll',

  '&::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    width: 3,
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: 3,
    backgroundColor: '#CCCCCC',
  },
});

export const CloseIcon = styled(CloseRounded)({
  marginLeft: 6,
  marginRight: -6,
  cursor: 'pointer',
});

export const FilterHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 24,
});

export const FilterHeaderTypography = styled(Typography)({
  flex: 1,
  fontSize: 24,
  lineHeight: '30px',
});

export const Separator = styled('div')({
  width: '100%',
  height: 1,
  borderTop: '1px solid #ECECEC',
  margin: '24px 0',
});
