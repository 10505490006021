import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useState } from 'react';
import { DATE_FORMAT } from 'constants/time';
import { useReactKey } from 'hocs/ReactKeyProvider/ReactKeyContext';

import { GLOBAL_EVENTS } from 'events/constants';
import { useEventListener } from 'hooks/useEventListener';
import { ChangeDeliveryDateEventPayload } from './interface';
import { UnavailableDatesEvent } from '../interface';

const getInitialDateValue = (_date: string) => dayjs(_date, DATE_FORMAT);

function dispatchDateChangeEvent(date: Dayjs, reactKey: string) {
  const submitDate = date.format(DATE_FORMAT);

  const changeDateEvent = new CustomEvent<ChangeDeliveryDateEventPayload>(
    `${GLOBAL_EVENTS.product.changeDeliverDate}_${reactKey}`,
    {
      detail: {
        submitDate,
        reactKey,
        shouldSubmitCartForm: true,
      },
    },
  );
  document.dispatchEvent(changeDateEvent);
}

export const useDeliveryDates = (initialDate: string) => {
  const reactKey = useReactKey();

  /**
   * This is a datepicker internal state,
   * does not get dispatched to form,
   * only needed inside this component
   * */
  const [date, setDateState] = useState<Dayjs | null>(
    getInitialDateValue(initialDate),
  );
  const [unavailableDatesState, setUnavailableDates] = useState<string[]>([]);

  const handleDateChange = useCallback(
    (_date: MaterialUiPickersDate) => {
      setDateState(_date);
    },
    [setDateState],
  );

  /**
   * We only toggle a modal on Date accept (i.e. when user selects a date),
   * not when date changes programmatically
   * */
  const handleDateAccept = useCallback(
    (_date: MaterialUiPickersDate) => {
      dispatchDateChangeEvent(_date!, reactKey);
    },
    [dispatchDateChangeEvent],
  );

  const handleDeliveryDatesEvent = useCallback(
    (payload: UnavailableDatesEvent) => {
      const { unavailableDates } = payload;

      setUnavailableDates(unavailableDates);
    },
    [setUnavailableDates],
  );

  useEventListener<UnavailableDatesEvent>(
    GLOBAL_EVENTS.product.dispatchUnavailableDates,
    handleDeliveryDatesEvent,
  );

  return {
    date,
    reactKey,
    handleDateChange,
    handleDateAccept,
    unavailableDatesState,
  };
};
