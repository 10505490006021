import React from 'react';

import {
  ProductSkeletonStyled,
  ProductCardInfo,
  ImageSkeleton,
  TypographySkeleton,
  PriceSkeleton,
  TypographySkeleton2,
  TypographySkeleton3,
} from './styled';

const ProductSkeleton = ({
  resWithSoonestDate,
}: {
  resWithSoonestDate: boolean;
}) => (
  <ProductSkeletonStyled>
    <ImageSkeleton width="100%" animation="wave" variant="rect" />
    <ProductCardInfo>
      <TypographySkeleton
        width="50%"
        animation="wave"
        variant="rect"
        height={32}
      />
      <TypographySkeleton2
        width="70%"
        animation="wave"
        variant="rect"
        height={16}
      />
      <TypographySkeleton2
        width="70%"
        animation="wave"
        variant="rect"
        height={16}
      />
      {resWithSoonestDate && (
        <TypographySkeleton3
          width="70%"
          animation="wave"
          variant="rect"
          height={16}
        />
      )}
    </ProductCardInfo>
    <PriceSkeleton width="20%" animation="wave" variant="rect" height={46} />
  </ProductSkeletonStyled>
);

export default ProductSkeleton;
