/* eslint-disable no-bitwise */

/**
 * function that hashes our react key and appends to classNames as a prefix
 * to prevent css classes from conflicting
 */
export const HashReactKey = (str: string) => {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }

  const hashString = new Uint32Array([hash])[0].toString(36);
  const prefix = 'x';

  return `${prefix}${hashString.slice(1)}`;
};
