export enum ApiRoutes {
  Availability = '/availability.json',
  AvailabilityWithPrices = '/availability_with_prices.json',
  Cart = '/cart_lines.json',
  CartAddons = '/cart/update_add_ons',
  InterstitialAddons = '/cart/interstitial',
  ShopPageFilters = '/api/v1/shop_page_filters.json',
  Shop = '/api/v1/products',
  ShopAll = '/shop',
  ProductCount = '/api/v1/products_count',
  ZipCode = '/api/v1/zip_code',
  ZipCodes = '/api/v1/zip_codes',
  ZipCodeRailsForm = '/zip_code',
  GetInTouch = '/api/static/get_in_touch',
  LivstickVideoCheck = '/cart/handle_livstick_video_check',
}
