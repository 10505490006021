import React from 'react';
import DatePicker from 'components/Checkout/DeliveryDateControl/DeliveryDatePicker';
import { useReactKey } from 'hocs/ReactKeyProvider/ReactKeyContext';

import DatesRangePopper from './DatesRangePopper';
import DeliveryDatesInfo from './DeliveryDatesInfo';
import { useDeliveryDates } from './useDeliveryDates';
import { DeliveryDateProps } from './interface';

const DeliveryDate = ({
  page,
  maxDateOffset,
}: DeliveryDateProps): JSX.Element => {
  const reactKey = useReactKey();
  const {
    initialDate,
    formDate,
    unavailableDatesState,
    modalOpen,
    datesInfoVisible,
    toggleModal,
    toggleDatesInfoVisible,
    setFormDateCb,
    handleDateChange,
    handleDateAccept,
  } = useDeliveryDates(page);

  return (
    <>
      {initialDate && (
        <DatesRangePopper
          initialDate={initialDate}
          setFormDate={setFormDateCb}
          modalOpen={modalOpen}
          toggleModal={toggleModal}
          unavailableDates={unavailableDatesState}
        />
      )}
      {formDate && (
        <DeliveryDatesInfo
          visible={datesInfoVisible}
          toggleVisible={toggleDatesInfoVisible}
          date={formDate}
        />
      )}
      <DatePicker
        onDateChange={handleDateChange}
        onDateAccept={handleDateAccept}
        date={initialDate}
        datesInfoVisible={datesInfoVisible}
        unavailableDates={unavailableDatesState}
        mountKey={reactKey}
        maxDateOffset={maxDateOffset}
      />
    </>
  );
};

export default DeliveryDate;
