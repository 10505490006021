import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="navbar"
export default class extends Controller {
  connect() {}

  static get targets() {
    return ['menu', 'menuBackdrop'];
  }

  toggleMenu() {
    this.menuTarget.classList.toggle('translate-x-0');
    this.menuTarget.classList.toggle('-translate-x-full');

    this.menuBackdropTarget.classList.toggle('hidden');
    this.menuBackdropTarget.classList.toggle('fixed');
  }
}
