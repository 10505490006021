import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['togglingElement', 'trigger'];
  }

  static get values() {
    return { trigger: { type: String, default: 'true' } };
  }

  connect() {
    this.triggerTargets.forEach((radio) => {
      radio.addEventListener('change', this.toggleElementVisibility.bind(this));
    });
  }

  toggleElementVisibility(event) {
    this.togglingElementTargets.forEach((element) => {
      if (element.dataset.toggleVisibilityTriggerName !== event.target.name) {
        return;
      }

      if (element.dataset.toggleVisibilityWhen === event.target.value) {
        element.removeAttribute('disabled');
        element.classList.remove('hidden');
      } else {
        element.setAttribute('disabled', true);
        element.classList.add('hidden');
      }
    });
  }
}
