import { styled } from '@material-ui/core/styles';
import { down, up } from 'utils/breakpointHelpers';

export const LivstickCartLine = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',

  [down('sm')]: {
    display: 'block',
  },
});

export const LivstickInfoContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  color: '#000000',
});

export const LivstickPrice = styled('div')({
  marginLeft: 34,

  [up('sm')]: {
    marginLeft: 0,
    marginRight: 48,
  },
});
