import { withStyles } from '@material-ui/core/styles';
import MUISlider from '@material-ui/core/Slider';
import { COLORS } from 'theme/colors';

const BOX_SHADOW =
  '0px 0.5px 2px rgba(0, 0, 0, 0.12), 0px 6px 10px rgba(0, 0, 0, 0.12)';

export const Slider = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    height: 4,
    marginBottom: 5,
  },
  thumb: {
    height: 14,
    width: 14,
    backgroundColor: theme.palette.primary.main,
    border: `3px solid ${COLORS.white}`,
    boxShadow: BOX_SHADOW,
    '&:focus, &:hover, &$active': {
      boxShadow: BOX_SHADOW,
      border: `5px solid ${COLORS.white}`,
    },
  },
  active: {},
  track: {
    height: 4,
  },
  rail: {
    color: COLORS.inputGray,
    opacity: 1,
    height: 4,
  },
}))(MUISlider);
