import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { styled } from '@material-ui/core/styles';

import { Button } from 'ui-components/v2/Button';
import { down } from 'utils/breakpointHelpers';

export const CardStyled = styled(Card)({
  paddingInline: 10,
  width: '100%',
  background: 'transparent',
  '& > a > div > picture > img': {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  [down('md')]: {
    paddingInline: 6,
  },
});

export const CTAStyled = styled(Button)({
  width: '100%',
  height: '100%',
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  boxShadow: 'none !important',
});

export const CardContentStyled = styled(CardContent)({
  padding: '0 !important',
  width: '100%',
  height: '40px',
});
