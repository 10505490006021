import React from 'react';

interface ProductBadgeProps {
  label: string;
}

const ProductBadge = ({ label }: ProductBadgeProps) => (
  <div className="font-quincy absolute left-1/2 top-0 w-max -translate-x-[50%] rounded rounded-t-none bg-white px-4 py-2 text-center text-lg font-semibold uppercase italic text-black">
    {label}
  </div>
);

export default ProductBadge;
