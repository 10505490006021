import React, { useLayoutEffect, useRef } from 'react';

import Image from 'ui-components/Image';
import Modal from 'ui-components/v2/Modal';
import ProductPrice from 'components/ProductPrice';
import {
  ContainerStyled,
  ContentStyled,
  ActionButtonStyled,
  BodyStyled,
  TitleStyled,
  MediaStyled,
  NameStyled,
  PriceStyled,
  DescriptionStyled,
  InfoContainerStyled,
} from './styled';

interface DetailsModalProps {
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  name: string;
  cloudinary_id: string;
  price: string;
  sales_price: string | null;
  description: string;
}

const DetailsModal = ({
  open,
  setOpen,
  name,
  cloudinary_id: image,
  price,
  sales_price: salesPrice,
  description,
}: DetailsModalProps) => {
  const infoDivRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (infoDivRef.current) {
      infoDivRef.current.scroll({ top: 0 });
    }
  }, [open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      primaryButton={
        <ActionButtonStyled
          variant="contained"
          color="secondary"
          onClick={() => setOpen(false)}
        >
          Close
        </ActionButtonStyled>
      }
    >
      <ContainerStyled>
        <TitleStyled variant="h3">The Particulars</TitleStyled>
        <ContentStyled>
          <MediaStyled>
            <Image useWebP isSquare width="100%" alt={name} src={image} />
          </MediaStyled>
          <InfoContainerStyled ref={infoDivRef}>
            <NameStyled variant="body1">{name}</NameStyled>
            <PriceStyled variant="body1">
              <ProductPrice price={price} salesPrice={salesPrice} />
            </PriceStyled>
            <DescriptionStyled
              variant="body1"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </InfoContainerStyled>
        </ContentStyled>
        <BodyStyled
          variant="body2"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </ContainerStyled>
    </Modal>
  );
};

export default DetailsModal;
