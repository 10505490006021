import React from 'react';
import { Grid } from '@material-ui/core';

import withWrappers from 'hocs/ReactKeyProvider';
import ZipCodeField from 'components/ZipCodeField';
import DeliveryWindowPicker from 'components/DeliveryWindowPicker';
import { Product } from 'pages/pdp/interface';
import { useControl } from 'hooks/useControl';
import { PurchaseFormFieldNames as FIELD_NAMES } from 'constants/form';

interface ProductFormProps {
  product: Product;
}

const ProductForm = ({ product }: ProductFormProps) => {
  const control = useControl();
  if (!control) return null;

  return (
    <Grid container spacing={2} className="pb-8 md:pb-10">
      <Grid item xs={12} sm={6}>
        <ZipCodeField control={control} required />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DeliveryWindowPicker
          required
          control={control}
          productId={product.id}
          purchasable={product.purchasable}
          fieldName={FIELD_NAMES.date}
        />
      </Grid>
    </Grid>
  );
};

export default withWrappers(ProductForm);
