import React from 'react';

import Image from 'ui-components/Image';
import Modal from 'ui-components/v2/Modal';
import ProductPrice from 'components/ProductPrice';
import { Product } from 'pages/pdp/interface';
import { Addon } from 'components/Addons/interface';
import {
  ContainerStyled,
  ContentStyled,
  ActionButtonStyled,
  TitleStyled,
  MediaStyled,
  NameStyled,
  PriceStyled,
  ProductLineContainerStyled,
  InfoContainerStyled,
} from './styled';
import AddonItem from './AddonItem';

interface AddSuccessModalProps
  extends Pick<Product, 'name' | 'price' | 'sales_price'> {
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  image: string;
  addons: Addon[];
}

const AddSuccessModal = ({
  open,
  setOpen,
  name,
  image,
  price,
  addons,
  sales_price,
}: AddSuccessModalProps) => (
  <Modal
    open={open}
    setOpen={setOpen}
    primaryButton={
      <ActionButtonStyled variant="contained" color="primary" href="/cart">
        Checkout
      </ActionButtonStyled>
    }
    secondaryButton={
      <ActionButtonStyled variant="contained" color="secondary" href="/shop">
        Keep Shopping
      </ActionButtonStyled>
    }
  >
    <ContainerStyled>
      <TitleStyled variant="h3">Added to your bag</TitleStyled>
      <ContentStyled>
        <ProductLineContainerStyled style={{ marginBlock: 10 }}>
          <MediaStyled>
            <Image useWebP isSquare width="100%" alt={name} src={image} />
          </MediaStyled>
          <InfoContainerStyled>
            <NameStyled variant="body1">{name}</NameStyled>
            <PriceStyled variant="body1">
              Price: <ProductPrice price={price} salesPrice={sales_price} />
            </PriceStyled>
          </InfoContainerStyled>
        </ProductLineContainerStyled>
        {addons.length ? (
          <div>
            <NameStyled variant="subtitle1">Add-Ons</NameStyled>
            <ProductLineContainerStyled>
              {addons.map((addon) => (
                <AddonItem key={addon.product_id} {...addon} />
              ))}
            </ProductLineContainerStyled>
          </div>
        ) : null}
      </ContentStyled>
    </ContainerStyled>
  </Modal>
);

export default AddSuccessModal;
