import { Product } from 'pages/shop/interface';

export const getGroupedProducts = (products: Product[]) => {
  const groupedMap = new Map();

  products.forEach((product) => {
    const { grouping_id: groupingId } = product;

    if (!groupingId) {
      groupedMap.set(product.id, product);
      return;
    }

    if (!groupedMap.has(groupingId)) {
      groupedMap.set(groupingId, product);
    }
  });

  return Array.from(groupedMap.values());
};
