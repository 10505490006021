import { useEffect } from 'react';

type Item = {
  item_id: string;
  item_name: string;
  coupon?: string;
  discount?: number;
  item_category: string;
  price?: number;
  quantity?: number;
};

type PlatformType = 'ga4' | 'meta';

interface GAEvent {
  event:
    | 'filters_open'
    | 'calendar_open'
    | 'select_item'
    | 'view_item'
    | 'add_to_cart'
    | 'remove_from_cart'
    | 'view_cart'
    | 'purchase';
  ecommerce?: {
    currency?: 'USD';
    value?: number;
    coupon?: string;
    transaction_id?: string;
    shipping?: number;
    tax?: number;
    items: Item[];
  };
}

interface MetaEvent {
  event_name:
    | 'ViewContent'
    | 'AddToCart'
    | 'InitiateCheckout'
    | 'AddPaymentInfo'
    | 'Purchase';
  event_time: number;
  action_source: 'website';
  user_data?: {
    [x in
      | 'em'
      | 'ph'
      | 'fn'
      | 'ln'
      | 'ct'
      | 'st'
      | 'zp'
      | 'country'
      | 'client_user_agent']?: Array<string | null> | string;
  };
  custom_data: {
    currency: 'USD';
    value: string;
    content_type: 'product';
    content_ids: string[];
  };
}

type PlatformToEventMap = {
  ga4: GAEvent;
  meta: MetaEvent;
};

const pushMethods = {
  ga4: 'pushEventToDataLayer',
  meta: 'postEventToMeta',
};

export const pushMetricsEvent = <T extends PlatformType>(
  platform: T,
  event: PlatformToEventMap[T],
) => {
  const pushMethod = (window as any)[pushMethods[platform]];
  if (pushMethod) pushMethod(event);
};

export const useMetricsEvent = <T extends PlatformType>(
  platform: T,
  event: PlatformToEventMap[T],
) => {
  useEffect(() => {
    const pushMethod = (window as any)[pushMethods[platform]];
    const onPageLoad = () => {
      if (pushMethod) pushMethod(event);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
    }

    return () => window.removeEventListener('load', onPageLoad);
  }, []);
};
