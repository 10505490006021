import { Box, Link } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Check } from '@material-ui/icons';
import { COLORS } from 'theme/colors';

export const Container = styled(Box)({
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  gap: 14,
  alignItems: 'center',
});

export const CheckStyled = styled(Check)({
  color: '#000',
  background: COLORS.quicksand,
  borderRadius: '50%',
  width: 50,
  height: 50,
});

export const LivstickLink = styled(Link)({
  color: COLORS.fluorescentPink,
  textDecoration: 'underline',
});
