export enum ProductsReducerActionTypes {
  addProducts = 'ADD_PRODUCTS',
  refetchProducts = 'REFETCH_PRODUCTS',
  refetchProductsFail = 'REFETCH_PRODUCTS_FAIL',
  refetchProductsSuccess = 'REFETCH_PRODUCTS_SUCCESS',
  pageIncrement = 'PAGE_INCREMENT',
  enableRequests = 'ENABLE_REQUESTS',
}

export enum ProductsFormFieldNames {
  zipCode = 'zip_code',
  priceRange = 'price_range',
  deliveryDate = 'delivery_date',
  discountable_only = 'discountable_only',
  sortBy = 'sort_by',
  productType = 'product_type',
  flowerType = 'flower_type',
  occasion = 'occasion',
  collection = 'collection',
  marketingCategory = 'marketing_category',
}

export const SORTBY_VALUES = {
  lowToHigh: 'price-low-to-high',
  highToLow: 'price-high-to-low',
  recommended: 'recommended',
  availability: 'availability',
};

export const PRODUCTS_FORM_DEFAULT_VALUES = {
  [ProductsFormFieldNames.zipCode]: null,
  [ProductsFormFieldNames.deliveryDate]: null,
  [ProductsFormFieldNames.discountable_only]: null,
  [ProductsFormFieldNames.sortBy]: SORTBY_VALUES.recommended,
  [ProductsFormFieldNames.occasion]: [],
  [ProductsFormFieldNames.productType]: [],
  [ProductsFormFieldNames.flowerType]: [],
  [ProductsFormFieldNames.collection]: [],
};
