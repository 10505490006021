import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';

import LivstickWidget from 'components/LivstickWidget';
import { Button } from 'ui-components/v2/Button';
import { LivstickLink, Container, CheckStyled } from './styled';

interface OrderConfirmationProps {
  cartId: string;
  cartEmail: string;
  livstickAPIKey: string;
}

const OrderConfirmation = ({
  cartId,
  cartEmail,
  livstickAPIKey,
}: OrderConfirmationProps) => {
  const [showWidget, setShowWidget] = useState(false);
  const [recorded, setRecorded] = useState(false);

  useEffect(() => {
    document.addEventListener('livstick-message-recorded', () =>
      setRecorded(true),
    );
    return () =>
      document.removeEventListener('livstick-message-recorded', () =>
        setRecorded(false),
      );
  }, []);

  return (
    <Container>
      {!recorded ? (
        <>
          <Button onClick={() => setShowWidget(true)} variant="outlined">
            Record Video Message
          </Button>
          <Typography variant="body1">or</Typography>
          <LivstickLink
            href={`https://public.api.livstick.com/crid/${livstickAPIKey}/${cartId}`}
          >
            Click Here to Record on Website
          </LivstickLink>
        </>
      ) : (
        <>
          <CheckStyled fontSize="large" />
          <Typography variant="body2">
            Your message has been recorded. You will receive an email shortly to
            confirm.
          </Typography>
          <Button
            href={`https://public.api.livstick.com/crid/${livstickAPIKey}/${cartId}`}
            variant="outlined"
          >
            View Video Message
          </Button>
        </>
      )}
      {showWidget && !recorded ? (
        <LivstickWidget
          cartEmail={cartEmail}
          cartId={cartId}
          livstickAPIKey={livstickAPIKey}
          showWidget={showWidget}
          setShowWidget={setShowWidget}
        />
      ) : null}
    </Container>
  );
};

export default OrderConfirmation;
