import React from 'react';
import { Typography, useMediaQuery } from '@material-ui/core';

import { down } from 'utils/breakpointHelpers';
import { pushMetricsEvent } from 'hooks/useDataLayer';
import { HeaderContainer } from './styled';
import { ResultsHeaderProps } from './interface';
import { SortBy } from '../Filters/SortBy';
import FiltersButton from '../Filters/FiltersButton';

const ResultsHeader = ({
  total,
  control,
  openFilter,
  openFilters,
}: ResultsHeaderProps) => {
  const matches = useMediaQuery(down('lg'));

  return (
    <HeaderContainer>
      {!matches && !openFilters.desktop ? (
        <FiltersButton
          show={() => {
            pushMetricsEvent('ga4', { event: 'filters_open' });
            openFilter((prev) => ({ mobile: false, desktop: !prev.desktop }));
          }}
        />
      ) : null}
      <Typography
        variant="body2"
        className="font-brandon flex-grow !font-medium"
      >
        {total} Results
      </Typography>
      <SortBy control={control} />
      {matches ? (
        <FiltersButton
          show={() => openFilter({ mobile: true, desktop: false })}
        />
      ) : null}
    </HeaderContainer>
  );
};

export default ResultsHeader;
