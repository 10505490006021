import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { Button } from 'ui-components/v2/Button';
import { FieldValues, UseFormReset } from 'react-hook-form';
import { useEmptyStateStyles, NoResults } from './styled';

interface EmptyStateProps {
  reset: UseFormReset<FieldValues>;
  // TODO: This should be a Date type
  deliveryDate: String | null;
}

export const EmptyState: FC<EmptyStateProps> = ({ reset, deliveryDate }) => {
  const styles = useEmptyStateStyles();

  return (
    <div>
      <NoResults>
        <Typography variant="h3" className={styles.noResultTitle}>
          {deliveryDate
            ? `No results for delivery on ${deliveryDate} `
            : 'NO RESULTS'}
        </Typography>
        <Typography variant="body1" className={styles.noResultDesc}>
          Oh no! We couldn't find results for your filters. <br />
          Please select other options or try a different delivery date.
        </Typography>
        <div className="flex gap-3">
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={reset}
            className="tracking-no-results-cta-reset-filters"
          >
            Reset Filters
          </Button>
          <Button
            variant="contained"
            color="secondary"
            href="/shop"
            className="tracking-no-results-cta-shop-all"
          >
            Shop All Products
          </Button>
        </div>
      </NoResults>
    </div>
  );
};
