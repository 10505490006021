import React from 'react';
import { RadioProps, Radio as MUIRadio, Theme } from '@material-ui/core';
import { styled, withStyles } from '@material-ui/core/styles';
import { COLORS } from 'theme/colors';

export const RadioIconStyled = styled('span')({
  width: 18,
  height: 18,
  margin: '0 3px',
  border: `1px solid ${COLORS.dividerGray}`,
  borderRadius: 10,
  background: COLORS.inputGray,
});

export const Radio = withStyles((theme: Theme) => ({
  root: {
    color: COLORS.dividerGray,
    paddingRight: 13,
    '&$checked': {
      color: theme.palette.primary.main,
    },
    '&&:hover': {
      backgroundColor: 'transparent',
      '& > span': {
        '& > span': {
          border: `1px solid ${COLORS.borderGray}`,
        },
        '& > div': {
          '& > svg': {
            color: theme.palette.primary.main,
          },
        },
        '& > div > svg ~ svg > path': {
          color: COLORS.checkboxHover,
        },
      },
    },
    '&.MuiRadio-root.Mui-disabled': {
      backgroundColor: 'transparent',
      '& > span': {
        '& > span': {
          border: `1px solid ${COLORS.inputGray}`,
        },
        '& > div': {
          '& > svg': {
            color: theme.palette.action.disabled,
          },
        },
      },
    },
  },
  checked: {},
}))((props: RadioProps) => (
  <MUIRadio
    color="default"
    icon={<RadioIconStyled />}
    disableRipple
    {...props}
  />
));
