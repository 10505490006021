import { Box, Grid, styled } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import { COLORS } from 'theme/colors';
import { down } from 'utils/breakpointHelpers';

export const ContainerStyled = styled(Grid)({
  gap: 20,
  [down('md')]: {
    gap: 16,
  },
  [down('sm')]: {
    gap: 0,
  },
});

export const NavContainerStyled = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  width: 81,
  maxHeight: 405,
  gap: 20,
  [down('md')]: {
    width: 54,
    maxHeight: 270,
    gap: 16,
  },
  [down('sm')]: {
    display: 'none',
  },
});

export const ThumbnailContainerStyled = styled(Box)({
  position: 'relative',
  cursor: 'pointer',
  width: 79,
  height: 79,
  [down('md')]: {
    width: 52,
    height: 52,
  },
});

export const ThumbnailOverlayStyled = styled(Box)({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  background: 'rgba(208, 213, 221, 0.4)',
});

export const IconButtonStyled = styled(IconButton)({
  color: 'black',
  margin: 'auto',
  display: 'block !important',
});

export const PlayIconStyled = styled(PlayArrowIcon)({
  position: 'absolute',
  top: 0,
});

export const CenterContainerStyled = styled(Grid)({
  width: 'calc(100% - 110px)',
  [down('md')]: {
    width: 'calc(100% - 75px)',
  },
  [down('sm')]: {
    width: '100%',
  },
});

export const DotListStyled = styled('ul')({
  padding: 0,
  marginTop: 16,
  marginBottom: 25,
  listStyle: 'none',
  display: 'flex',
  justifyContent: 'center',
  gap: 7,
  '& > .slick-active > div': {
    background: COLORS.black,
  },
  [down('md')]: {
    marginTop: 16,
  },
  [down('sm')]: {
    marginTop: 12,
    marginBottom: 0,
  },
});

export const DotStyled = styled(Box)({
  width: 8,
  height: 8,
  background: COLORS.black10,
  borderRadius: '50%',
  cursor: 'pointer',
});
