import { useMediaQuery, useTheme } from '@material-ui/core';

type Config = {
  infinite: boolean;
  centerMode: boolean;
  slidesToShow: number;
  centerPadding?: string;
};

export const useAddonsUtils = (amount: number) => {
  const theme = useTheme();
  const matchesLg = useMediaQuery(theme.breakpoints.up('md'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

  let config: Config = { centerMode: false, slidesToShow: 1, infinite: false };

  if (matchesLg) {
    config = { centerMode: false, slidesToShow: 4, infinite: amount > 4 };
  } else if (matchesMd) {
    config = {
      centerMode: false,
      slidesToShow: 4,
      infinite: amount > 4,
    };
  } else if (matchesSm) {
    config = {
      centerMode: false,
      slidesToShow: 2,
      infinite: amount > 2,
    };
  }

  return config;
};
