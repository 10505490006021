import { Box, Typography, styled } from '@material-ui/core';
import { down } from 'utils/breakpointHelpers';

export const ContainerPositionLg = styled('div')({
  position: 'fixed',
  bottom: -104,
  right: 0,
  left: 0,
  height: 100,
  zIndex: 9999,
  transition: 'top 500ms',
  [`${down('md')} and (orientation: landscape)`]: {
    height: 76,
  },
});

export const ContainerPosition = styled('div')({
  position: 'fixed',
  bottom: -203,
  right: 0,
  left: 0,
  height: 200,
  zIndex: 99,
  transition: 'bottom 500ms',
  [down('sm')]: {
    height: 113,
  },
});

export const ContainerStyled = styled(Box)({
  display: 'flex',
  backgroundColor: '#FFF',
  height: 100,
  boxShadow: '0px 4px 3px rgba(0, 0, 0, 0.25)',
  padding: '12px 52px 12px 25px',
  gap: 44,
  [`${down('md')} and (orientation: portrait)`]: {
    height: 200,
    padding: '28px 71px 28px 41px',
    boxShadow: '0px -3px 4px rgba(0, 0, 0, 0.25)',
  },
  [`${down('md')} and (orientation: landscape)`]: {
    height: 76,
  },
  [down('sm')]: {
    gap: 17,
    height: 113,
    padding: '14px 15px',
  },
});

export const ImageContainerStyled = styled(Box)({
  width: 76,
  [`${down('md')} and (orientation: portrait)`]: {
    width: 144,
  },
  [`${down('md')} and (orientation: landscape)`]: {
    width: 52,
  },
  [down('sm')]: {
    width: 83,
  },
});

export const TitleStyled = styled(Typography)({
  fontSize: 34,
  lineHeight: '40px',
  [`${down('md')} and (orientation: portrait)`]: {
    lineHeight: '40px',
  },
  [`${down('md')} and (orientation: landscape)`]: {
    fontSize: 24,
    lineHeight: '24px',
  },
  [down('sm')]: {
    fontSize: 16,
    maxWidth: 216,
    lineHeight: '16px',
  },
});

export const ATCContainerStyled = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  height: '100%',
  alignItems: 'center',
  width: 'min-content',
  justifyContent: 'space-between',
  [`${down('md')} and (orientation: portrait)`]: {
    gap: 12,
    flexDirection: 'column',
    justifyContent: 'center',
    '& > *': {
      width: '100%',
    },
  },
});
