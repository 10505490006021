import React, { Ref, forwardRef } from 'react';
import {
  Checkbox as MUICheckbox,
  CheckboxProps,
  Theme,
} from '@material-ui/core';
import { styled, withStyles } from '@material-ui/core/styles';
import { COLORS } from 'theme/colors';

const CheckboxIconStyled = styled(({ className, ...props }) => (
  <span {...props} className={`${className} custom-checkbox-icon`} />
))({
  width: 18,
  height: 18,
  border: `1px solid ${COLORS.black}`,
  borderRadius: 2,
  transition: 'all 0.1s ease-in',
});

export const Checkbox = withStyles((theme: Theme) => ({
  root: {
    width: 38,
    height: 38,
    boxSizing: 'border-box',
    transition: 'all 0.2s ease-in',
    color: COLORS.dividerGray,
    '&$checked': {
      color: theme.palette.primary.main,
      '&:hover': {
        '& .MuiIconButton-label': {
          content: '""',
          height: 18,
          width: 18,
          position: 'absolute',
          backgroundColor: 'white',
          zIndex: -1,
        },
        '& .MuiIconButton-label:after': {
          content: '""',
          height: 18,
          width: 18,
          position: 'absolute',
          backgroundColor: COLORS.checkboxHover,
          zIndex: -1,
        },
      },
    },
    '&$checked&$disabled': {
      color: theme.palette.action.disabled,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.667rem',
    },
  },
  checked: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  disabled: {
    '& .custom-checkbox-icon': {
      borderColor: COLORS.inputGray,
    },
  },
}))(
  forwardRef((props: CheckboxProps, ref: Ref<HTMLInputElement> | null) => (
    <MUICheckbox
      color="default"
      icon={<CheckboxIconStyled />}
      disableRipple
      inputRef={ref}
      {...props}
    />
  )),
);
