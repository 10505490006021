import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { down } from 'utils/breakpointHelpers';

export const RootStyled = styled(Box)({
  width: '20%',
  [down('sm')]: {
    width: 'calc(100%/3 - 14px)',
  },
});

export const CardHeaderStyled = styled(Typography)({
  fontWeight: 'bold',
  marginBlock: 6,
});
