import React from 'react';
import Close from '@material-ui/icons/Close';
import MUIModal from '@material-ui/core/Modal';

import { ModalClose } from 'ui-components/v2/Modal/styled';
import { ModalStyled, ModalTitle, RedesignedBackdrop } from './styled';

interface IProps {
  handleClose: () => void;
  show: boolean;
  title?: string;
}

const Modal: React.FC<IProps> = (props) => {
  const { handleClose, show = false, children, title } = props;

  return (
    <MUIModal
      keepMounted
      open={show}
      onClose={handleClose}
      BackdropComponent={RedesignedBackdrop}
    >
      <ModalStyled>
        <ModalClose className="close-icon" onClick={handleClose}>
          <Close />
        </ModalClose>
        <ModalTitle>{title}</ModalTitle>
        {children}
      </ModalStyled>
    </MUIModal>
  );
};

export default Modal;
