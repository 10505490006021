import { useMediaQuery, useTheme } from '@material-ui/core';

export const useAddonsUtils = (amount: number) => {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));

  return matchesMd
    ? { slidesToShow: 3, infinite: amount > 3 }
    : {
        slidesToShow: 2,
        infinite: amount > 2,
      };
};
