import React, { useCallback } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useWatch } from 'react-hook-form';

import Image from 'ui-components/Image';
import Modal from 'ui-components/v2/Modal';
import ProductPrice from 'components/ProductPrice';
import { HookFormProps } from 'pages/pdp/interface';
import { dateFormatter, isDateDeliveryChecker } from 'utils/dateHelpers';
import { PurchaseFormFieldNames as FIELD_NAMES } from 'constants/form';
import {
  ContainerStyled,
  ActionButtonStyled,
  TitleStyled,
  ContentStyled,
  MediaStyled,
  NameStyled,
  PriceStyled,
  InfoStyled,
} from './styled';

interface SubscriptionFormProps extends Pick<HookFormProps, 'control'> {
  open: boolean;
  image: string;
  name: string;
  price: string;
  sales_price: string | null;
  setOpen: (value: React.SetStateAction<boolean>) => void;
}

const SubscriptionFinal = ({
  open,
  setOpen,
  name,
  image,
  price,
  sales_price: salesPrice,
  control,
}: SubscriptionFormProps) => {
  const startDate = useWatch({ control, name: FIELD_NAMES.startDate });
  const endDate = useWatch({ control, name: FIELD_NAMES.endDate });
  const frequency = useWatch({ control, name: FIELD_NAMES.frequency });

  const getSubscriptionStartWording = useCallback((date: Dayjs) => {
    if (!date.isValid()) return '';

    const firstDate = date.format('MM/DD');
    const nextDate = date.add(1, 'day').format('MM/DD/YYYY');

    return isDateDeliveryChecker(date)
      ? `Delivery Date: ${date.format('MM/DD/YYYY')}`
      : `Delivery Window: ${firstDate}-${nextDate}`;
  }, []);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      primaryButton={
        <ActionButtonStyled variant="contained" color="primary" href="/cart">
          Checkout
        </ActionButtonStyled>
      }
      secondaryButton={
        <ActionButtonStyled variant="contained" color="secondary" href="/shop">
          Keep Shopping
        </ActionButtonStyled>
      }
    >
      <ContainerStyled>
        <TitleStyled variant="h3">Your Recurring Delivery Is Set!</TitleStyled>
        <ContentStyled>
          <MediaStyled>
            <Image useWebP isSquare width="100%" alt={name} src={image} />
          </MediaStyled>
          <div>
            <NameStyled variant="body1" gutterBottom>
              {name}
            </NameStyled>
            <PriceStyled variant="body1" gutterBottom>
              <ProductPrice price={price} salesPrice={salesPrice} />
            </PriceStyled>
            <InfoStyled variant="body1">
              {getSubscriptionStartWording(dayjs(dateFormatter(startDate)))}
            </InfoStyled>
            {endDate ? (
              <InfoStyled variant="body1">
                End date: {dayjs(dateFormatter(endDate)).format('MM/DD/YYYY')}
              </InfoStyled>
            ) : null}
            <InfoStyled variant="body1">Frequency: {frequency}</InfoStyled>
          </div>
        </ContentStyled>
      </ContainerStyled>
    </Modal>
  );
};

export default SubscriptionFinal;
