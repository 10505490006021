import { styled } from '@material-ui/core/styles';
import { down } from 'utils/breakpointHelpers';

export const WhereToDeliverContainer = styled('div')({
  width: '180px',

  [down('sm')]: {
    width: '100%',
  },
});
