import React from 'react';
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'config/dayjs';
import { ReportHandler } from 'web-vitals';
import muiTheme from 'theme/mui-theme';
import { WrappersProps } from './interface';
import { HashReactKey } from './HashReactKey';

const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export const generateClassName = (reactKey: string) =>
  createGenerateClassName({
    seed: HashReactKey(reactKey),
  });

export const Wrappers = ({
  children,
  reactKey,
  customTheme,
}: WrappersProps) => {
  React.useEffect(() => {
    if (!document) return;

    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  const themeProvided = customTheme ?? muiTheme;

  return (
    <ThemeProvider theme={themeProvided}>
      <StylesProvider generateClassName={generateClassName(reactKey)}>
        <MuiPickersUtilsProvider utils={DayjsUtils}>
          {children}
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
