import { styled, Typography } from '@material-ui/core';
import { down, up } from 'utils/breakpointHelpers';

export const MainSeparator = styled('div')({
  width: '100%',
  height: 1,
  backgroundColor: '#ECECEC',
});

export const Separator = styled('div')({
  width: '100%',
  height: 1,
  backgroundColor: '#ECECEC',
  margin: '24px 0',
});

export const FiltersModal = styled('div')({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1100,
  background: 'rgba(0, 0, 0, 0.25)',

  [up('lg')]: {
    display: 'none',
  },
});

export const WhiteSpace = styled('div')({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: '60%',
  left: 0,
  zIndex: 151,
  background: '#FFF',
  [down('md')]: {
    width: '80%',
    height: '100vh',
    maxWidth: 450,
    right: 0,
    left: 'auto',
  },
});

export const FiltersHeader = styled('div')({
  display: 'flex',
  width: '100%',
  gap: 24,
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 24,
  paddingRight: 15,
});

export const FiltersContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '20px 24px 80px 24px',
  maxWidth: '100vw',
  overflowY: 'auto',
  height: '100vh',
});

export const Title = styled(Typography)({
  fontWeight: 500,
  flexGrow: 1,
  alignSelf: 'flex-end',
});
