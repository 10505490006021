import React, { useEffect, useState } from 'react';

import { SEOCopy } from 'components/SEOField';
import { safeJSONParse } from 'utils/safeJSONParse';
import { PageContainer, PageContent, Separator, TopSection } from './styled';
import { ShopPageFilters, ShopPageProps } from './interface';
import { PathBreadcrumbs } from './PathBreadcrumbs';
import { Filters } from './Filters';
import { useFilters } from './hooks/useFilters';
import { useProducts } from './hooks/useProducts';
import Results from './Results';
import MobileFilters from './Filters/MobileFilters';
import ScrollToTop from './ScrollToTop';
import PageHeader from './PageHeader';
import ResultsHeader from './ResultsHeader';

const Shop = ({ products_ssr, filters, groupingEnabled }: ShopPageProps) => {
  const parsedFilters: ShopPageFilters = safeJSONParse(filters);
  const {
    shop_page: shopPage,
    min_price: minPrice,
    max_price: maxPrice,
  } = parsedFilters;
  const priceRange = [Number(minPrice), Number(maxPrice)];
  const [breadcrumbs, setBreadcrumb] = useState([
    {
      title: 'Home',
      href: '/',
    },
    {
      title: 'Shop All',
      href: '/shop',
    },
  ]);

  useEffect(() => {
    if (shopPage) {
      const newBreadcrumbs = breadcrumbs;
      if (shopPage.title && shopPage.slug.toLowerCase() !== 'regular') {
        newBreadcrumbs.push({
          title: shopPage.title,
          href: `/shop/${shopPage.slug}`,
        });
        setBreadcrumb(newBreadcrumbs);
      }
    }
  }, []);

  // filters form
  const { control, watch, reset, appliedFiltersNumber } = useFilters({
    ...parsedFilters,
    priceRange,
  });

  // results fetching
  const {
    products,
    loading,
    loadMore,
    hasLoadedOnce,
    hasMore,
    resWithSoonestDate,
    totalProducts,
    hasAborted,
  } = useProducts(watch, products_ssr);

  const [openFilters, setOpenFilters] = useState({
    mobile: false,
    desktop: false,
  });

  useEffect(() => {
    // to prevent the shop page from scrolling
    // when user scrolls in the filter pane
    if (openFilters.mobile) {
      document.body.style.overflow = 'hidden';
      return;
    }
    document.body.style.overflow = 'scroll';
  }, [openFilters.mobile]);

  return (
    <>
      <PageContainer>
        <TopSection>
          <PathBreadcrumbs breadcrumbs={breadcrumbs} disablePadding />
          {/* TODO: fix the "as any" below */}
          <PageHeader control={control as any} title={shopPage?.title} />
        </TopSection>
        <Separator />
        <PageContent>
          <Filters
            control={control}
            reset={reset}
            filterCount={appliedFiltersNumber}
            open={openFilters.desktop}
            priceRange={priceRange}
            hide={() => setOpenFilters({ ...openFilters, desktop: false })}
          />
          <div className="w-full">
            <ResultsHeader
              control={control}
              total={totalProducts}
              openFilters={openFilters}
              openFilter={setOpenFilters}
            />
            <Results
              control={control}
              products={products}
              loading={loading}
              hasAborted={hasAborted}
              hasLoadedOnce={hasLoadedOnce}
              hasMore={hasMore}
              loadMore={loadMore}
              groupingEnabled={groupingEnabled && !appliedFiltersNumber}
              resWithSoonestDate={resWithSoonestDate}
              reset={reset}
            />
            {shopPage.seo_copy && <SEOCopy text={shopPage.seo_copy} />}
          </div>
        </PageContent>
      </PageContainer>
      {openFilters.mobile && (
        <MobileFilters
          control={control}
          reset={reset}
          priceRange={priceRange}
          hide={() => setOpenFilters({ ...openFilters, mobile: false })}
        />
      )}
      <ScrollToTop />
    </>
  );
};

export default Shop;
