/**
 * Fetches the feature flags from the window object.
 *
 * @returns Record<string, boolean> of feature flags
 */
export default function featureFlags(): Record<string, boolean> {
  const flags = typeof window !== 'undefined' && window.featureFlags;
  return flags || {};
}

function findFeatureFlag(feature: string): boolean | undefined {
  return featureFlags()[feature];
}

export function isFeatureEnabled(feature: string): boolean {
  const flag = findFeatureFlag(feature);
  return typeof flag === 'boolean' ? flag : false;
}
