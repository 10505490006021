import React from 'react';

import { ResetButtonProps } from './interface';
import { StyledButton } from './styled';

export function ResetButton({ onClick }: ResetButtonProps) {
  return (
    <StyledButton
      variant="outlined"
      color="default"
      onClick={onClick}
      disableElevation
    >
      CLEAR
    </StyledButton>
  );
}
