/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useState } from 'react';
import Close from '@material-ui/icons/Close';
import { useReactKey } from 'hocs/ReactKeyProvider/ReactKeyContext';
import { isDateDeliveryChecker } from 'utils/dateHelpers';
import { getDateLabel } from '../helpers/getDateLabel';

import { DeliveryDatesInfoProps } from './interface';
import {
  RootStyled,
  TypographyStyled,
  LinkStyled,
  PopupButtonStyled,
  ContainerStyled,
  PopupContainer,
  Popup,
  PopupTitle,
  PopupText,
  PopupLink,
  PopupClose,
  TypographyStyledPopup,
} from './styled';

const DeliveryDatesInfo: FC<DeliveryDatesInfoProps> = ({
  visible,
  toggleVisible,
  date,
}) => {
  const [showPopup, setShow] = useState(false);
  const reactKey = useReactKey();
  if (!visible) return null;

  const handleHide = () => toggleVisible(false);
  const datesWindowLabel = getDateLabel(date);

  return (
    <RootStyled reactkey={reactKey}>
      <TypographyStyled>
        {isDateDeliveryChecker(date) ? 'Delivery date' : 'Delivery window'}
      </TypographyStyled>
      <ContainerStyled>
        <TypographyStyledPopup>
          {datesWindowLabel}
          {!isDateDeliveryChecker(date) && (
            <PopupButtonStyled
              role="dialog"
              aria-label="what is a delivery window"
              onClick={() => {
                setShow(true);
              }}
            >
              ?
            </PopupButtonStyled>
          )}
        </TypographyStyledPopup>
        {showPopup && (
          <PopupContainer>
            <Popup>
              <PopupClose aria-label="Close">
                <Close
                  onClick={() => {
                    setShow(false);
                  }}
                />
              </PopupClose>
              <PopupTitle>What is "Delivery Window"?</PopupTitle>
              <PopupText tabIndex={0}>
                Rather than selecting a single date, you will now see the option
                to select a two-day delivery window for your flowers. Select the
                two-day delivery window that works best for you and your
                recipient, and we will deliver on either the first or second day
                in your window.
              </PopupText>
              <PopupLink
                target="_blank"
                href="https://farmgirlflowers.com/help-center?a=What-is-a-delivery-window---id--96VTewyASqqWt7R0LkIsvA"
              >
                Read More
              </PopupLink>
            </Popup>
          </PopupContainer>
        )}
      </ContainerStyled>
      <LinkStyled onClick={handleHide}>Edit</LinkStyled>
    </RootStyled>
  );
};

export default DeliveryDatesInfo;
