import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['submitButton', 'observedInput'];
  }

  connect() {
    this.updateButtonState();

    this.observedInputTargets.forEach((input) => {
      input.addEventListener('input', this.updateButtonState.bind(this));
    });
  }

  updateButtonState() {
    const formIsEmpty = this.observedInputTargets.some(
      (input) => input.value === '',
    );

    if (formIsEmpty) {
      this.submitButtonTarget.disabled = true;
    } else {
      this.submitButtonTarget.disabled = false;
    }
  }
}
