import React, { useState } from 'react';
import Modal from 'ui-components/v2/Modal';

import { Typography } from '@material-ui/core';
import {
  ContainerStyled,
  ActionButtonStyled,
  TitleStyled,
  LinkStyled,
} from './styled';

interface IProps {
  path: string;
  label: string;
  cardType: string;
  expirationDate: string;
}

const PaymentDeleteConfirmation = ({
  label,
  cardType,
  expirationDate,
  path,
}: IProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <LinkStyled onClick={() => setOpen(true)}>Remove</LinkStyled>
      <Modal
        open={open}
        setOpen={setOpen}
        primaryButton={
          <ActionButtonStyled
            href={path}
            color="primary"
            variant="contained"
            data-method="delete"
          >
            Yes, Remove Payment
          </ActionButtonStyled>
        }
        secondaryButton={
          <ActionButtonStyled
            variant="contained"
            color="secondary"
            onClick={() => setOpen(false)}
          >
            Cancel
          </ActionButtonStyled>
        }
      >
        <ContainerStyled>
          <TitleStyled variant="h4">Remove This Payment Method?</TitleStyled>
          <div>
            <Typography variant="body2">
              <b style={{ textTransform: 'uppercase' }}>{cardType}</b>
              <br />
              {label}
              <br />
              Exp. {expirationDate}
            </Typography>
          </div>
        </ContainerStyled>
      </Modal>
    </>
  );
};

export default PaymentDeleteConfirmation;
