import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="four-sixty"
// Load the FourSixty JS widget
export default class extends Controller {
  connect() {
    // If screensize is mobile, then we want to show 4 items, otherwise 8
    let pageSize = 8;
    if (window.innerWidth < 768) {
      pageSize = 4;
    }

    this.loadFourSixty(pageSize);
  }

  loadFourSixty(pageSize) {
    const script = document.createElement('script');
    script.src = '//foursixty.com/media/scripts/fs.embed.v2.5.js';
    script.setAttribute('data-feed-id', 'farmgirl-flowers');
    script.setAttribute('data-theme', 'showcase_v2_5');
    script.setAttribute('data-category-filter', 'homepage');
    script.setAttribute('data-page-size', pageSize);

    // Replace this.element with the script element, we can't use a child
    // element, because then FourSixty doesn't load with the correct size
    this.element.replaceWith(script);
  }
}
