import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';

import { COLORS } from 'theme/colors';
import { AdjustedShippingDayProps } from './interface';

const useStyles = makeStyles((theme) => ({
  root: (props: { shouldHighlight: boolean; selected: boolean }) => ({
    width: '100%',
    height: 48,
    padding: 0,
    margin: 0,
    borderRadius: 1,
    color: props.shouldHighlight ? COLORS.fluorescentPink : COLORS.black,
    fontSize: 15,
    border: `1px solid ${COLORS.black20}`,
    '& > span': {
      fontSize: 15,
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      height: 41,
    },
    ...(props.selected && {
      background: `${COLORS.quicksand} !important`,
      border: `1px solid ${COLORS.black40}`,
      '& p': {
        fontWeight: 900,
      },
    }),
  }),
}));

const AdjustedShippingDay = ({
  day,
  price,
  priceDiff,
  selected,
  overriden,
}: AdjustedShippingDayProps) => {
  const parsedPrice = parseFloat(price);
  const parsedPriceDiff = parseFloat(priceDiff);
  const shouldHighlight = overriden && parsedPriceDiff < 0;
  const classes = useStyles({ selected, shouldHighlight });

  const extraPriceClasses = [shouldHighlight ? '' : 'text-gray-500'].join(' ');

  return (
    <IconButton classes={classes} className="tracking-calendar-date-button">
      <p className="fgf-month-number m-0">{day}</p>
      <p className={`m-0 text-xs ${extraPriceClasses}`}>${parsedPrice}</p>
    </IconButton>
  );
};
export default AdjustedShippingDay;
