/* eslint-disable no-undef */

/**
 * Scroll to the first visible error with js-error-anchor class on the page.
 */
export function scrollToError() {
  const firstError = document.querySelector(
    ".js-error-anchor:not([style='display:none'])",
  );

  if (firstError) {
    const originalScrollY = window.scrollY;
    firstError.scrollIntoView();

    const pixelsScrolledUp = window.scrollY - originalScrollY;
    if (pixelsScrolledUp <= 70) {
      // Scroll up a bit further if the error is already in view
      window.scrollBy(0, -150);
    }
  }
}

/**
 * Set the value of an input field and trigger a change event as if the user
 * had typed the value themselves. This ensures that the expected event
 * listeners are triggered.
 */
export function setInputValueWithEvent(input, value) {
  // eslint-disable-next-line no-param-reassign
  input.value = value;

  const event = new Event('change', { bubbles: true });
  input.dispatchEvent(event);
}
