/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import sha256 from 'crypto-js/sha256';
import useFetch, { CachePolicies } from 'use-http';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ApiRoutes } from 'constants/api';
import { getDiscount, getSubtotal } from 'utils/getSubtotal';
import { Product, PurchaseFormInputs } from 'pages/pdp/interface';
import {
  PURCHASE_FORM_DEFAULT_VALUES,
  PurchaseFormFieldNames as FIELD_NAMES,
} from 'constants/form';
import { pushMetricsEvent } from './useDataLayer';

const validationSchema = yup.object<PurchaseFormInputs>().shape({
  [FIELD_NAMES.date]: yup
    .string()
    .when('subscription', {
      is: null,
      then: yup.string().required('Please select a delivery date').nullable(),
    })
    .nullable(),
  [FIELD_NAMES.endDate]: yup
    .string()
    .when('subscription', {
      is: 'on',
      then: yup.string().required().nullable(),
    })
    .nullable(),
  [FIELD_NAMES.frequency]: yup.string().when('subscription', {
    is: 'on',
    then: yup.string().oneOf(['weekly', 'biweekly', 'monthly']).required(),
  }),
  [FIELD_NAMES.startDate]: yup
    .string()
    .when('subscription', {
      is: 'on',
      then: yup.string().required().nullable(),
    })
    .nullable(),
  [FIELD_NAMES.subscription]: yup.string().oneOf([null, 'on']).nullable(),
  [FIELD_NAMES.zipCode]: yup
    .string()
    .required('Please enter delivery zip code')
    .nullable(),
});

export const usePurchaseForm = (product: Product, filterDate: string) => {
  const [showConfirmation, toggleShowConfirmation] = useState(false);
  const { control, setValue, handleSubmit, watch } =
    useForm<PurchaseFormInputs>({
      defaultValues: {
        ...PURCHASE_FORM_DEFAULT_VALUES,
        product_id: product.id,
        filter_date: filterDate,
      },
      resolver: !product.gift_card
        ? yupResolver<yup.AnyObjectSchema>(validationSchema)
        : undefined,
      mode: 'onSubmit',
      reValidateMode: 'onChange',
    });

  const { post, loading } = useFetch<{ status: string }>(ApiRoutes.Cart, {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      'X-CSRF-Token': window?.FORM_AUTHENTICITY_TOKEN,
    },
  });

  useEffect(() => {
    if (!window.control) {
      window.control = control;
      window.dispatchEvent(new Event('controlUpdate'));
    }
  }, [control]);

  const onSubmit: SubmitHandler<PurchaseFormInputs> = (
    data: PurchaseFormInputs,
  ) => {
    const mappedAddons = data.add_ons.map((a) => ({
      product_id: a.product_id,
      quantity: a.quantity,
    }));
    const {
      date,
      end_date,
      frequency,
      quantity,
      start_date,
      subscription,
      no_stock,
      add_ons,
      show_cart,
      zip_code,
      ...rest
    } = data;

    const oneTimePurchaseData = {
      date,
      quantity,
      add_ons: mappedAddons,
      ...rest,
    };
    const subscriptionData = {
      end_date,
      frequency,
      start_date,
      subscription,
      ...rest,
    };

    post(subscription ? subscriptionData : oneTimePurchaseData).then((res) => {
      if (res.status === 'ok') {
        const hashedZipCode = zip_code ? sha256(zip_code).toString() : null;

        pushMetricsEvent('ga4', {
          event: 'add_to_cart',
          ecommerce: {
            currency: 'USD',
            value: getSubtotal(add_ons, product.price, product.sales_price),
            items: [
              {
                item_id: product.id.toString(),
                item_name: product.name.trim(),
                discount: getDiscount(product.price, product.sales_price),
                item_category: 'main_product',
                price: Number(product.price),
                quantity: 1,
              },
              ...add_ons.map((addon) => ({
                item_id: addon.product_id.toString(),
                item_name: addon.name.trim(),
                item_category: 'add_on',
                price: Number(addon.price),
                quantity: addon.quantity,
              })),
            ],
          },
        });
        pushMetricsEvent('meta', {
          event_name: 'AddToCart',
          event_time: Math.floor(Date.now() / 1000),
          action_source: 'website',
          custom_data: {
            currency: 'USD',
            value: getSubtotal(
              add_ons,
              product.price,
              product.sales_price,
            ).toString(),
            content_type: 'product',
            content_ids: [
              product.id.toString(),
              ...add_ons.map((addon) => addon.product_id.toString()),
            ],
          },
          user_data: {
            zp: [hashedZipCode],
            client_user_agent: window?.navigator.userAgent,
          },
        });

        if (!show_cart && !subscription) {
          window.location.assign(ApiRoutes.InterstitialAddons);
        } else {
          toggleShowConfirmation(true);
        }
      } else {
        window.location.assign(ApiRoutes.ShopAll);
      }
    });
  };

  return {
    control,
    setValue,
    loading,
    handleSubmit,
    onSubmit,
    watch,
    showConfirmation,
    toggleShowConfirmation,
  };
};
