import { IconButton, styled, Box, Backdrop } from '@material-ui/core';
import { down, isMobile } from 'utils/breakpointHelpers';

export const RedesignedBackdrop = styled(Backdrop)({
  background: 'rgba(0, 0, 0, 0.6)',
  '-webkit-backdrop-filter': 'blur(7px)',
  backdropFilter: 'blur(7px)',
});

export const ModalClose = styled(IconButton)({
  width: 10,
  height: 10,
  position: 'absolute',
  right: '0.75em',
  top: '0.75em',
  color: '#000',
  background: 'none !important',
  boxShadow: 'none',
  '&.Mui-focusVisible': {
    outline: '2px solid black',
  },
});

export const ModalContainerStyled = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: '#FFFFFF',
  boxShadow: '0px 0px 15px rgba(39, 48, 62, 0.2)',
  width: 600,
  borderRadius: '4px',
  [down('sm')]: {
    width: '350px !important',
  },
  '@media (max-width: 900px) and (orientation: landscape)': {
    height: isMobile() ? '90vh' : 'auto',
    overflow: 'auto',
  },
});

export const ModalContentStyled = styled(Box)({
  width: '100%',
});

export const ModalActionsStyled = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '18px',
  padding: '14px',
  width: '100%',
  borderTop: '1px solid #CCC',
  height: 74,
  [down('sm')]: {
    height: 66,
    gap: '14px',
  },
});
