import { Controller } from '@hotwired/stimulus';
import {
  formatDuration,
  differenceInHours,
  intervalToDuration,
} from 'date-fns';

const getTimeLeft = (endDate) => {
  const now = new Date();
  const duration = intervalToDuration({
    start: now,
    end: endDate,
  });
  const hours = differenceInHours(endDate, now, {
    roundingMethod: 'floor',
  });

  return formatDuration(
    { ...duration, hours },
    {
      zero: true,
      format: ['hours', 'minutes', 'seconds'],
    },
  );
};

// Connects to data-controller="countdown"
export default class extends Controller {
  connect() {
    this.element.innerText = this.countdownText();

    setInterval(() => {
      this.element.innerText = this.countdownText();
    }, 1000);
  }

  static get values() {
    return {
      // Time in seconds since epoch
      epochEnd: Number,
    };
  }

  countdownText() {
    return getTimeLeft(
      new Date(
        // convert epoch to milliseconds
        this.epochEndValue * 1000,
      ),
    );
  }
}
