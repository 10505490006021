import { styled, Typography } from '@material-ui/core';
import { down } from 'utils/breakpointHelpers';

export const FiltersContainer = styled('div')({
  display: 'flex',
  gap: 20,
  [down('sm')]: {
    flexDirection: 'column',
    width: '100%',
  },
});

export const HeaderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 20,
  flex: 1,
  [down('sm')]: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

export const PageTitle = styled(Typography)({
  fontSize: 34,
  lineHeight: '40px',
  flexGrow: 1,
  alignSelf: 'flex-end',
  [down('sm')]: {
    alignSelf: 'flex-start',
    fontSize: '20px',
    lineHeight: '24px',
  },
});

export const Separator = styled('div')({
  width: '100%',
  height: 1,
  backgroundColor: '#ECECEC',
  marginBottom: '6px',
});
