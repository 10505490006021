import React from 'react';
import { useController, useWatch } from 'react-hook-form';
import {
  ProductsFormFieldNames as FIELD_NAMES,
  SORTBY_VALUES,
} from 'pages/shop/hooks/constants';

import { MenuItem } from 'ui-components/v2/MenuItem';
import { SortByProps } from './interface';
import { SelectStyled } from './styled';

enum SortByOptions {
  RECOMMENDED = 'Recommended',
  AVAILABILITY = 'Availability',
  PRICE_LOW_TO_HIGH = 'Price low to high',
  PRICE_HIGH_TO_LOW = 'Price high to low',
}

export function SortBy({ control }: SortByProps) {
  const deliveryDateFilter = useWatch({
    control,
    name: FIELD_NAMES.deliveryDate,
  });
  const {
    field: { ref, ...sortByProps },
  } = useController({
    name: FIELD_NAMES.sortBy,
    control,
  });

  return (
    <SelectStyled variant="outlined" label="Sort By" {...sortByProps}>
      <MenuItem value={SORTBY_VALUES.recommended}>
        {SortByOptions.RECOMMENDED}
      </MenuItem>
      {!deliveryDateFilter && (
        <MenuItem value={SORTBY_VALUES.availability}>
          {SortByOptions.AVAILABILITY}
        </MenuItem>
      )}
      <MenuItem value={SORTBY_VALUES.lowToHigh}>
        {SortByOptions.PRICE_LOW_TO_HIGH}
      </MenuItem>
      <MenuItem value={SORTBY_VALUES.highToLow}>
        {SortByOptions.PRICE_HIGH_TO_LOW}
      </MenuItem>
    </SelectStyled>
  );
}
