import { makeStyles, styled } from '@material-ui/core/styles';
import { COLORS } from 'theme/colors';
import { up, down } from 'utils/breakpointHelpers';

export const useAboutUsStyles = makeStyles(() => ({
  root: {
    [up('md')]: {
      padding: '0 3.5em',
    },
    margin: 'auto',
    maxWidth: '1550px',
  },

  heroImageContainer: {},

  image: {
    objectFit: 'cover',
    height: '100%',

    '&.about-us-hero': {
      [down('md')]: {
        height: '50vh',
      },
      width: '100%',
    },
    '&.special-delivery': {
      width: '100%',
      height: '70vh',
      objectFit: 'cover',
    },
  },

  h2: {
    fontSize: '48px',
    lineHeight: '56px',
    [down('md')]: {
      fontSize: '34px',
      lineHeight: '40px',
    },
  },

  paragraph: {
    margin: '1em 0',

    [down('sm')]: {
      fontSize: 16,
      lineHeight: '18px',
    },
  },

  description: {
    padding: '4em 0',
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: 'auto',
    textAlign: 'center',

    [up('md')]: {
      textAlign: 'left',
      width: '80%',
    },
  },

  infoGrid: {
    display: 'flex',
    flexDirection: 'column-reverse',
    height: 'auto',

    '&.founder': {
      flexDirection: 'column',
    },

    [up('md')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: 'fit-content(0)',
    },
  },

  infoGridText: {
    height: 'max-content',
    padding: '4em 2em',
    backgroundColor: '#ffe7e5',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',

    [up('md')]: {
      padding: '8em 4em',
    },

    '&.founder': {
      textAlign: 'right',
      alignItems: 'flex-end',
    },
  },

  infoGridImage: {
    height: '90vh',
    objectFit: 'cover',
    width: '100%',

    [up('md')]: {
      height: 0,
      minHeight: '100%',
    },

    '&.founder': {
      backgroundPositionY: '-150px',
    },
  },

  founder: {
    position: 'relative',
  },

  donations: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '4em 2em',

    [up('md')]: {
      padding: '4em',
    },
  },
}));

export const BoldText = styled('strong')({
  fontWeight: 600,
});

export const StyledLink = styled('a')({
  textDecoration: 'underline',

  '&.default': {
    color: '#000',

    '&:hover': {
      color: COLORS.fluorescentPink,
    },
  },
});
