import { Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

export const WidgetInModal = styled('div')({
  textAlign: 'center',
  padding: '24px 56px',
});

export const LoaderContainer = styled(Box)({
  padding: '30%',
});

export const VideoUploadPortal = styled(Box)({
  margin: '20px 0',
  borderRadius: 10,

  '& video': {
    borderRadius: 20,
  },

  '& .jHCFxt': {
    background: 'transparent',
  },

  '& .video-js': {
    backgroundColor: 'transparent !important',
  },

  '& .vjs-control-bar': {
    borderRadius: '0 0 20px 20px',
  },
});
