import { Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

import { Select } from 'ui-components/v2/Select';
import { down } from 'utils/breakpointHelpers';

export const FilterContainer = styled('div')({
  display: 'flex',
});

export const Title = styled(Typography)({
  alignSelf: 'center',
  marginRight: 10,
  [down('sm')]: {
    display: 'none',
  },
});

export const SelectStyled = styled(Select)({
  width: '312px',
  height: '42px',

  [down('sm')]: {
    width: '270px',
  },

  [down('sm')]: {
    width: '110px',
  },
});
