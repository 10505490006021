import React from 'react';
import { Typography } from '@material-ui/core';
import { useWatch } from 'react-hook-form';

import { FilterByProps } from './interface';
import { CheckboxGroup } from '../CheckboxGroup';
import { FilterGroup } from '../../interface';

export function FilterBy({ control }: FilterByProps) {
  const productType = useWatch({
    control,
    name: FilterGroup.ProductType,
  });

  return (
    <>
      <Typography variant="body1">
        <strong>Product</strong>
      </Typography>
      <CheckboxGroup
        control={control}
        fields={productType}
        category={FilterGroup.ProductType}
      />
    </>
  );
}
