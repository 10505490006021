import { makeStyles, Theme } from '@material-ui/core/styles';
import { ImageProps } from './interface';

export const useImageStyles = makeStyles<
  Theme,
  Pick<ImageProps, 'width' | 'height' | 'isSquare' | 'minHeight'>
>(() => ({
  mediaContainer: (props) => ({
    position: 'relative',
    width: props.width,
    height: props.height ? props.height : 0,
    paddingBottom: props.isSquare ? '100%' : 0,
    minHeight: props.minHeight,
  }),
  mediaImage: (props) => ({
    position: 'absolute',
    top: 0,
    objectFit: 'cover',
    height: props.height || 'auto',
    width: '100%',
  }),
  skeleton: (props) => ({
    position: 'absolute',
    top: 0,
    width: props.width,
    height: props.height ? props.height : 0,
    paddingTop: props.isSquare ? '100%' : 0,
  }),
}));
