import { Box, styled, Typography } from '@material-ui/core';

import { Button } from 'ui-components/v2/Button';
import { down, up } from 'utils/breakpointHelpers';

export const ContainerStyled = styled(Box)({
  padding: '20px',
  paddingBottom: '45px',
  [down('sm')]: {
    paddingBottom: '38px',
  },
});

export const ContentStyled = styled(Box)({
  display: 'flex',
  gap: '17px',
  [down('sm')]: {
    paddingBottom: '15px',
  },
});

export const InfoContainerStyled = styled('div')({
  [up('sm')]: {
    height: 270,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      width: 3,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 3,
      backgroundColor: '#CCCCCC',
    },
  },
});

export const ActionButtonStyled = styled(Button)({
  width: 'auto',
  minWidth: '82px',
  [down('sm')]: {
    width: 'auto',
    minWidth: '78px',
    paddingInline: '20px',
  },
});

export const TitleStyled = styled(Typography)({
  margin: '3px 6px 9px',
  fontSize: '34px',
  lineHeight: '40px',
  [down('md')]: {
    fontSize: '24px',
    lineHeight: '30px',
    marginBottom: '20px',
  },
  [down('sm')]: {
    fontSize: '20px',
    lineHeight: '24px',
    marginBottom: '9px',
  },
});

export const MediaStyled = styled('div')({
  minWidth: '191px',
  height: '191px',
  [down('sm')]: {
    minWidth: '141px',
    height: '141px',
  },
});

export const NameStyled = styled(Typography)({
  fontSize: '24px',
  lineHeight: '28px',
  [down('sm')]: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,
  },
});

export const PriceStyled = styled(Typography)({});

export const DescriptionStyled = styled(Typography)({
  [down('sm')]: {
    display: 'none',
  },
});

export const BodyStyled = styled(Typography)({
  height: 120,
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    width: 3,
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: 3,
    backgroundColor: '#CCCCCC',
  },
  [up('sm')]: {
    display: 'none',
  },
});
