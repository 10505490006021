import { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useFetch } from 'use-http';
import { useController, useFormState, useWatch } from 'react-hook-form';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { ApiRoutes } from 'constants/api';
import { AVAILABLE_DAY_DATE_FORMAT, DATE_FORMAT } from 'constants/time';
import { dateFormatter, isDateDeliveryChecker } from 'utils/dateHelpers';
import { PurchaseFormFieldNames as FIELD_NAMES } from 'constants/form';
import { getDefaultDatePickerDate } from './helpers/getNextDeliveryDate';
import {
  GetAvailableDatesResponse,
  MaxDateOffset,
  UseDeliveryDatesProps,
} from './interface';

const DEFAULT_MAX_DATE = dayjs().add(6, 'month');

function getMaxDate(maxDateOffset: MaxDateOffset | undefined) {
  if (maxDateOffset) {
    return dayjs().add(maxDateOffset.amount, maxDateOffset.unit);
  }

  return DEFAULT_MAX_DATE;
}

export const useDeliveryDates = ({
  control,
  fieldName,
  productId,
  purchasable,
}: UseDeliveryDatesProps) => {
  const [initialDate, setInitialDateState] = useState<Dayjs | null>(null);
  const [modalOpen, toggleModal] = useState<boolean>(false);
  const {
    get: getAvailability,
    abort: availabilityAbort,
    loading: availabilityLoading,
    response: availabilityResponse,
    data: availableDatesData = { available_dates: [] },
  } = useFetch<GetAvailableDatesResponse>();

  const { available_dates: availableDates } = availableDatesData;

  const { errors } = useFormState({ control });
  const formDate = useWatch({ control, name: fieldName });
  const zipCode = useWatch({ control, name: FIELD_NAMES.zipCode });
  const deliveryDate = useWatch({ control, name: FIELD_NAMES.date });
  const filterDate = useWatch({ control, name: FIELD_NAMES.filterDate });
  const isDateDelivery = isDateDeliveryChecker(dayjs(dateFormatter(formDate)));

  const {
    field: { name, onChange },
  } = useController({ name: fieldName, control });
  const {
    field: { value: noStock, onChange: setNoStock },
  } = useController({ name: FIELD_NAMES.noStock, control });

  const setFormDateCb = useCallback(
    (date: Dayjs | null) => {
      onChange(date?.format(DATE_FORMAT));
      setInitialDateState(date);
    },
    [onChange],
  );

  const handleDateChange = useCallback(
    (date: MaterialUiPickersDate) => {
      if (!date) return;
      setInitialDateState(date);
    },
    [setInitialDateState],
  );

  const handleMonthChange = useCallback(
    (date: MaterialUiPickersDate) => {
      if (!date || !zipCode || !purchasable) return undefined;
      availabilityAbort();

      return new Promise<void>((resolve) => {
        getAvailability(
          `/products/${productId}${
            ApiRoutes.AvailabilityWithPrices
          }?quantity=1&zip_code=${zipCode}&month=${date.month() + 1}`,
        ).then(() => {
          resolve();
        });
      });
    },
    [zipCode, purchasable, availabilityAbort, getAvailability, productId],
  );

  /**
   * We only toggle a modal on Date accept (i.e. when user selects a date),
   * not when date changes programmatically
   * */
  const handleDateAccept = useCallback(
    (date: MaterialUiPickersDate) => {
      if (date) {
        if (isDateDeliveryChecker(date)) {
          setFormDateCb(date);
        } else {
          toggleModal(true);
        }
      }
    },
    [toggleModal],
  );

  const shouldDisableDate = useCallback(
    (date_: MaterialUiPickersDate) => {
      const SUNDAY = 0;
      if (!availabilityResponse.ok || !date_) return false;
      if (date_.day() === SUNDAY) return true;

      return !availableDates
        .map((obj) => obj.date)
        .includes(date_?.format(AVAILABLE_DAY_DATE_FORMAT));
    },
    [availableDates],
  );

  const initiallyHighlightedDate = useMemo(() => {
    if (!availabilityResponse.ok) return null;

    const highlightDate = getDefaultDatePickerDate(
      formDate || filterDate,
      availableDates,
    );

    if (noStock !== !highlightDate) setNoStock(!highlightDate);

    return highlightDate || null;
  }, [availableDates]);

  const getHelperText = useCallback(() => {
    if (!zipCode) return 'Please select a valid zip code first';
    if (availabilityResponse.status >= 400)
      return 'There was an error, please try again later';

    return errors[fieldName]?.message;
  }, [zipCode, noStock, errors[fieldName]]);

  useEffect(() => {
    if (zipCode && purchasable) {
      availabilityAbort();
      getAvailability(
        `/products/${productId}${ApiRoutes.AvailabilityWithPrices}?quantity=1&zip_code=${zipCode}`,
      );
    }
  }, [zipCode]);

  useEffect(() => {
    if (availableDates?.length && initiallyHighlightedDate) {
      setFormDateCb(initiallyHighlightedDate);
    }
  }, [JSON.stringify(availableDates)]);

  useEffect(() => {
    if (fieldName === FIELD_NAMES.startDate) {
      setFormDateCb(dayjs(dateFormatter(deliveryDate)));
    }
  }, [deliveryDate]);

  return {
    name,
    modalOpen,
    getMaxDate,
    toggleModal,
    initialDate,
    setFormDateCb,
    getHelperText,
    availableDates,
    handleDateChange,
    handleDateAccept,
    handleMonthChange,
    shouldDisableDate,
    initiallyHighlightedDate,
    error: fieldName in errors,
    disabled:
      noStock ||
      !purchasable ||
      availabilityLoading ||
      !availabilityResponse.ok,
    label: isDateDelivery ? 'Delivery Date' : 'Delivery Window',
  };
};
