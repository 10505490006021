import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Skeleton } from '@material-ui/lab';
import { IS_SERVER } from 'constants/server';
import { useRailsContext } from 'hocs/ReactKeyProvider/RailsContext';
import { useImageStyles } from './styled';
import { ImageProps, CloudinarySize } from './interface';

function getImageUrl(
  srcUrl: string,
  cloudinary_url: string,
  useWebP: boolean,
  size: CloudinarySize | null,
  filename?: string,
): string {
  const extension = useWebP ? '.webp' : '';
  const isGif = filename && filename.toLowerCase().endsWith('.gif');

  if (IS_SERVER && srcUrl)
    return `${cloudinary_url}f_auto,q_auto,dpr_2.0${
      !isGif ? ',h_450,w_450' : ''
    }/v1/${srcUrl}${extension}`;

  if (isGif)
    return `${cloudinary_url}f_auto,q_auto,dpr_2.0/v1/${srcUrl}${extension}`;

  if (!srcUrl || !size) return '';

  const { w, h } = size;

  return `${cloudinary_url}f_auto,q_auto,dpr_2.0,h_${h},w_${w}/v1/${srcUrl}${extension}`;
}

/**
 * Resilient, SSR-proof, Image loader component with placeholder support.
 * Serves as an abstraction for Cloudinary images from backend, supports WebP
 * and loads Images based on viewport.
 * */
const Image = ({
  src,
  isSquare,
  useWebP = false,
  alt,
  width,
  height,
  filename,
  minHeight,
}: ImageProps) => {
  const railsContext = useRailsContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const classes = useImageStyles({
    isSquare,
    width,
    height,
    minHeight,
  });
  const [loaded, setLoaded] = useState(false);
  const [size, setSize] = useState<CloudinarySize | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      setSize({
        w: containerRef.current.clientWidth,
        h: isSquare
          ? containerRef.current.clientWidth
          : containerRef.current.clientHeight,
      });
    }
  }, [containerRef]);

  const imageUrlWebP = useMemo(
    () =>
      getImageUrl(src, railsContext.cloudinary_url, useWebP, size, filename),
    [src, size],
  );

  const imageUrl = useMemo(
    () => getImageUrl(src, railsContext.cloudinary_url, false, size, filename),
    [src, size],
  );

  return (
    <div className={classes.mediaContainer} ref={containerRef}>
      {(size || IS_SERVER) && (
        <picture>
          <source srcSet={imageUrlWebP} type="image/webp" />
          <source srcSet={imageUrl} type="image/jpeg" />
          <img
            className={classes.mediaImage}
            src={imageUrl}
            alt={alt}
            loading="lazy"
            ref={(img) => {
              // SSR workaround
              if (!img) return;

              const update = () => setLoaded(true);
              // eslint-disable-next-line no-param-reassign
              img.onload = update;
              if (img.complete) {
                update();
              }
            }}
          />
        </picture>
      )}
      {(!loaded || !imageUrl) && !IS_SERVER && (
        <Skeleton className={classes.skeleton} variant="rect" />
      )}
    </div>
  );
};

export default Image;
