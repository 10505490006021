import React from 'react';
import ZipCodeField from 'components/ZipCodeField';
import { WhereToDeliverProps } from './interface';

import { WhereToDeliverContainer } from './styled';

export const WhereToDeliver = ({ control }: WhereToDeliverProps) => (
  <WhereToDeliverContainer>
    <ZipCodeField control={control} label="Zip Code" />
  </WhereToDeliverContainer>
);
