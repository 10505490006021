import { Pages } from 'constants/pages';

const REACT_OUT_EVENTS_PREFIX = 'REACT';

export const GLOBAL_EVENTS = {
  [Pages.PRODUCT]: {
    dispatchUnavailableDates: 'DISPATCH_UNAVAILABLE_DATES',
    showDeliverDateOptionsModal: 'SHOW_DELIVER_DATE_OPTIONS_MODAL',
    changeDeliverDate: `${REACT_OUT_EVENTS_PREFIX}_CHANGE_DELIVER_DATE`,
    updateCheckoutItem: 'UPDATE_CHECKOUT_ITEM',
    setAddons: `${REACT_OUT_EVENTS_PREFIX}_SET_CHECKOUT_ADDONS`,
    resetAddons: 'RESET_ADDONS',
  },
  [Pages.SHOP]: {
    dispatchUnavailableDates: 'DISPATCH_UNAVAILABLE_DATES',
    showDeliverDateOptionsModal: 'SHOW_DELIVER_DATE_OPTIONS_MODAL',
  },
};
