import React from 'react';
import { useWatch } from 'react-hook-form';

import ProductPrice from 'components/ProductPrice';
import { Button } from 'ui-components/v2/Button';
import { getSubtotal } from 'utils/getSubtotal';
import { HookFormProps, PDPProps } from 'pages/pdp/interface';
import { PurchaseFormFieldNames as FIELD_NAMES } from 'constants/form';

export const ATC = ({
  id,
  control,
  product,
  loading,
  onSubmit,
  handleSubmit,
}: Pick<PDPProps, 'product'> &
  Omit<HookFormProps, 'setValue'> & { id: string }) => {
  const addons = useWatch({ control, name: FIELD_NAMES.addons });
  const noStock = useWatch({ control, name: FIELD_NAMES.noStock });
  const subtotal = getSubtotal(addons, product.price, product.price).toFixed(2);

  return (
    <Button
      id={id}
      fullWidth
      color="primary"
      variant="contained"
      className="tracking-add-to-cart"
      disabled={loading || !product.purchasable || noStock}
      onClick={handleSubmit(onSubmit)}
    >
      {noStock || !product.purchasable ? (
        'Sold Out!'
      ) : (
        <>
          <span className="mr-1">Add To Cart -</span>
          <ProductPrice
            price={subtotal}
            salesPrice={
              product.sales_price
                ? getSubtotal(
                    addons,
                    product.price,
                    product.sales_price,
                  ).toFixed(2)
                : null
            }
          />
        </>
      )}
    </Button>
  );
};
