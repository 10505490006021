import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useWatch } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';

import { getGroupedProducts } from 'utils/shop';
import { ProductsFormFieldNames as FIELD_NAMES } from 'pages/shop/hooks/constants';

import { ResultsContainer } from './styled';
import { ResultsProps } from './interface';
import { EmptyState } from '../EmptyState';
import ProductCard from './ProductCard';
import ProductSkeleton from './ProductCard/productsSkeleton';

const SKELETON_COUNT = 12;

const Results = ({
  products,
  loading,
  hasMore,
  loadMore,
  resWithSoonestDate,
  reset,
  hasLoadedOnce,
  hasAborted,
  control,
  groupingEnabled,
}: ResultsProps) => {
  const showLoading = !products.length && loading;
  const deliveryDate = useWatch({ control, name: FIELD_NAMES.deliveryDate });

  const [nextCardsLoading, setNextCardsLoading] = useState(false);

  useEffect(() => {
    setNextCardsLoading(loading && !!products.length);
  }, [loading, products.length]);

  const displayedProducts = useMemo(
    () => (groupingEnabled ? getGroupedProducts(products) : products),
    [products, groupingEnabled],
  );

  const skeletons = useMemo(
    () =>
      Array.from({ length: SKELETON_COUNT }).map((_, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <ProductSkeleton resWithSoonestDate={resWithSoonestDate} />
        </Grid>
      )),
    [resWithSoonestDate],
  );

  const renderProducts = useMemo(
    () =>
      displayedProducts.map((product) => (
        <Grid item xs={12} sm={6} md={4} key={product.id}>
          <ProductCard
            product={product}
            deliveryDate={deliveryDate}
            discountPrice={product.sales_price}
            groupingEnabled={groupingEnabled}
          />
        </Grid>
      )),
    [displayedProducts, deliveryDate, groupingEnabled],
  );

  const handleLoadMore = useCallback(() => loadMore(), [loadMore]);

  return (
    <ResultsContainer>
      {(showLoading || hasAborted) && (
        <Grid container spacing={3}>
          {skeletons}
        </Grid>
      )}

      {!products.length && !showLoading && hasLoadedOnce && !hasAborted && (
        <EmptyState reset={reset} deliveryDate={deliveryDate} />
      )}

      {!!products.length && !showLoading && (
        <InfiniteScroll
          next={handleLoadMore}
          hasMore={!!hasMore}
          scrollThreshold={0.6}
          dataLength={displayedProducts.length}
          loader={null}
          style={{ overflow: 'visible' }}
        >
          <Grid container spacing={3}>
            {renderProducts}
            {nextCardsLoading && skeletons}
          </Grid>
        </InfiniteScroll>
      )}
    </ResultsContainer>
  );
};

export default Results;
