/* eslint-disable no-undef */
/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable object-shorthand */
/* eslint-disable no-else-return */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-template */
/* eslint-disable no-inner-declarations */
/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';
import debounce from 'lodash.debounce';

import { scrollToError, setInputValueWithEvent } from '../lib/dom_utils';

function fetchContentFromMetaTag(name) {
  const metaTag = document.querySelector('meta[name="' + name + '"]');
  if (metaTag) {
    return metaTag.content;
  } else {
    console.error('[FGF] Could not find meta tag with name "' + name + '"');
    return null;
  }
}

const FormState = {
  READY: 'ready',
  /**
   * The VALIDATING state is used when we are validating via the backend or e.g.
   * saving address info.
   */
  VALIDATING: 'validating',
  /**
   * The SUBMITTING_PAYMENT state is used when we are submitting the form for
   * payment.
   */
  SUBMITTING_PAYMENT: 'submittingPayment',
};

const SubmitPurpose = {
  VALIDATE_AND_SAVE: 'validate_and_save',
  PAYMENT: 'payment',
};

export default class extends Controller {
  initialize() {
    this.formState = FormState.READY;
  }

  connect() {
    this.mainForm = this.element;
    this.applePayButton = this.mainForm.querySelector('#js-apple-pay-button');

    if (this.isGoogleAvailable) {
      this.googlePaymentsClient = new google.payments.api.PaymentsClient({
        environment:
          this.braintreeEnvironment === 'sandbox' ? 'TEST' : 'PRODUCTION',
      });
    }

    this.braintreeEnvironment = fetchContentFromMetaTag(
      'braintree-environment',
    );
    this.googleMerchantId = fetchContentFromMetaTag('google-merchant-id');
    this.braintreeToken = fetchContentFromMetaTag('braintree-token');
    if (this.braintreeEnvironment !== 'production') {
      console.warn(
        `[FGF] Braintree environment is not set to production. Value is: ${this.braintreeEnvironment}`,
      );
    }
    this.setFormState(FormState.READY);

    this.initializeBraintree();

    // If the customer has credit that fully covers the order, we don't
    // initialize Braintree at all hence we don't submit on that flow, instead
    // we submit the form directly here upon clicking the pay button.
    if (this.checkoutWithoutChargeTarget.value === 'true') {
      this.btnPayTarget.addEventListener('click', async (event) => {
        event.preventDefault();

        await this.handlePaymentFlow(async () => {
          this.submitForm(SubmitPurpose.PAYMENT);
          return true;
        });
        return;
      });
    }
  }

  static get targets() {
    return [
      'btnPay',
      'btnPaySpinner',
      'btnPayWithPaypal',
      'creditCardForm',
      'paymentMethodSelect',
      'checkoutWithoutCharge',
      'autoSubmit',
      'disabledDuringSubmit',
      'confirmationPopup',
      'confirmSubmitBtn',
      'cancelSubmitBtn',
    ];
  }

  static get outlets() {
    return ['loader'];
  }

  static get values() {
    return {
      submitConfirmationPopupEnabled: Boolean,
      submitOnKeyupEnabled: Boolean,
    };
  }

  async handlePaymentFlow(paymentHandler) {
    if (this.submitConfirmationPopupEnabledValue) {
      // Show popup and wait for user confirmation
      this.confirmationPopupTarget.classList.remove('hidden');

      // Create a Promise that resolves when user confirms or rejects
      const userDecision = await new Promise((resolve) => {
        const handleConfirm = () => {
          this.cleanupPopupListeners();
          this.confirmationPopupTarget.classList.add('hidden');
          resolve(true);
        };

        const handleCancel = () => {
          this.cleanupPopupListeners();
          this.confirmationPopupTarget.classList.add('hidden');
          this.setFormState(FormState.READY);
          resolve(false);
        };

        // Store handlers so we can remove them later
        this._confirmHandler = handleConfirm;
        this._cancelHandler = handleCancel;

        this.confirmSubmitBtnTarget.addEventListener('click', handleConfirm);
        this.cancelSubmitBtnTarget.addEventListener('click', handleCancel);
      });

      if (!userDecision) {
        return false; // User cancelled
      }
    }

    // Now proceed with the actual payment handling
    return await paymentHandler();
  }

  cleanupPopupListeners() {
    if (this._confirmHandler) {
      this.confirmSubmitBtnTarget.removeEventListener(
        'click',
        this._confirmHandler,
      );
      this._confirmHandler = null;
    }
    if (this._cancelHandler) {
      this.cancelSubmitBtnTarget.removeEventListener(
        'click',
        this._cancelHandler,
      );
      this._cancelHandler = null;
    }
  }

  handlePayNowClick(event) {
    event.preventDefault();
    if (!this.btnPayTarget.disabled) {
      this.confirmationPopupTarget.classList.remove('hidden');
    }
  }

  handleConfirmSubmit() {
    event.preventDefault();
    this.confirmationPopupTarget.classList.add('hidden');
    this.submitForm(SubmitPurpose.PAYMENT);
  }

  handleCancelSubmit() {
    event.preventDefault();
    this.setFormState(FormState.READY);
    this.confirmationPopupTarget.classList.add('hidden');
  }

  autoSubmitTargetConnected(target) {
    const debouncedValidateAndSave = debounce(() => {
      this.submitForm(SubmitPurpose.VALIDATE_AND_SAVE);
    }, 700);

    if (this.submitOnKeyupEnabledValue) {
      target._previousValue = target.value;

      const handleValueChange = () => {
        const currentValue = target.value;

        if (target.type === 'checkbox') {
          this.setFormStateByPurpose(SubmitPurpose.VALIDATE_AND_SAVE);
          debouncedValidateAndSave();
        } else if (target._previousValue !== currentValue) {
          target._previousValue = currentValue;
          this.setFormStateByPurpose(SubmitPurpose.VALIDATE_AND_SAVE);
          debouncedValidateAndSave();
        }
      };

      // NOTE(kspe): This is an exception to prevent issues with resetting the
      // cursor position within the text area (card message). When Hotwire
      // replaces the textarea it seems to replace whole element hence the
      // cursor position is reset. With input fields this is not an issue as
      // only the value is replaced.
      if (!target.classList.contains('js-disallow-submit-on-keyup')) {
        target.addEventListener('keyup', handleValueChange);
      }
      target.addEventListener('change', handleValueChange);
    } else {
      const onChange = (event) => {
        event.preventDefault();
        // Disable the form while we're validating and saving, to prevent multiple
        // submissions (e.g. clicking "Pay Now" should not be possible, even if
        // during the debounce period)
        this.setFormStateByPurpose(SubmitPurpose.VALIDATE_AND_SAVE);
        debouncedValidateAndSave();
      };

      target.addEventListener('change', onChange);
    }
  }

  setFormState(state) {
    const inactiveFormClasses = [
      'opacity-50',
      'pointer-events-none',
      'cursor-wait',
    ];
    switch (state) {
      case FormState.READY:
        this.setPayButtonDisabled(false);
        this.showAddressValidationLoader(false);
        this.mainForm.removeAttribute('disabled');
        this.mainForm.removeAttribute('inert');
        inactiveFormClasses.forEach((className) => {
          this.mainForm.classList.remove(className);
        });
        break;
      case FormState.VALIDATING:
        // Ensure users don't click the address override checkbox while we're
        // validating the address
        this.showAddressValidationLoader(true);
        // When we are saving/validating address info, we don't want to allow
        // the btnPay to be clicked
        this.setPayButtonDisabled(true);
        break;
      case FormState.SUBMITTING_PAYMENT:
        this.setPayButtonDisabled(true);
        this.mainForm.setAttribute('disabled', 'disabled');
        // When the form is submitting for payment, we want to prevent any
        // further changes until the submission is complete
        this.mainForm.setAttribute('inert', true);
        inactiveFormClasses.forEach((className) => {
          this.mainForm.classList.add(className);
        });
        break;
      default:
        console.warn('[FGF] Unexpected form state: ' + state);
    }
  }

  showAddressValidationLoader(isLoading) {
    if (isLoading) {
      this.loaderOutlets.forEach((loader) => {
        loader.show();
      });
    } else {
      this.loaderOutlets.forEach((loader) => {
        loader.hide();
      });
    }
  }

  setPayButtonDisabled(disabled) {
    const buttons = [this.btnPayTarget, this.btnPayWithPaypalTarget];
    const disabledClasses = [
      'opacity-50',
      'pointer-events-none',
      'cursor-wait',
    ];
    if (disabled) {
      this.btnPayTarget.classList.add('btn-disabled');
      this.btnPaySpinnerTarget.classList.remove('hidden');
      buttons.forEach((button) => {
        button.setAttribute('disabled', 'disabled');
        disabledClasses.forEach((className) => {
          button.classList.add(className);
        });
      });
    } else {
      this.btnPayTarget.classList.remove('btn-disabled');
      this.btnPaySpinnerTarget.classList.add('hidden');
      buttons.forEach((button) => {
        button.removeAttribute('disabled');
        disabledClasses.forEach((className) => {
          button.classList.remove(className);
        });
      });
    }
  }

  paymentMethodSelectTargetConnected() {
    const selectedOption =
      this.paymentMethodSelectTarget.querySelector('option:checked');
    this.afterPaymentMethodSelected(selectedOption);

    this.paymentMethodSelectTarget.addEventListener('change', () => {
      const selectedOption =
        this.paymentMethodSelectTarget.querySelector('option:checked');
      this.afterPaymentMethodSelected(selectedOption);
    });
  }

  afterPaymentMethodSelected(selectedOption) {
    const savePaymentMethodCb = document.querySelector(
      '.js-save-payment-method-checkbox',
    );

    if (selectedOption.value === 'new') {
      this.creditCardFormTarget.style.display = 'block';
      savePaymentMethodCb.style.display = 'flex';
      this.populateBillingAddressFormUsingJSON(null); // clears the form fields
    } else {
      this.creditCardFormTarget.style.display = 'none';
      savePaymentMethodCb.style.display = 'none';
      // NOTE(kspe): We clear the payment nonce and type here to ensure that we
      // do not send both the nonce and saved payment method ID which will
      // result in making a BT transaction request and passing both the nonce
      // and saved payment method token.
      this.clearPaymentNonceAndType();
      this.populateBillingAddressFormUsingJSON(
        selectedOption.dataset.addressFormFieldsJson,
      );
    }
  }

  clearPaymentNonceAndType() {
    document.getElementById('payment_nonce').value = '';
    document.getElementById('payment_method_type').value = '';
  }

  populateBillingAddressFormUsingJSON(addressFieldsJson) {
    const addressFields = JSON.parse(addressFieldsJson || '{}');
    const formFields = [
      'first_name',
      'last_name',
      'address_1',
      'address_2',
      'city',
      'state',
      'zip_code',
      'phone',
    ];

    formFields.forEach((formField) => {
      const value = addressFields[formField] || '';
      setInputValueWithEvent(
        document.querySelector('#cart_payment_' + formField),
        value,
      );
    });
  }

  setPaymentNonceAndType(nonce, type) {
    document.getElementById('payment_nonce').value = nonce;
    document.getElementById('payment_method_type').value = type;
  }

  setBillingAddressValues(address = {}) {
    const addressFields = [
      'first_name',
      'last_name',
      'address_1',
      'address_2',
      'city',
      'state',
      'zip_code',
      'phone',
    ];

    address.keys().forEach((key) => {
      if (!addressFields.includes(key)) {
        console.warn(`[FGF] Unexpected address field name: ${key}`);
        return;
      }

      const value = address[key] || '';
      const input = document.querySelector('#cart_payment_' + key);
      setInputValueWithEvent(input, value);
    });
  }

  initializeBraintree() {
    // We expect that braintree might be not available in some cases, e.g. when
    // a customer has enough account credit and no payment is required.
    if (typeof braintree === 'undefined') {
      return;
    }

    braintree.client.create(
      {
        authorization: this.braintreeToken,
      },
      (err, clientInstance) => {
        if (err) {
          console.error(err);
          return;
        }

        braintree.dataCollector.create(
          {
            client: clientInstance,
            paypal: true,
          },
          (err, dataCollectorInstance) => {
            if (err) {
              return;
            }
            document.getElementById('device_data').value =
              dataCollectorInstance.deviceData;
          },
        );

        this.createHostedFields(clientInstance);

        braintree.paypalCheckout.create(
          {
            client: clientInstance,
          },
          this.onPaypalCheckoutCreated.bind(this),
        );

        if (this.isApplePayAvailable) {
          this.initializeApplePay(clientInstance);
          this.applePayButton.style.display = 'inline-block';
        } else {
          console.warn('No Apple Pay support detected.');
        }

        // Google Pay
        braintree.client
          .create({
            authorization: this.braintreeToken,
          })
          .then((clientInstance) =>
            braintree.googlePayment.create({
              client: clientInstance,
              googlePayVersion: 2,
              googleMerchantId: this.googleMerchantId,
            }),
          )
          .then((googlePaymentInstance) =>
            this.googlePaymentsClient
              .isReadyToPay({
                // see https://developers.google.com/pay/api/web/reference/object#IsReadyToPayRequest
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods:
                  googlePaymentInstance.createPaymentDataRequest()
                    .allowedPaymentMethods,
                existingPaymentMethodRequired: true, // Optional
              })
              .then((response) =>
                this.setupGooglePayButton(response, googlePaymentInstance),
              ),
          )
          .catch((err) => {
            console.error(err);
          });
      },
    );
  }

  setupGooglePayButton(response, googlePaymentInstance) {
    if (response.result) {
      const container = document.getElementById('js-google-pay-button');
      const button = this.googlePaymentsClient.createButton({
        buttonColor: 'default',
        buttonType: 'buy',
        buttonSizeMode: 'fill',
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
              allowedCardNetworks: [
                'AMEX',
                'DISCOVER',
                'INTERAC',
                'JCB',
                'MASTERCARD',
                'VISA',
              ],
            },
          },
        ], // use the same payment methods as for the loadPaymentData() API call
      });

      container.appendChild(button);

      button.addEventListener('click', (event) =>
        this.handleGooglePayment(event, googlePaymentInstance),
      );
    }
  }

  handleGooglePayment(event, googlePaymentInstance) {
    event.preventDefault();

    if (!this.isDeliveryAddressFilledOut()) {
      alert('Please fill out the delivery address before using Google Pay.');
      return;
    }
    const paymentDataRequest = googlePaymentInstance.createPaymentDataRequest({
      transactionInfo: {
        currencyCode: 'USD',
        totalPriceStatus: 'FINAL',
        totalPrice: this.amountToPay,
      },
    });

    // We recommend collecting billing address information, at minimum
    // billing postal code, and passing that billing postal code with all
    // Google Pay card transactions as a best practice.
    // See all available options at https://developers.google.com/pay/api/web/reference/object
    const cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];
    cardPaymentMethod.parameters.billingAddressRequired = true;
    cardPaymentMethod.parameters.billingAddressParameters = {
      format: 'MIN',
      phoneNumberRequired: true,
    };

    this.googlePaymentsClient
      .loadPaymentData(paymentDataRequest)
      .then((paymentData) => googlePaymentInstance.parseResponse(paymentData))
      .then((result) => {
        // Send result.nonce to your server
        // result.type may be either "AndroidPayCard" or "PayPalAccount", and
        // paymentData will contain the billingAddress for card payments
        this.setPaymentNonceAndType(result.nonce, 'google_pay');
        this.submitForm(SubmitPurpose.PAYMENT);
      })
      .catch((err) => {
        console.error(err);
        // Handle errors
      });
  }

  onPaypalCheckoutCreated(paypalCheckoutErr, paypalCheckoutInstance) {
    if (paypalCheckoutErr) {
      console.error('Error creating PayPal Checkout:', paypalCheckoutErr);
      return;
    }

    paypalCheckoutInstance.loadPayPalSDK(
      {
        components: 'buttons,messages',
        'enable-funding': 'paylater',
        vault: true,
      },
      this.onLoadPayPalSDK.bind(this),
    );
  }

  setFormStateByPurpose(purpose) {
    if (purpose === SubmitPurpose.VALIDATE_AND_SAVE) {
      this.setFormState(FormState.VALIDATING);
    } else if (purpose === SubmitPurpose.PAYMENT) {
      this.setFormState(FormState.SUBMITTING_PAYMENT);
    }
  }

  submitForm(purpose) {
    this.setFormStateByPurpose(purpose);

    const form = this.mainForm;
    // Add validation_context param if relevant
    let contextInput = null;
    if (purpose === SubmitPurpose.PAYMENT) {
      contextInput = document.createElement('input');
      contextInput.type = 'hidden';
      contextInput.name = 'cart[validation_context]';
      contextInput.value = 'payment';
      form.appendChild(contextInput);
    }

    this.resetFormStateOnceAfterSubmit();
    form.requestSubmit();

    if (contextInput) {
      contextInput.remove();
    }
  }

  resetFormStateOnceAfterSubmit() {
    document.addEventListener(
      'turbo:submit-end',
      () => {
        // Once form is submitted and the request completed, re-enable the pay button
        this.setFormState(FormState.READY);
      },
      { once: true },
    );
  }

  /**
   * @see https://braintree.github.io/braintree-web/current/module-braintree-web_hosted-fields.html#.create
   */
  createHostedFields(clientInstance) {
    braintree.hostedFields.create(
      {
        client: clientInstance,
        styles: {
          input: {
            'font-size': '15px',
            'font-family': 'courier, monospace',
            'font-weight': 'lighter',
            color: '#930303',
          },
          ':focus': {
            color: '#303030',
          },
          '.valid': {
            color: '#303030',
          },
          '::placeholder': {
            color: '#ccc',
          },
        },
        fields: {
          number: {
            selector: '#card-number',
            placeholder: '4111 1111 1111 1111',
          },
          cvv: {
            selector: '#cvv',
            placeholder: '123',
          },
          expirationDate: {
            selector: '#expiration-date',
            placeholder: 'MM/YYYY',
          },
        },
      },
      async (err, hostedFieldsInstance) => {
        const teardown = async (event) => {
          event.preventDefault();
          document.querySelector('ul.errors').innerHTML = '';

          // Handle saved payment method case
          if (!this.isBraintreeCreditCardFormVisible()) {
            await this.handlePaymentFlow(async () => {
              this.submitForm(SubmitPurpose.PAYMENT);
              return true;
            });
            return;
          }

          // Handle credit card case
          await this.handlePaymentFlow(async () => {
            this.setFormState(FormState.VALIDATING);

            try {
              const payload = await new Promise((resolve, reject) => {
                hostedFieldsInstance.tokenize((err, payload) => {
                  if (err) reject(err);
                  else resolve(payload);
                });
              });

              this.setPaymentNonceAndType(payload.nonce, 'card');
              this.submitForm(SubmitPurpose.PAYMENT);
              return true;
            } catch (err) {
              this.setFormState(FormState.READY);
              console.warn('[FGF] Error tokenizing card: ', err);
              const errorsElem = document.querySelector('ul.errors');
              errorsElem.innerHTML = '';
              const node = document.createElement('li');
              node.classList.add('js-error-anchor');
              node.innerText = err.message;
              errorsElem.appendChild(node);
              scrollToError();
              return false;
            }
          });
        };

        this.btnPayTarget.addEventListener('click', teardown, false);
      },
    );
  }

  isBraintreeCreditCardFormVisible() {
    return (
      this.creditCardFormTarget &&
      this.creditCardFormTarget.style.display !== 'none'
    );
  }

  /**
   * Callback for loadPayPalSDK.
   *
   * @see https://braintree.github.io/braintree-web/current/PayPalCheckout.html#loadPayPalSDK
   */
  onLoadPayPalSDK(err, paypalCheckoutInstance) {
    // For documentation on how to style the buttons, see:
    // https://developer.paypal.com/sdk/js/reference/#link-buttons
    paypal
      .Buttons({
        env: this.braintreeEnvironment,
        fundingSource: paypal.FUNDING.PAYPAL,
        style: {
          layout: 'horizontal',
          color: 'blue',
          shape: 'rect',
          label: 'checkout',
          height: 48,
          tagline: true,
        },

        createBillingAgreement: () =>
          this.paypalButtonCreateBillingAgreement(paypalCheckoutInstance),

        onApprove: (data, actions) =>
          paypalCheckoutInstance.tokenizePayment(
            data,
            this.onPaypalPaymentTokenized.bind(this),
          ),

        onCancel: (data) => {
          console.log(
            'checkout.js payment cancelled',
            JSON.stringify(data, 0, 2),
          );
        },

        onError: this.onPaypalError.bind(this),
      })
      .render('#js-paypal-button');

    const payLaterButton = paypal.Buttons({
      env: braintreeEnvironment,
      fundingSource: paypal.FUNDING.PAYLATER,
      style: {
        tagline: false,
        color: 'gold',
        label: 'paypal',
        height: 48,
      },

      createBillingAgreement: () =>
        this.paypalButtonCreateBillingAgreement(paypalCheckoutInstance),

      onApprove: (data, actions) =>
        paypalCheckoutInstance.tokenizePayment(
          data,
          this.onPaypalPaymentTokenized.bind(this),
        ),
    });

    if (!payLaterButton.isEligible()) {
      const paypalMessage = document.querySelector('#paypal-message');
      if (paypalMessage) {
        paypalMessage.style.display = 'none';
      }
      console.log('PayPal credit not eligible');
      // Skip rendering if not eligible
      return;
    }

    payLaterButton.render('#paypal-credit-button');
  }

  /**
   * Callback for tokenizePayment.
   *
   * @see https://braintree.github.io/braintree-web/current/PayPalCheckout.html#tokenizePayment
   */
  async onPaypalPaymentTokenized(err, payload) {
    await this.handlePaymentFlow(async () => {
      this.fillNameAndEmailUsingPaypalDetails(payload.details);
      this.setPaymentNonceAndType(payload.nonce, 'paypal');
      this.submitForm(SubmitPurpose.PAYMENT);
      return true;
    });
  }

  /**
   * The details object comes from PayPal's tokenizePayload object, which is
   * documented here:
   *
   * @see https://braintree.github.io/braintree-web/current/PayPalCheckout.html#~tokenizePayload
   *
   * @param {*} details
   */
  fillNameAndEmailUsingPaypalDetails(details) {
    setInputValueWithEvent(
      document.getElementById('cart_payment_first_name'),
      details.firstName,
    );
    setInputValueWithEvent(
      document.getElementById('cart_payment_last_name'),
      details.lastName,
    );
    setInputValueWithEvent(
      document.getElementById('cart_payment_email'),
      details.email,
    );
  }

  onPaypalError(err) {
    console.error('[FGF] PayPal error occurred:', err);
  }

  get amountToPay() {
    const { amount } = document.querySelector('.js-amount-to-pay').dataset;
    return amount;
  }

  paypalButtonCreateBillingAgreement(paypalCheckoutInstance) {
    return paypalCheckoutInstance.createPayment({
      flow: 'vault',
      billingAgreementDescription: `By proceeding, you agree to pay $${this.amountToPay}.`,
      enableShippingAddress: true,
      shippingAddressEditable: false,
      shippingAddressOverride: this.getShippingAddressForPaypal(),
    });
  }

  /**
   * Returns shipping address for use with Paypal's shippingAddressOverride
   * option in createPayment.
   *
   * @see https://braintree.github.io/braintree-web/current/PayPalCheckout.html#createPayment
   * @returns {Object} - Shipping address for PayPal
   */
  getShippingAddressForPaypal() {
    return {
      recipientName: [
        this.getInputValue('#cart_delivery_first_name'),
        this.getInputValue('#cart_delivery_last_name'),
      ].join(' '),
      line1: this.getInputValue('#cart_delivery_address_1'),
      line2: this.getInputValue('#cart_delivery_address_2'),
      city: this.getInputValue('#cart_delivery_city'),
      state: this.getInputValue('#cart_delivery_state'),
      postalCode: this.getInputValue('#cart_delivery_zip_code'),
      countryCode: 'US',
      phone: this.getInputValue('#cart_delivery_phone'),
    };
  }

  getInputValue(inputId) {
    return this.mainForm.querySelector(inputId).value;
  }

  // TODO: There's probably a less brittle way to do this
  isDeliveryAddressFilledOut() {
    const requiredFields = [
      'first_name',
      'last_name',
      'address_1',
      'city',
      'state',
      'zip_code',
      'phone',
    ];
    return requiredFields.every((field) => {
      const value = this.getInputValue(`#cart_delivery_${field}`);
      return value && value.length > 0;
    });
  }

  // This getter checks also if ApplePay is available at all before trying to
  // initialize it. If it is not available the ApplePaySession will be
  // undefined.
  get isApplePayAvailable() {
    return (
      window.ApplePaySession &&
      ApplePaySession.supportsVersion(3) &&
      ApplePaySession.canMakePayments()
    );
  }

  get isGoogleAvailable() {
    return typeof google !== 'undefined';
  }

  initializeApplePay(braintreeClientInstance) {
    if (!this.isApplePayAvailable) {
      return;
    }
    this.applePayButton.addEventListener('click', () => {
      if (!this.isDeliveryAddressFilledOut()) {
        alert('Please fill out the delivery address before using Apple Pay.');
        return;
      }

      braintree.applePay.create(
        {
          client: braintreeClientInstance,
        },
        (applePayErr, applePayInstance) => {
          if (applePayErr) {
            alert('Apple Pay failed to load.');
            return;
          }

          const paymentRequest = applePayInstance.createPaymentRequest({
            total: {
              label: 'Farmgirl Flowers',
              amount: this.amountToPay,
            },

            // Make sure we get billing info from the user upon a successful payment
            requiredBillingContactFields: [
              'email',
              'name',
              'phone',
              'postalAddress',
            ],
          });

          const session = new ApplePaySession(3, paymentRequest);

          session.onvalidatemerchant = (event) => {
            applePayInstance.performValidation(
              {
                validationURL: event.validationURL,
                displayName: 'Farmgirl Flowers',
              },
              (err, merchantSession) => {
                if (err) {
                  alert('Apple Pay failed to load.');
                  return;
                }
                session.completeMerchantValidation(merchantSession);
              },
            );
          };

          // See:
          // https://developer.paypal.com/braintree/docs/guides/apple-pay/client-side/javascript/v3/#onpaymentauthorized-callback
          session.onpaymentauthorized = (event) => {
            applePayInstance.tokenize(
              {
                token: event.payment.token,
              },
              (tokenizeErr, payload) => {
                if (tokenizeErr) {
                  console.error('Error tokenizing Apple Pay:', tokenizeErr);
                  session.completePayment(ApplePaySession.STATUS_FAILURE);
                  alert('Payment was not processed, please try again');
                  return;
                }

                this.setPaymentNonceAndType(payload.nonce, 'apple_pay');
                this.setPaymentInfoUsingApplePayBillingContact(
                  event.payment.billingContact,
                );

                session.completePayment(ApplePaySession.STATUS_SUCCESS);

                this.submitForm(SubmitPurpose.PAYMENT);
              },
            );
          };
          session.begin();
        },
      );
    });
  }

  /**
   * @see https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentcontact
   *    for available properties on billingContact
   *
   * @param {*} billingContact
   */
  setPaymentInfoUsingApplePayBillingContact(billingContact) {
    const [line1, line2] = billingContact.addressLines;
    this.setFormValuesByInputSelectors({
      '#cart_payment_first_name': billingContact.givenName || '',
      '#cart_payment_last_name': billingContact.familyName || '',
      '#cart_payment_address_1': line1,
      '#cart_payment_address_2': line2 || '',
      '#cart_payment_city': billingContact.locality,
      '#cart_payment_state': billingContact.administrativeArea, // adminstrativeArea is the state (2-letter code)
      '#cart_payment_zip_code': billingContact.postalCode,
      '#cart_payment_phone': billingContact.phoneNumber,
    });

    // Not always available, even if requiredBillingContactFields is set
    if (billingContact.emailAddress && billingContact.emailAddress.length > 0) {
      this.setFormValuesByInputSelectors({
        '#cart_payment_email': billingContact.emailAddress,
      });
    }
  }

  /**
   * Sets the form values based on the provided selectors and values.
   *
   * @param {Object} selectorsAndValues - An object containing selectors as keys
   * @returns {void}
   */
  setFormValuesByInputSelectors(selectorsAndValues) {
    Object.entries(selectorsAndValues).forEach((selectorAndValue) => {
      const [selector, value] = selectorAndValue;
      const input = document.querySelector(selector);
      setInputValueWithEvent(input, value);
    });
  }
}
