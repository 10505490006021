/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['overlay'];
  }

  overlayTargetConnected(target) {
    // Make sure it's hidden. Ideally this is already done in the HTML to prevent flickering.
    target.classList.add('hidden');
  }

  show() {
    this.overlayTargets.forEach((overlay) => {
      overlay.classList.remove('hidden');
    });
  }

  hide() {
    this.overlayTargets.forEach((overlay) => {
      overlay.classList.add('hidden');
    });
  }
}
