import { Box, Typography } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';
import { COLORS } from 'theme/colors';
import { Button } from 'ui-components/v2/Button';
import { up, down } from 'utils/breakpointHelpers';

export const useCorporateGiftingStyles = makeStyles(() => ({
  paragraph: {
    margin: '1em 0',

    [down('sm')]: {
      fontSize: 16,
      lineHeight: '18px',
    },

    '&.carousel-desc': {
      [down('md')]: {
        width: '100%',
      },

      width: '60%',
      margin: 'auto',
    },
  },

  heroText: {
    padding: '4em 2em',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',

    [up('md')]: {
      padding: '4em',
    },
  },

  howItWorks: {
    marginTop: '4em',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'max-content',
    height: 'auto',
    textAlign: 'center',

    [up('lg')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gridGap: '20px',
    },
  },

  infoBox: {
    border: '1px solid #000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '300px',
    padding: '2em',
    position: 'relative',
  },

  infoNumber: {
    position: 'absolute',
    left: '50%',
    top: '-25px',
    transform: 'translate(-50%)',
    backgroundColor: '#fff',
    height: '50px',
    width: 'auto',
    padding: '1em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  iconBox: {
    [down('md')]: {
      marginBottom: '4em',
    },

    marginTop: '2em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
  },

  description: {
    padding: '4em 0',
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: 'auto',
    textAlign: 'center',

    '& h1': {
      textAlign: 'left',
    },
  },

  carouselArea: {
    textAlign: 'center',
  },
}));

export const BoldText = styled('strong')((props: { color?: string }) => ({
  fontWeight: 600,
  color: props.color || '#000000',
}));

export const StyledLink = styled('a')({
  textDecoration: 'underline',

  '&.default': {
    color: '#000',

    '&:hover': {
      color: COLORS.fluorescentPink,
    },
  },
});

export const CorporateGiftingWrapper = styled(Box)({
  margin: 'auto',
  maxWidth: '1440px',
});

export const HeroWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: 'max-content',

  [up('sm')]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
});

export const HeroHeader = styled(Typography)({
  margin: 0,
  fontSize: '34px',
  lineHeight: '40px',
  color: '#101828',
  marginBottom: '30px',

  [down('md')]: {
    fontSize: '34px',
    lineHeight: '40px',
  },

  [down('sm')]: {
    width: '320px',
  },
});

export const HeroImage = styled('img')({
  height: '100%',
  width: '100%',
  objectFit: 'cover',
});

export const OrderButton = styled(Button)({
  padding: '12px 20px',
  marginTop: '56px',
  width: 'max-content',
});

export const InquiriesInfoSection = styled(Box)({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 48,

  [down('sm')]: {
    marginTop: 24,
  },
});

export const InquiriesHeader = styled(Typography)({
  margin: 0,
  fontFamily: ['quincy-cf', 'Arial', 'sans-serif'].join(','),
  fontWeight: 500,
  color: '#101828',
  fontSize: '48px',
  lineHeight: '52px',
  [down('md')]: {
    fontSize: '34px',
    lineHeight: '40px',
  },

  [down('sm')]: {
    width: '320px',
  },
});

export const InquiriesInfo = styled(Typography)({
  width: '520px',
  margin: '1em 0',

  [down('sm')]: {
    fontSize: 16,
    lineHeight: '18px',
    width: '320px',
  },
});

export const FormHeader = styled(Typography)({
  margin: 0,
  fontFamily: ['quincy-cf', 'Arial', 'sans-serif'].join(','),
  fontWeight: 500,
  fontSize: '34px',
  lineHeight: '40px',
  marginBottom: '26px',
  textAlign: 'center',

  [down('md')]: {
    fontSize: '24px',
    lineHeight: '30px',
  },
});

export const GetInTouchForm = styled('form')({
  width: 600,
  maxWidth: 600,
  padding: 32,

  [down('sm')]: {
    width: 'max-content',
    maxWidth: '90%',
  },
});
