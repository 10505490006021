import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['content', 'icon'];
  }

  toggle() {
    if (
      this.contentTarget.style.maxHeight &&
      this.contentTarget.style.maxHeight !== '0px'
    ) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    this.contentTarget.style.maxHeight = `${this.contentTarget.scrollHeight}px`;

    this.iconTarget.style.transform = 'rotate(180deg)';
  }

  close() {
    this.contentTarget.style.maxHeight = '0px';

    this.iconTarget.style.transform = 'rotate(0deg)';
  }
}
