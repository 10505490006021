import React, { useCallback, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useController } from 'react-hook-form';
import { CalendarToday, Close } from '@material-ui/icons';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import DatePicker from 'ui-components/v2/DatePicker';
import { UNAVAILABLE_DAY_DATE_FORMAT } from 'constants/time';
import {
  ProductsFormFieldNames as FIELD_NAMES,
  SORTBY_VALUES,
} from 'pages/shop/hooks/constants';
import { dateFormatter } from 'utils/dateHelpers';
import { DeliveryDatesProps } from './interface';
import { DeliveryDatesContainer } from './styled';

export const DeliveryDates = ({ control }: DeliveryDatesProps) => {
  const [initialDate, setInitialDate] = useState<Dayjs | null>(null);

  const {
    field: { ref, onChange, value: formDate, ...deliveryDateField },
  } = useController({
    name: FIELD_NAMES.deliveryDate,
    control,
  });
  const { field: sortByField } = useController({
    name: FIELD_NAMES.sortBy,
    control,
  });

  const handleDateChange = useCallback(
    (date: MaterialUiPickersDate) => {
      if (!date) return;

      sortByField.onChange(SORTBY_VALUES.recommended);
      setInitialDate(date);
    },
    [setInitialDate, sortByField],
  );

  const handleDateAccept = useCallback(
    (date: MaterialUiPickersDate) => {
      if (!date) return;

      onChange(date.format(UNAVAILABLE_DAY_DATE_FORMAT));
      setInitialDate(date);
    },
    [onChange],
  );

  useEffect(() => {
    const parsedDate = formDate ? dayjs(dateFormatter(formDate)) : null;
    setInitialDate(parsedDate);
  }, [formDate]);

  return (
    <DeliveryDatesContainer>
      <DatePicker
        fullWidth
        disablePast
        id="shop-delivery-date-input"
        className="tracking-delivery-date-input-container"
        label="Delivery Date"
        shouldDisableDate={(date) => date?.day() === 0}
        value={initialDate}
        onChange={handleDateChange}
        onAccept={handleDateAccept}
        keyboardIcon={
          initialDate ? (
            <Close
              fontSize="small"
              onClick={(event) => {
                event.stopPropagation();
                onChange(null);
              }}
            />
          ) : (
            <CalendarToday style={{ width: 15 }} fontSize="small" />
          )
        }
        {...deliveryDateField}
      />
    </DeliveryDatesContainer>
  );
};
