import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['insuranceEnabled', 'form'];
  }

  static get values() {
    return {
      storeDomain: String,
      subtotal: Number,
      merchantId: String,
      existsInCart: Boolean,
      loadingStyles: String,
    };
  }

  connect() {
    // TODO(kspe): ensure here that window.Route.Protection is available

    window.Route.Protection.render({
      storeDomain: this.storeDomainValue,
      subtotal: this.subtotalValue,
      currency: 'USD',
      environment: window.Route.Environment.Production,
      merchantId: this.merchantIdValue,
      status: this.existsInCartValue
        ? window.Route.Coverage.ActiveByDefault
        : window.Route.Coverage.InactiveByDefault,
      theming: {
        mode: window.Route.Theme.Mode.LightMode,
        alignment: window.Route.Theme.Alignment.Left,
      },
    });

    window.Route.Protection.on(
      'status_change',
      this.handleStatusChange.bind(this),
    );
  }

  disconnect() {
    window.Route.Protection.off(
      'status_change',
      this.handleStatusChange.bind(this),
    );
  }

  handleStatusChange(event) {
    this.insuranceEnabledTarget.value = event.to == 1 ? 1 : 0;
    document.getElementById('route-div-container').style.cssText =
      this.loadingStylesValue;
    this.formTarget.requestSubmit();
  }
}
