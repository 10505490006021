import React, { useState } from 'react';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';

const DatePicker = ({ PopoverProps, ...rest }: KeyboardDatePickerProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);

    if (rest.onOpen) {
      rest.onOpen();
    }
  };

  return (
    <KeyboardDatePicker
      open={open}
      disableToolbar
      variant="inline"
      size="small"
      id="date-picker"
      autoOk
      fullWidth
      inputVariant="outlined"
      format="MM/DD/YYYY"
      keyboardIcon={<CalendarIcon style={{ width: 15 }} fontSize="small" />}
      InputProps={{
        onFocus: handleOpen,
      }}
      PopoverProps={{
        disableRestoreFocus: true,
        onClose: () => {
          setOpen(false);
        },
        ...PopoverProps,
      }}
      KeyboardButtonProps={{
        'aria-label': 'change date',
        style: {
          background: 'transparent',
          border: 'none',
          padding: 0,
          color: '#000',
        },
      }}
      {...rest}
      onOpen={handleOpen}
      onClose={() => setOpen(false)}
    />
  );
};

export default DatePicker;
