import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';
import { styled } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

import { Button } from 'ui-components/v2/Button';
import { down } from 'utils/breakpointHelpers';

export const ProductCardInfo = styled('div')({
  display: 'grid',
  gap: 6,
});
export const ProductTypographySmall = styled(Typography)({
  height: 32,
});

export const ProductTypographySmall2 = styled(Typography)({
  whiteSpace: 'nowrap',
  height: 16,
});

export const DetailsButtonStyled = styled(Button)(
  ({ mousein }: { mousein: string }) => ({
    paddingY: 9,
    color: mousein
      ? 'var(--fgf-contained-button-hover-color) !important'
      : 'var(--fgf-contained-button-color) !important',
    background: mousein
      ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)) var(--fgf-contained-button-hover-bg) !important'
      : 'var(--fgf-contained-button-bg) !important',
    width: '100%',
  }),
);

export const ProductImage = styled('div')({
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  [down('sm')]: {
    height: 'auto',
  },
  '& > div > picture > img': {
    transition: 'all 0.3s',
    transform: 'scale(1)',
  },
  '&:hover': {
    '& > div > picture > img': {
      transform: 'scale(1.1)',
    },
  },
});

export const StyledNumberFormat = styled(NumberFormat)(
  (props: { isdiscounted: string }) => ({
    marginLeft: 4,
    textDecoration: props.isdiscounted ? 'line-through' : 'none',
  }),
);

export const StyledDiscountNumberFormat = styled(NumberFormat)(() => ({
  color: '#EF868F',
  marginLeft: 8,
}));

// Skeleton

export const ProductSkeletonStyled = styled('div')({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr',
});

export const ImageSkeleton = styled(Skeleton)({
  borderRadius: 2,
  height: 0,
  paddingTop: '100%',
  marginBottom: 12,
});

export const TypographySkeleton = styled(Skeleton)({
  borderRadius: 100,
});

export const TypographySkeleton2 = styled(Skeleton)({
  borderRadius: 100,
});

export const TypographySkeleton3 = styled(Skeleton)({
  borderRadius: 100,
  marginTop: 4,
});

export const PriceSkeleton = styled(Skeleton)({
  marginTop: 12,
  borderRadius: 100,
});
