import React from 'react';
import { Typography } from '@material-ui/core';
import { useWatch } from 'react-hook-form';

import { CheckboxGroup } from '../CheckboxGroup';
import { FilterGroup } from '../../interface';
import { OccasionProps } from './interface';

export function Occasion({ control }: OccasionProps) {
  const occasions = useWatch({
    control,
    name: FilterGroup.Occasion,
  });

  return (
    <>
      <Typography variant="body1">
        <strong>Occasion</strong>
      </Typography>
      <CheckboxGroup
        control={control}
        fields={occasions}
        category={FilterGroup.Occasion}
      />
    </>
  );
}
