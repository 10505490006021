import React from 'react';
import { Typography } from '@material-ui/core';
import { Container, Separator } from './styled';

interface SEOCopyProps {
  text: string;
}

export const SEOCopy = ({ text }: SEOCopyProps) => (
  <Container>
    <Separator />
    <Typography
      variant="body1"
      component="div"
      dangerouslySetInnerHTML={{ __html: text }}
    />
  </Container>
);
