import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="select-two"
export default class extends Controller {
  static get values() {
    return {
      placeholder: String,
    };
  }

  connect() {
    this.lazyLoadSelect2();
  }

  get select2() {
    this.lazyLoadSelect2();
    return this.$elem;
  }

  lazyLoadSelect2() {
    if (!this.$elem) {
      this.$elem = $(this.element);
      this.$elem.select2({
        placeholder: this.placeholderValue || '– Select option –',
      });
    }
  }
}
