import { Box, styled, Typography } from '@material-ui/core';

import { Button } from 'ui-components/v2/Button';
import { down } from 'utils/breakpointHelpers';

export const ContainerStyled = styled(Box)({
  padding: 20,
  paddingBottom: 32,
});

export const ActionButtonStyled = styled(Button)({
  width: 'auto',
  minWidth: '82px',
  [down('sm')]: {
    width: 'auto',
    minWidth: '78px',
    paddingInline: '20px',
  },
});

export const TitleStyled = styled(Typography)({
  marginBottom: 20,
  fontSize: '34px',
  lineHeight: '40px',
  [down('md')]: {
    fontSize: 24,
    lineHeight: '30px',
  },
  [down('sm')]: {
    fontSize: 20,
    lineHeight: '24px',
  },
});

export const ContentStyled = styled(Box)({
  display: 'flex',
  gap: '17px',
  [down('sm')]: {
    paddingBottom: '15px',
  },
});

export const MediaStyled = styled('div')({
  width: '191px',
  height: '191px',
  [down('sm')]: {
    width: '141px',
    height: '141px',
  },
});

export const NameStyled = styled(Typography)({
  maxWidth: 285,
  fontSize: '24px',
  lineHeight: '28px',
  [down('sm')]: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,
  },
});

export const PriceStyled = styled(Typography)({
  fontSize: '18px',
  lineHeight: '24px',
  [down('sm')]: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 400,
  },
});

export const InfoStyled = styled(Typography)({
  textTransform: 'capitalize',
  [down('sm')]: {
    fontSize: 16,
    lineHeight: '18px',
  },
});
