import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import withWrappers from 'hocs/ReactKeyProvider';
import Slide from 'ui-components/Image';
import ProductBadge from 'components/ProductBadge';
import { Image } from 'pages/pdp/interface';
import {
  CenterContainerStyled,
  DotListStyled,
  DotStyled,
  ContainerStyled,
  NavContainerStyled,
  IconButtonStyled,
} from './styled';
import Thumbnail from './Thumbnail';

interface GalleryProps {
  images: Image[];
  label: string;
}

const Gallery = ({ images, label }: GalleryProps) => {
  const sliderRef = useRef<any>(null);
  const [index, setIndex] = useState(0);
  const sortedImages = images
    .sort((a, b) => a.position - b.position)
    .slice(0, 10);

  return (
    <ContainerStyled container aria-hidden>
      <NavContainerStyled item>
        <Slider
          vertical
          swipeToSlide
          verticalSwiping
          speed={500}
          slidesToShow={5}
          slidesToScroll={1}
          infinite={sortedImages.length > 5}
          nextArrow={
            <IconButtonStyled
              id="next-arrow"
              className="tracking-view-more-images-arrow"
            >
              <ExpandMoreIcon />
            </IconButtonStyled>
          }
        >
          {sortedImages.map((image, i) => (
            <div
              key={image.id}
              data-position={i}
              className="tracking-gallery-navigation"
            >
              <Thumbnail
                image={image}
                selected={index === i}
                handleClick={() => sliderRef.current?.slickGoTo(i)}
              />
            </div>
          ))}
        </Slider>
      </NavContainerStyled>
      <CenterContainerStyled item>
        <div className="relative">
          <Slider
            dots
            speed={500}
            ref={sliderRef}
            infinite={false}
            slidesToShow={1}
            slidesToScroll={1}
            customPaging={() => <DotStyled />}
            beforeChange={(current, next) => setIndex(next)}
            appendDots={(dots) => <DotListStyled>{dots}</DotListStyled>}
          >
            {sortedImages.map((image) => (
              <Slide
                useWebP
                isSquare
                width="100%"
                height="100%"
                key={image.id}
                filename={image.filename}
                src={image.cloudinary_photo_id}
                alt={`image of ${image.filename}`}
              />
            ))}
          </Slider>
          {label ? <ProductBadge label={label} /> : null}
        </div>
      </CenterContainerStyled>
    </ContainerStyled>
  );
};

export default withWrappers(Gallery);
