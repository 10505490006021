/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  FormControlLabel as MUIFormControlLabel,
  FormControlLabelProps,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { COLORS } from 'theme/colors';

export const FormControlLabel = withStyles({
  root: {
    maxHeight: '34px',
  },
  label: {
    fontSize: 16,
    color: COLORS.black,
    opacity: 0.8,
    lineHeight: '20px',
  },
})((props: FormControlLabelProps) => <MUIFormControlLabel {...props} />);
