import React from 'react';

import Slide from 'ui-components/Image';
import { Image } from 'pages/pdp/interface';
import { ThumbnailContainerStyled, ThumbnailOverlayStyled } from './styled';

interface ThumbnailProps {
  image: Image;
  selected: boolean;
  handleClick?: () => void;
}

const Thumbnail = ({ image, selected, handleClick }: ThumbnailProps) => (
  <ThumbnailContainerStyled onClick={handleClick}>
    <Slide
      useWebP
      isSquare
      width="100%"
      key={image.id}
      alt="thumbnail"
      src={image.cloudinary_photo_id}
    />
    {!selected && <ThumbnailOverlayStyled />}
  </ThumbnailContainerStyled>
);

export default Thumbnail;
