export const COLORS = {
  aquamarine: '#44bab5',
  aquamarineDark: '#369490',
  grayHeader: '#303030',
  grayText: '#626262',
  grayTextLight: '#666666',
  grayInputBorder: '#e5e5e5',
  grayBackgroundLight: '#F2F2F2',
  patina: '#407A52',
  patinaDark: '#104d29',
  patinaLight: '#6ea97f',
  oldWebsiteGray: '#444444',
  greenAccent: '#67B8B4',
  greenHovered: '#44928E',
  greenDisabled: '#A8CFCD',
  pinkAccent: '#EF868F',
  inputGray: '#F5F5F5',
  borderGray: '#DFE0E2',
  dividerGray: '#ECECEC',
  errorBorder: '#EF868F',
  disabledInput: '#E1DFDF',
  checkboxHover: 'rgba(103, 184, 180, 0.5)',
  black: '#000000',
  black10: '#E5E5E5',
  black20: '#CCCCCC',
  black30: '#B3B3B3',
  black40: '#999999',
  black50: '#808080',
  black70: '#4C4C4C',
  black90: '#1A1A1A',
  white: '#FFFFFF',
  quicksand: '#F3E8E2',
  quicksand10: '#FEFDFC',
  neonChartreuse: '#E3FF04',
  neonChartreuse20: '#F7FFB4',
  neonChartreuse10: '#FCFFE6',
  fluorescentPink: '#e50077',
  fluorescentPink50: '#FF80C4',
  hotOrange: '#FF5B00',
  brightRed: '#FF432F',
  textMain: '#000',
  textSecondary: '#27303E',
  textSecondaryLight: '#27303E',
};
