import { Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { ArrowForward, Check } from '@material-ui/icons';
import { Button } from 'ui-components/v2/Button';
import { down, up } from 'utils/breakpointHelpers';

export const SignUpFormsContainer = styled('div')({
  borderTop: '1px solid #000000',
  borderBottom: '1px solid #000000',
});

export const MaxContainer = styled('div')({
  fontFamily: ['brandon-grotesque', 'sans-serif'].join(','),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: 1440,
  width: '100%',
  margin: '0 auto',

  [up('md')]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
});

export const FormContainer = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  height: 308,
  padding: '40px 24px',
  justifyContent: 'center',
  background: '#fff',

  '&.left': {
    borderRight: '1px solid #000000',

    [down('md')]: {
      borderBottom: '1px solid #000000',
      borderRight: 'none',
    },
  },

  [up('md')]: {
    alignItems: 'start',
    paddingLeft: '80px',
  },
});

export const FormHeader = styled(Typography)({
  color: '#000000',
  fontWeight: 600,
  margin: 0,
  [down('sm')]: {
    fontSize: 16,
    lineHeight: '18px',
  },
});

export const FormInfo = styled(Typography)({
  color: '#000000',
  margin: 0,
  marginBottom: 16,
  maxWidth: '421px',
  width: '85%',
  height: '36px',
  [down('sm')]: {
    fontSize: 16,
    lineHeight: '18px',
  },
});

export const FormDisclaimer = styled(Typography)({
  marginBottom: 24,
  maxWidth: 421,
  width: '85%',
  fontStyle: 'italic',
  fontSize: 10,
  lineHeight: '14px',
  textTransform: 'none',
});

export const InputSection = styled('div')({
  display: 'flex',
  gap: '16px',
});

export const StyledInput = styled('input')({
  fontFamily: ['brandon-grotesque', 'sans-serif'].join(','),
  width: '286px',
  height: '42px',
  background: '#FFFFFF',
  border: '1px solid #000000',
  borderRadius: '4px',
  padding: '5px 10px',

  [down('md')]: {
    width: '250px',
  },

  [down('sm')]: {
    flexGrow: 1,
    maxWidth: 220,
  },
});

export const StyledButton = styled(Button)({
  height: 42,
  flexShrink: 0,
  '&:disabled': {
    cursor: 'not-allowed',
  },
});

export const SubmitText = styled('div')({
  [down('sm')]: {
    display: 'none',
  },
});

export const SubmitArrrow = styled(ArrowForward)({
  width: '24px',

  [up('sm')]: {
    display: 'none',
  },
});

export const CheckMobile = styled(Check)({
  width: '24px',

  [up('sm')]: {
    display: 'none',
  },
});

export const ErrorText = styled(Typography)({
  color: '#f44336 !important',
  margin: 0,
  fontSize: '12px',
  height: 14,
});

export const SubscriptionSuccess = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
});
