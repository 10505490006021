import { styled } from '@material-ui/core/styles';
import { TextField, Typography } from '@material-ui/core';
import { down } from 'utils/breakpointHelpers';

export const Title = styled(Typography)({
  marginBottom: 20,
});

export const TextFieldsContainer = styled('div')({
  display: 'inline-grid',
  gridTemplateColumns: '16fr 3fr 16fr',
  marginBottom: 6,
});

export const PriceTextField = styled(TextField)({
  fontSize: 18,
  [down('sm')]: {
    fontSize: 14,
  },
});

export const HorizontalDivider = styled('div')({
  textAlign: 'center',
  margin: 'auto',
  color: 'rgba(0, 0, 0, 0.3)',
});
