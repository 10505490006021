import React from 'react';
import DatePicker from 'ui-components/v2/DatePicker';
import { AVAILABLE_DAY_DATE_FORMAT } from 'constants/time';

import { pushMetricsEvent } from 'hooks/useDataLayer';
import DatesRangePopper from './DatesRangePopper';
import AdjustedShippingDay from './AdjustedShippingDay';
import { DeliveryWindowPickerProps } from './interface';
import { useDeliveryDates } from './useDeliveryDates';

const DeliveryWindowPicker = ({
  productId,
  fieldName,
  control,
  purchasable,
  ...rest
}: DeliveryWindowPickerProps) => {
  const {
    name,
    error,
    label,
    disabled,
    modalOpen,
    getMaxDate,
    initialDate,
    toggleModal,
    setFormDateCb,
    getHelperText,
    availableDates,
    handleDateChange,
    handleDateAccept,
    shouldDisableDate,
    handleMonthChange,
    initiallyHighlightedDate,
  } = useDeliveryDates({ control, fieldName, productId, purchasable });

  const CalendarLegend = () => (
    <div className="px-[18px] pb-5 sm:px-7">
      <p className="m-0">
        <span className="bullet bg-gray-500" />
        Shipping Price
      </p>
    </div>
  );

  return (
    <>
      {initialDate && (
        <DatesRangePopper
          initialDate={initialDate}
          setFormDate={setFormDateCb}
          modalOpen={modalOpen}
          toggleModal={toggleModal}
          availableDates={availableDates}
        />
      )}
      <DatePicker
        disablePast
        name={name}
        label={label}
        id="pdp-delivery-date-input"
        className="tracking-delivery-date-input-container"
        error={error}
        disabled={disabled}
        value={initialDate}
        disableToolbar={false}
        onChange={handleDateChange}
        onAccept={handleDateAccept}
        helperText={getHelperText()}
        onMonthChange={handleMonthChange}
        ToolbarComponent={CalendarLegend}
        shouldDisableDate={shouldDisableDate}
        initialFocusedDate={initiallyHighlightedDate}
        maxDate={getMaxDate({ amount: 6, unit: 'month' })}
        onOpen={() => pushMetricsEvent('ga4', { event: 'calendar_open' })}
        renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
          if (!day) return dayComponent;

          const availableDate = availableDates.find(
            (obj) => obj.date === day.format(AVAILABLE_DAY_DATE_FORMAT),
          );

          if (availableDate && dayInCurrentMonth) {
            return (
              <AdjustedShippingDay
                day={day.date()}
                selected={!!selectedDate?.isSame(day)}
                price={`${availableDate.shipping_price}`}
                priceDiff={`${availableDate.shipping_price_diff}`}
                overriden={availableDate.overriden}
              />
            );
          }

          return dayComponent;
        }}
        {...rest}
      />
    </>
  );
};

export default DeliveryWindowPicker;
