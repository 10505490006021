import { Box, styled } from '@material-ui/core';

export const ContainerPosition = styled('div')({
  position: 'fixed',
  bottom: -84,
  right: 0,
  left: 0,
  height: 80,
  zIndex: 9999,
  transition: 'top 500ms',
});

export const ContainerStyled = styled(Box)({
  height: 80,
  background: '#FFF',
  boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.25)',
  padding: '21px 18px',
});
