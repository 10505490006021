import { Controller } from '@hotwired/stimulus';

/**
 * Applies class to detect if form input has a value, so the labels can be animated in CSS.
 * Attache this controller to the <form> element.
 */
export default class extends Controller {
  static get targets() {
    return ['labelInput'];
  }

  initialize() {
    this.fieldWrapperSelector = '.fgf-mui-form-field';
    this.hasValueClass = 'fgf-has-value';
    this.hasFocusClass = 'fgf-has-focus';
  }

  // Input fields can get added dynamically, so we need to listen for those
  labelInputTargetConnected(target) {
    // Ignore if target is a <label> element
    if (target.tagName === 'LABEL') return;

    const fieldWrapper = target.closest(this.fieldWrapperSelector);
    this.attachInputListeners(fieldWrapper, target);
  }

  /**
   * Attaches listeners to the input fields inside the simple_form field wrapper
   * element.
   */
  attachInputListeners(fieldWrapper, input) {
    this.handleInputChange(fieldWrapper, input); // Set initial state

    input.addEventListener('change', () => {
      this.handleInputChange(fieldWrapper, input);
    });

    input.addEventListener('focus', () => {
      fieldWrapper.classList.add(this.hasFocusClass);
    });

    input.addEventListener('blur', () => {
      fieldWrapper.classList.remove(this.hasFocusClass);
    });
  }

  handleInputChange(wrapper, input) {
    if (input.value) {
      input.classList.add(this.hasValueClass);
      wrapper.classList.add(this.hasValueClass);
    } else {
      input.classList.remove(this.hasValueClass);
      wrapper.classList.remove(this.hasValueClass);
    }
  }
}
