import React from 'react';

import Modal from 'ui-components/v2/Modal';
import {
  ContainerStyled,
  ModalActionButtonStyled,
  ModalBodyStyled,
  ModalTitleStyled,
} from './styled';

interface SubscriptionLearnMoreProps {
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  setFormOpen: (value: React.SetStateAction<boolean>) => void;
}

const SubscriptionLearnMore = ({
  open,
  setOpen,
  setFormOpen,
}: SubscriptionLearnMoreProps) => (
  <Modal
    open={open}
    setOpen={setOpen}
    primaryButton={
      <ModalActionButtonStyled
        variant="contained"
        color="primary"
        onClick={() => {
          setOpen(false);
          setFormOpen(true);
        }}
      >
        Sign me up!
      </ModalActionButtonStyled>
    }
    secondaryButton={
      <ModalActionButtonStyled
        variant="contained"
        color="secondary"
        onClick={() => setOpen(false)}
      >
        This is a one time thing
      </ModalActionButtonStyled>
    }
  >
    <ContainerStyled>
      <ModalTitleStyled variant="h3">
        About Recurring Deliveries
      </ModalTitleStyled>
      <ModalBodyStyled variant="body1">
        Do you love the effect of fresh flowers in your living space? Then a
        recurring delivery is right up your alley! We can deliver weekly,
        biweekly, or monthly if you'd like flowers to brighten up your home or
        office.
      </ModalBodyStyled>
      <ModalBodyStyled variant="body1">
        When placing your order, select the “Make It A Recurring Delivery”
        button. Then select your delivery start date, end date, and frequency.
        Review and confirm your selections and proceed to checkout! If there are
        delivery dates that you’d like to change, please let us know via
        orders@farmgirlflowers.com or call us at (855) 202-3817. Provided the
        item and day aren’t sold out or unavailable, we’ll be glad to help you!
      </ModalBodyStyled>
      <ModalBodyStyled variant="body1">
        Note that you will be charged upfront for the first bouquet plus the
        shipping fee. For future deliveries, we'll charge your card a few days
        prior to each subsequent order.
      </ModalBodyStyled>
    </ContainerStyled>
  </Modal>
);

export default SubscriptionLearnMore;
