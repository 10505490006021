import React from 'react';
import { Typography } from '@material-ui/core';
import { useWatch } from 'react-hook-form';

import { CheckboxGroup } from '../CheckboxGroup';
import { FilterGroup } from '../../interface';
import { CollectionProps } from './interface';

export function Collection({ control }: CollectionProps) {
  const collections = useWatch({
    control,
    name: FilterGroup.Collection,
  });

  return (
    <>
      <Typography variant="body1">
        <strong>Collection</strong>
      </Typography>
      <CheckboxGroup
        control={control}
        fields={collections}
        category={FilterGroup.Collection}
      />
    </>
  );
}
