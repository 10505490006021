import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['street1', 'street2', 'city', 'region', 'postcode'];
  }

  static get values() {
    return {
      street1: String,
      street2: String,
      city: String,
      region: String,
      postcode: String,
    };
  }

  fillForm(event) {
    event.preventDefault();

    this.street1Target.value = this.street1Value;
    this.street2Target.value = this.street2Value;
    this.cityTarget.value = this.cityValue;
    this.regionTarget.value = this.regionValue;
    this.postcodeTarget.value = this.postcodeValue;
  }
}
