import React from 'react';

import AddSuccessModal from 'components/Modals/AddSuccess';
import SubscriptionFinal from 'components/Modals/SubscriptionFinal';
import { useMetricsEvent } from 'hooks/useDataLayer';
import { usePurchaseForm } from 'hooks/usePurchaseForm';
import { getDiscount, getSubtotal } from 'utils/getSubtotal';
import { PurchaseFormFieldNames as FIELD_NAMES } from 'constants/form';

import StickyATC from './StickyATC';
import ProductDetails from './ProductDetails';
import { PDPProps } from './interface';

const PDP = ({ product, filter_date }: PDPProps) => {
  const formProps = usePurchaseForm(product, filter_date);
  const addons = formProps.watch(FIELD_NAMES.addons);
  const subscription = formProps.watch(FIELD_NAMES.subscription);

  useMetricsEvent('ga4', {
    event: 'view_item',
    ecommerce: {
      currency: 'USD',
      value: getSubtotal([], product.price, product.sales_price),
      items: [
        {
          item_id: product.id.toString(),
          item_name: product.name.trim(),
          discount: getDiscount(product.price, product.sales_price),
          item_category: 'main_product',
          price: Number(product.price),
          quantity: 1,
        },
      ],
    },
  });

  useMetricsEvent('meta', {
    event_name: 'ViewContent',
    event_time: Math.floor(Date.now() / 1000),
    action_source: 'website',
    custom_data: {
      currency: 'USD',
      value: getSubtotal([], product.price, product.sales_price).toString(),
      content_type: 'product',
      content_ids: [product.id.toString()],
    },
    user_data: {
      zp: [null],
      client_user_agent: window?.navigator.userAgent,
    },
  });

  return (
    <>
      <ProductDetails product={product} {...formProps} />

      {subscription ? (
        <SubscriptionFinal
          open={formProps.showConfirmation}
          setOpen={formProps.toggleShowConfirmation}
          image={product.images[0].cloudinary_photo_id}
          control={formProps.control}
          {...product}
        />
      ) : (
        <AddSuccessModal
          open={formProps.showConfirmation}
          setOpen={formProps.toggleShowConfirmation}
          image={product.images[0].cloudinary_photo_id}
          addons={addons}
          {...product}
        />
      )}

      <StickyATC product={product} {...formProps} />
    </>
  );
};

export default PDP;
