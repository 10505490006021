import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="top-margin"
export default class extends Controller {
  static get targets() {
    return ['header', 'content'];
  }

  connect() {
    this.adjustContentMargin();

    // Adjust on window resize in case the header height changes
    window.addEventListener('resize', this.adjustContentMargin.bind(this));
  }

  disconnect() {
    window.removeEventListener('resize', this.adjustContentMargin.bind(this));
  }

  adjustContentMargin() {
    const headerHeight = this.headerTarget.offsetHeight;
    this.contentTarget.style.marginTop = `${headerHeight}px`;
  }
}
