import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['content'];
  }

  disconnect() {
    this.unbindOutsideClickListener();
  }

  toggle(event) {
    event.preventDefault();
    if (this.isOpen) {
      delete this.element.dataset.popoverOpen;
      this.unbindOutsideClickListener();
    } else {
      this.element.dataset.popoverOpen = 'true';
      this.bindOutsideClickListener();
    }
    this.element.classList.toggle('fgf-popover-open');
  }

  get isOpen() {
    return this.element.dataset.popoverOpen === 'true';
  }

  bindOutsideClickListener() {
    document.addEventListener('click', this.outsideClickListener.bind(this));
  }

  unbindOutsideClickListener() {
    document.removeEventListener('click', this.outsideClickListener.bind(this));
  }

  outsideClickListener(event) {
    // Close the popover if the click is outside of the popover
    if (!this.element.contains(event.target) && this.isOpen) {
      this.toggle(event);
    }
  }
}
