import React, { useCallback, useState } from 'react';
import { Dayjs } from 'dayjs';
import { useController, useWatch } from 'react-hook-form';
import { MenuItem } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import Modal from 'ui-components/v2/Modal';
import DatePicker from 'ui-components/v2/DatePicker';
import DeliveryWindowPicker from 'components/DeliveryWindowPicker';
import { DATE_FORMAT } from 'constants/time';
import { dateFormatter } from 'utils/dateHelpers';
import { HookFormProps } from 'pages/pdp/interface';
import { PurchaseFormFieldNames as FIELD_NAMES } from 'constants/form';
import {
  ContainerStyled,
  ActionButtonStyled,
  BodyStyled,
  TitleStyled,
  StepStyled,
  DateRangeContainerStyled,
  SelectStyled,
} from './styled';

interface SubscriptionFormProps
  extends Pick<
    HookFormProps,
    'control' | 'setValue' | 'handleSubmit' | 'onSubmit'
  > {
  id: number;
  purchasable: boolean;
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
}

const pickerDateFormatter = (date: MaterialUiPickersDate) => {
  if (!date) return '';
  return date.format('MM/D/YYYY');
};

const SubscriptionForm = ({
  open,
  setOpen,
  id,
  control,
  setValue,
  handleSubmit,
  onSubmit,
  purchasable,
}: SubscriptionFormProps) => {
  const [initialDate, setInitialDate] = useState<Dayjs | null>(null);
  const {
    field: {
      ref,
      onChange: endDateFieldChange,
      value: endDate,
      ...endDateField
    },
  } = useController({
    name: FIELD_NAMES.endDate,
    control,
  });
  const {
    field: { ref: freqRef, ...frequencyField },
  } = useController({
    name: FIELD_NAMES.frequency,
    control,
  });
  const startDate = useWatch({ control, name: FIELD_NAMES.startDate });

  const handleDateChange = useCallback(
    (date: MaterialUiPickersDate) => {
      if (!date) return;
      setInitialDate(date);
    },
    [setInitialDate],
  );

  const handleDateAccept = useCallback(
    (date: MaterialUiPickersDate) => {
      if (!date) return;

      endDateFieldChange(date.format(DATE_FORMAT));
      setInitialDate(date);
    },
    [endDateFieldChange],
  );

  const getHelperText = useCallback(() => {
    if (!startDate) return 'Select a start date';
    if (!endDate) return 'Select an end date';

    return '';
  }, [startDate, endDate]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      primaryButton={
        <ActionButtonStyled
          color="primary"
          variant="contained"
          disabled={!startDate || !endDate}
          onClick={() => {
            setValue(FIELD_NAMES.subscription, 'on');
            handleSubmit(onSubmit)();
            setOpen(false);
          }}
        >
          Confirm Delivery
        </ActionButtonStyled>
      }
      secondaryButton={
        <ActionButtonStyled
          variant="contained"
          color="secondary"
          onClick={() => setOpen(false)}
        >
          Close
        </ActionButtonStyled>
      }
    >
      <ContainerStyled>
        <TitleStyled variant="h3">Recurring Delivery Options</TitleStyled>
        <BodyStyled variant="body1">
          For recurring deliveries, choose a delivery date and frequency to
          receive floral arrangements at your door on a set schedule.
        </BodyStyled>
        <StepStyled variant="body1">
          Step 1: Choose Delivery Start and End Date
        </StepStyled>
        <DateRangeContainerStyled>
          <DeliveryWindowPicker
            required
            label="From"
            productId={id}
            purchasable={purchasable}
            style={{ width: 210 }}
            fieldName={FIELD_NAMES.startDate}
            labelFunc={pickerDateFormatter}
            control={control}
          />
          <DatePicker
            required
            disablePast
            label="To"
            minDate={dateFormatter(startDate)}
            disabled={!startDate}
            style={{ width: 210 }}
            labelFunc={pickerDateFormatter}
            helperText={getHelperText()}
            shouldDisableDate={(date) => (date ? date.day() === 0 : true)}
            value={initialDate}
            onChange={handleDateChange}
            onAccept={handleDateAccept}
            {...endDateField}
          />
        </DateRangeContainerStyled>
        <StepStyled variant="body1">
          Step 2: Choose Delivery Frequency
        </StepStyled>
        <SelectStyled
          required
          margin="dense"
          label="Frequency"
          {...frequencyField}
        >
          <MenuItem value="weekly">Weekly</MenuItem>
          <MenuItem value="biweekly">Every two weeks</MenuItem>
          <MenuItem value="monthly">Monthly</MenuItem>
        </SelectStyled>
      </ContainerStyled>
    </Modal>
  );
};

export default SubscriptionForm;
