import FormControlLabel from '@material-ui/core/FormControlLabel';
import { styled } from '@material-ui/core/styles';
import { RadioGroup, Typography } from '@material-ui/core';
import { Checkbox } from 'ui-components/v2/Checkbox';

export const RadioGroupStyled = styled(RadioGroup)({
  flexDirection: 'row',
  gap: '14px',
});

export const FormControlLabelStyled = styled(FormControlLabel)({
  alignItems: 'flex-start',
});

export const AddressSectionHeading = styled(Typography)({
  fontSize: 14,
  marginTop: 12,
  textTransform: 'uppercase',
  fontWeight: 'bold',
});

export const OptionContainer = styled('div')({
  maxWidth: '50%',
});

export const AddressListContainer = styled('div')({
  marginTop: 9,
});

export const LinkStyled = styled(Typography)({
  textDecoration: 'underline',
  cursor: 'pointer',
});

export const DisclaimerText = styled(Typography)({
  marginTop: 24,
});

export const SelectButtonContainer = styled('div')({
  marginTop: 24,
  position: 'sticky',
  bottom: '-22.5px',
  width: '100%',
  background: '#fff',
});

export const StyledCheckbox = styled(Checkbox)({
  marginRight: 8,
  marginLeft: 8,
  width: 20,
  height: 20,
  '& svg': {
    width: 18,
    height: 18,
  },
});

export const DeliveryLocationHeader = styled('p')({
  marginBottom: 0,
});
