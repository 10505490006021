import React from 'react';
import NumberFormat from 'react-number-format';

interface ProductPriceProps {
  price: string;
  salesPrice: string | null;
}

const ProductPrice = ({ price, salesPrice }: ProductPriceProps) => (
  <>
    {salesPrice ? (
      <NumberFormat
        value={Number(price).toFixed(2)}
        className="line-through"
        displayType="text"
        thousandSeparator
        prefix="$"
      />
    ) : null}
    <NumberFormat
      value={Number(salesPrice || price).toFixed(2)}
      className={salesPrice ? 'ml-1.5 text-[#EF868F]' : ''}
      displayType="text"
      thousandSeparator
      prefix="$"
    />
  </>
);

export default ProductPrice;
