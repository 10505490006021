import { Dayjs } from 'dayjs';

export const isDeliveryWindowDisabled = () => true;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isDateDeliveryChecker = (date: Dayjs) => true;

export const isValentinesPeriod = (date: Dayjs) => {
  const valentinesDates = [13, 14];
  const FEBRUARY = 1;
  if (!date) return false;
  if (date.month() === FEBRUARY && valentinesDates.includes(date.date())) {
    return true;
  }
  return false;
};

/**
 * @param date in MM-DD-YYYY format
 * @returns in MM/DD/YYYY format for Safari compatibility
 */
export const dateFormatter = (date: string | null) =>
  date ? date.replace(/-/g, '/') : null;
