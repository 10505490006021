import { styled } from '@material-ui/core';
import { down } from 'utils/breakpointHelpers';

export const HeaderContainer = styled('section')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 20,
  gap: 14,
  [down('md')]: {
    marginBottom: '12px',
  },
});
