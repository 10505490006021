import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';

/**
 * Get Rails's CSRF token from the <meta> tag
 */
function retrieveCsrfToken() {
  // eslint-disable-next-line no-undef
  return document.querySelector('meta[name="csrf-token"]').content;
}

/**
 * @see https://sortablejs.github.io/Sortable/
 */
export default class extends Controller {
  static get values() {
    return {
      url: String,
    };
  }

  connect() {
    this.sortable = Sortable.create(this.element, {
      onUpdate: this.update.bind(this),
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  update(event) {
    // Serializes the sortable's item data-id's (dataIdAttr option) into an
    // array of string.
    const ids = this.sortable.toArray();

    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': retrieveCsrfToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ids }),
    });
  }
}
