import React, { useState } from 'react';

import Image from 'ui-components/Image';
import ProductPrice from 'components/ProductPrice';
import { ToggleButton } from 'ui-components/v2/ToggleButton';
import { Action, AddonItemProps } from 'components/Addons/interface';
import {
  ButtonStyled,
  CounterContainerStyled,
  CounterStyled,
} from 'components/Addons/styled';

import {
  RootStyled,
  CardActionsStyled,
  LinkStyled,
  CardHeaderStyled,
} from './styled';

import DetailsModal from '../../DetailsModal';

const AddonItem = ({ addon, onSelect, selected, quantity }: AddonItemProps) => {
  const [open, setOpen] = useState(false);

  const handleClick = (action: Action) => {
    onSelect(addon, action);
  };

  const { name, price, sales_price: salesPrice, cloudinary_id: image } = addon;

  return (
    <>
      <RootStyled>
        <Image src={image} alt={name} width="100%" isSquare />
        <CardHeaderStyled variant="h3">{name}</CardHeaderStyled>
        <p className="m-0 text-lg leading-tight text-black md:text-xl">
          <ProductPrice price={price} salesPrice={salesPrice} />
        </p>
        <LinkStyled
          variant="body2"
          className="tracking-add-on"
          onClick={() => setOpen(true)}
        >
          View Details
        </LinkStyled>
        <CardActionsStyled disableSpacing>
          {selected ? (
            <CounterContainerStyled>
              <ButtonStyled
                onClick={() => handleClick('decrease')}
                className="tracking-decrease-add-on-qty"
              >
                -
              </ButtonStyled>
              <CounterStyled variant="button">{quantity}</CounterStyled>
              <ButtonStyled
                onClick={() => handleClick('increase')}
                className="tracking-increase-add-on-qty"
              >
                +
              </ButtonStyled>
            </CounterContainerStyled>
          ) : (
            <ToggleButton
              value="selected"
              selected={false}
              className="tracking-select-add-on"
              onClick={() => handleClick('increase')}
            >
              Select
            </ToggleButton>
          )}
        </CardActionsStyled>
      </RootStyled>
      <DetailsModal open={open} setOpen={setOpen} {...addon} />
    </>
  );
};

export default AddonItem;
