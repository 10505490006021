import React from 'react';
import { Slide, useMediaQuery } from '@material-ui/core';

import { up } from 'utils/breakpointHelpers';
import { Collection } from './Collection';
import { FilterBy } from './FilterBy';
import { Occasion } from './Occasion';
import { FlowerType } from './FlowerType';
import { PriceSelect } from './PriceSelect';
import { ResetButton } from './ResetButton';
import { FiltersProps } from './interface';
import {
  CloseIcon,
  FilterHeader,
  FilterHeaderTypography,
  FiltersContainer,
  Separator,
} from './styled';

export function Filters({
  control,
  reset,
  filterCount,
  priceRange,
  open,
  hide,
}: FiltersProps) {
  const matches = useMediaQuery(up('lg'));

  if (!matches) return null;

  return (
    <Slide direction="right" in={open} mountOnEnter unmountOnExit>
      <FiltersContainer>
        <FilterHeader>
          <FilterHeaderTypography variant="caption">
            Filter {filterCount ? `(${filterCount})` : ''}
          </FilterHeaderTypography>
          <ResetButton onClick={reset} />
          <CloseIcon fontSize="medium" onClick={() => hide()} />
        </FilterHeader>
        <FilterBy control={control} />
        <Separator />
        <PriceSelect control={control} priceRange={priceRange} />
        <Separator />
        <Occasion control={control} />
        <Separator />
        <Collection control={control} />
        <Separator />
        <FlowerType control={control} />
      </FiltersContainer>
    </Slide>
  );
}
