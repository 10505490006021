import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { DATE_FORMAT } from 'constants/time';
import { useReactKey } from 'hocs/ReactKeyProvider/ReactKeyContext';
import { Pages } from 'constants/pages';
import { IS_SERVER } from 'constants/server';

import { isDateDeliveryChecker } from 'utils/dateHelpers';
import { GLOBAL_EVENTS } from 'events/constants';
import { useEventListener } from 'hooks/useEventListener';
import { ChangeDeliveryDateEventPayload } from './interface';
import { UnavailableDatesEvent } from '../interface';

function getInitialDateValue(reactKey: string) {
  if (typeof window === 'undefined') return null;

  const inputElement = document.querySelector(
    `[data-react-key='${reactKey}']`,
  ) as HTMLInputElement;

  if (!inputElement || !inputElement.value) {
    return null;
  }

  return dayjs(inputElement.value, DATE_FORMAT);
}

function dispatchDateChangeEvent(
  date: Dayjs,
  reactKey: string,
  shouldSubmitCartForm?: boolean,
) {
  const hiddenDateField = date.format(DATE_FORMAT);

  const changeDateEvent = new CustomEvent<ChangeDeliveryDateEventPayload>(
    `${GLOBAL_EVENTS[Pages.PRODUCT].changeDeliverDate}_${reactKey}`,
    {
      detail: {
        submitDate: hiddenDateField,
        reactKey,
        shouldSubmitCartForm: !!shouldSubmitCartForm,
      },
    },
  );
  document.dispatchEvent(changeDateEvent);
}

export const useDeliveryDates = (page: string) => {
  const reactKey = useReactKey();

  /**
   * This is a datepicker internal state,
   * does not get dispatched to form,
   * only needed inside this component
   * */
  const [initialDate, setInitialDateState] = useState<Dayjs | null>(
    getInitialDateValue(reactKey),
  );
  /** This is a form state,
   * this gets dispatched to the datepicker DOM element,
   * and the add to cart form uses this value.
   */
  const [formDate, setFormDate] = useState<Dayjs | null>(
    getInitialDateValue(reactKey),
  );

  const [unavailableDatesState, setUnavailableDates] = useState<string[]>([]);
  const [modalOpen, toggleModal] = useState<boolean>(false);

  const [datesInfoVisible, toggleDatesInfoVisible] = useState<boolean>(false);

  const setFormDateCb = useCallback(
    (date: Dayjs, preventSubmitOnCart?: boolean) => {
      if (!date) return;
      /** Should submit a form if changed from cart view */
      const shouldSubmitForm = !preventSubmitOnCart && page === Pages.CART;

      toggleDatesInfoVisible(true);
      setFormDate(date);

      dispatchDateChangeEvent(date, reactKey, shouldSubmitForm);
    },
    [page, reactKey, setFormDate, toggleDatesInfoVisible],
  );

  const handleDateChange = useCallback(
    (date: MaterialUiPickersDate) => {
      if (!date) return;
      setInitialDateState(date);

      dispatchDateChangeEvent(date, reactKey);
    },
    [reactKey, setInitialDateState],
  );

  /**
   * We only toggle a modal on Date accept (i.e. when user selects a date),
   * not when date changes programmatically
   * */
  const handleDateAccept = useCallback(
    (date: MaterialUiPickersDate) => {
      if (date) {
        if (isDateDeliveryChecker(date)) {
          setFormDateCb(date);
        } else {
          toggleModal(true);
        }
      }
    },
    [toggleModal],
  );

  const handleDeliveryDatesEvent = useCallback(
    (payload: UnavailableDatesEvent) => {
      const { unavailableDates } = payload;

      setUnavailableDates(unavailableDates);
    },
    [setUnavailableDates],
  );

  useEffect(() => {
    /**
     * prevent race conditions with jquery event handlers,
     * not a good solution but more of a workaround */
    function dispatchDate() {
      setFormDateCb(initialDate!, true);
    }

    if (!IS_SERVER) {
      window.addEventListener('load', dispatchDate);
    } else {
      dispatchDate();
    }

    return () => {
      window.removeEventListener('load', dispatchDate);
    };
  }, []);

  useEventListener<UnavailableDatesEvent>(
    GLOBAL_EVENTS[Pages.SHOP].dispatchUnavailableDates,
    handleDeliveryDatesEvent,
  );

  return {
    initialDate,
    formDate,
    unavailableDatesState,
    modalOpen,
    datesInfoVisible,
    toggleModal,
    toggleDatesInfoVisible,
    setFormDateCb,
    handleDateChange,
    handleDateAccept,
  };
};
