import React, { useState } from 'react';
import { useWatch } from 'react-hook-form';

import Addons from 'components/Addons/PDP';
import SubscriptionForm from 'components/Modals/SubscriptionForm';
import SubscriptionLearnMore from 'components/Modals/SubscriptionLearnMore';
import { ATC } from 'components/ATC';
import { Button } from 'ui-components/v2/Button';
import { PurchaseFormFieldNames as FIELD_NAMES } from 'constants/form';

import { HookFormProps, PDPProps } from '../interface';
import { CTAContainerStyled, LinkStyled, DisclaimerStyled } from './styled';

const ProductDetails = ({
  product,
  loading,
  onSubmit,
  handleSubmit,
  setValue,
  control,
}: Pick<PDPProps, 'product'> & HookFormProps) => {
  const [subscribeOpen, setSubscribeOpen] = useState(false);
  const [learnMoreOpen, setLearnMoreOpen] = useState(false);
  const date = useWatch({ control, name: FIELD_NAMES.date });
  const noStock = useWatch({ control, name: FIELD_NAMES.noStock });
  const zipCode = useWatch({ control, name: FIELD_NAMES.zipCode });
  const subscription = useWatch({ control, name: FIELD_NAMES.subscription });

  return (
    <>
      <Addons
        date={date}
        quantity={1}
        productId={product.id}
        zipCode={zipCode}
        setValue={setValue}
        subscription={subscription}
      />

      <CTAContainerStyled>
        {!product.purchasable || noStock ? (
          <DisclaimerStyled variant="body2">
            Looks like this one got away!
          </DisclaimerStyled>
        ) : null}
        <ATC
          id="form-atc"
          control={control}
          product={product}
          loading={loading}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
        />
        {product.subscribable ? (
          <>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              onClick={() => setSubscribeOpen(true)}
              disabled={loading || !product.purchasable || !zipCode || noStock}
            >
              Make It A Recurring Delivery
            </Button>
            <LinkStyled onClick={() => setLearnMoreOpen(true)}>
              Learn more about recurring deliveries
            </LinkStyled>
            <SubscriptionForm
              id={product.id}
              purchasable={product.purchasable}
              control={control}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              setValue={setValue}
              open={subscribeOpen}
              setOpen={setSubscribeOpen}
            />
            <SubscriptionLearnMore
              open={learnMoreOpen}
              setOpen={setLearnMoreOpen}
              setFormOpen={setSubscribeOpen}
            />
          </>
        ) : null}
      </CTAContainerStyled>
    </>
  );
};

export default ProductDetails;
