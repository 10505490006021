import React from 'react';
import { Link } from '@material-ui/core';

import Image from 'ui-components/Image';
import { CardStyled, CardContentStyled, CTAStyled } from './styled';

interface FavoriteProductProps {
  cloudinary_photo_id: string;
  slug: string;
  name: string;
}

const Product = ({ name, cloudinary_photo_id, slug }: FavoriteProductProps) => (
  <CardStyled elevation={0}>
    <Link href={`/${slug}`}>
      <Image src={cloudinary_photo_id} alt={name} width="100%" isSquare />
    </Link>
    <CardContentStyled>
      <CTAStyled
        className="tracking-favorites-carousel-cta-button"
        variant="contained"
        color="primary"
        href={`/${slug}`}
      >
        {name}
      </CTAStyled>
    </CardContentStyled>
  </CardStyled>
);

export default Product;
