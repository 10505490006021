import { ArrowUpwardRounded } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';

const ScrollToTop = () => {
  const [displayStyle, setDisplay] = useState(-52);

  function handleClick(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  useEffect(() => {
    const scrollFunction = () => {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        setDisplay(16);
      } else {
        setDisplay(-52);
      }
    };
    window?.addEventListener('scroll', scrollFunction);
    return () => window?.removeEventListener('scroll', scrollFunction);
  }, []);
  return (
    <div
      className="fixed right-5 z-50 transition-all duration-500"
      style={{ bottom: displayStyle }}
    >
      <a
        className="flex h-12 w-24 cursor-pointer items-center justify-center gap-1 rounded-full bg-white text-black shadow-md hover:shadow-xl"
        href="#scroll-to-top"
        tabIndex={-1}
        onClick={(event) => {
          handleClick(event);
        }}
      >
        <span className="text-base font-medium">Top</span>
        <ArrowUpwardRounded />
      </a>
    </div>
  );
};

export default ScrollToTop;
