import { Box, styled, Typography } from '@material-ui/core';

import { Button } from 'ui-components/v2/Button';
import { Select } from 'ui-components/v2/Select';
import { down } from 'utils/breakpointHelpers';

export const ContainerStyled = styled(Box)({
  padding: 20,
  paddingBottom: 32,
});

export const ActionButtonStyled = styled(Button)({
  width: 'auto',
  minWidth: '82px',
  [down('sm')]: {
    width: 'auto',
    minWidth: '78px',
    paddingInline: '20px',
  },
});

export const TitleStyled = styled(Typography)({
  marginBottom: 20,
  color: '#000',
  fontSize: '34px',
  lineHeight: '40px',
  [down('md')]: {
    fontSize: '24px',
    lineHeight: '30px',
  },
  [down('sm')]: {
    fontSize: '20px',
    lineHeight: '24px',
    marginBottom: '14px',
  },
});

export const BodyStyled = styled(Typography)({
  marginBottom: '18px',
  color: '#000',
  [down('sm')]: {
    fontSize: 16,
    lineHeight: '18px',
  },
});

export const StepStyled = styled(Typography)({
  marginBottom: '12px',
  fontSize: '18px',
  lineHeight: '24px',
  fontWeight: 600,
});

export const DateRangeContainerStyled = styled(Box)({
  marginBottom: 26,
  display: 'flex',
  gap: 14,
  [down('sm')]: {
    flexDirection: 'column',
    gap: 18,
  },
});

export const SelectStyled = styled(Select)({
  width: 210,
});
