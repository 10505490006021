import React, { useState } from 'react';
import Modal from 'ui-components/v2/Modal';

import { Typography } from '@material-ui/core';
import {
  ContainerStyled,
  ActionButtonStyled,
  TitleStyled,
  LinkStyled,
} from './styled';

interface IProps {
  fullName: string;
  businessName: string;
  street1: string;
  street2: string;
  region: string;
  phone: string;
  path: string;
}

const AddressDeleteConfirmation = ({
  fullName,
  businessName,
  street1,
  street2,
  region,
  phone,
  path,
}: IProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <LinkStyled onClick={() => setOpen(true)}>Remove</LinkStyled>
      <Modal
        open={open}
        setOpen={setOpen}
        primaryButton={
          <ActionButtonStyled
            href={path}
            color="primary"
            variant="contained"
            data-method="delete"
          >
            Yes, Remove Address
          </ActionButtonStyled>
        }
        secondaryButton={
          <ActionButtonStyled
            variant="contained"
            color="secondary"
            onClick={() => setOpen(false)}
          >
            Cancel
          </ActionButtonStyled>
        }
      >
        <ContainerStyled>
          <TitleStyled variant="h4">Remove This Address?</TitleStyled>
          <div>
            <Typography variant="body2">{fullName}</Typography>
            {businessName ? (
              <Typography variant="body2">{businessName}</Typography>
            ) : null}
            <Typography variant="body2">{street1}</Typography>
            {street2 ? (
              <Typography variant="body2">{street2}</Typography>
            ) : null}
            <Typography variant="body2">{region}</Typography>
            {phone ? (
              <Typography variant="body2">Phone: {phone}</Typography>
            ) : null}
          </div>
        </ContainerStyled>
      </Modal>
    </>
  );
};

export default AddressDeleteConfirmation;
