import { Dayjs } from 'dayjs';
import { getMaxDeliveryDate } from './getNextDeliveryDate';

const DATE_LABEL_FORMAT = 'dddd, MMMM D';

export function getDateLabel(date: Dayjs): string {
  const isFriday = date.day() === 5;
  const isSaturday = date.day() === 6;

  const startDateLabel = date.format(DATE_LABEL_FORMAT);

  const nextDayDateLabel = getMaxDeliveryDate(date).format(DATE_LABEL_FORMAT);

  const yearLabel = date.format('YYYY');

  return isFriday || isSaturday
    ? `${startDateLabel}, ${yearLabel}`
    : `${startDateLabel} - ${nextDayDateLabel}, ${yearLabel}`;
}
