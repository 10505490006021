import { InputBase } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { COLORS } from 'theme/colors';

export const TextArea = withStyles(() => ({
  root: {
    backgroundColor: COLORS.white,
    border: `1px solid ${COLORS.black30}`,
    padding: '10px 16px',
    height: 'max-content',
    borderRadius: 4,
    color: COLORS.black,
    width: '100%',
    '&::placeholder': {},
    '& > input::-webkit-outer-spin-button, & > input::-webkit-inner-spin-button':
      {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    '&:hover': {
      border: `1px solid ${COLORS.black}`,
    },
    '&.Mui-focused': {
      border: `2px solid ${COLORS.fluorescentPink}`,
    },
    '&&.Mui-disabled': {
      color: COLORS.black50,
      border: `1px solid ${COLORS.black10}`,
    },
  },
  error: {
    border: `1px solid ${COLORS.errorBorder}`,
    '&:hover': {
      border: `1px solid ${COLORS.errorBorder}`,
    },
  },
}))(InputBase);
