import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useFetch from 'use-http';
import { Button } from 'ui-components/v2/Button';
import { TextField } from 'ui-components/v2/TextField';
import { isMobile } from 'utils/breakpointHelpers';
import { TextArea } from 'ui-components/v2/TextArea';
import { ApiRoutes } from 'constants/api';
import { BoldText, FormHeader } from './styled';

type InputFields = {
  first_name: string;
  last_name: string;
  company_name: string;
  email: string;
  occasion_type: string;
};

const schema = yup.object().shape({
  first_name: yup.string().required('First name is a required field.'),
  last_name: yup.string().required('Last name is a required field.'),
  company_name: yup.string().required('Company name is a required field.'),
  email: yup.string().email().required('email is a required field.'),
  occasion_type: yup.string().required('Occasion is a required field.'),
});

export const ContactForm = React.forwardRef<HTMLDivElement, any>((_, ref) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<InputFields>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });
  const [submitStatus, setSubmitStatus] = useState({ ok: false, status: '' });

  const {
    post: submitForm,
    loading,
    response,
  } = useFetch<any>(ApiRoutes.GetInTouch);

  const onSubmit = async (data: InputFields) => {
    await submitForm(data);
    if (response.ok) {
      reset({
        first_name: '',
        last_name: '',
        company_name: '',
        email: '',
        occasion_type: '',
      });
      setSubmitStatus({ ok: true, status: "Thanks, we'll be in touch soon!" });
    } else {
      setSubmitStatus({
        ok: false,
        status: 'An error occured, please try again.',
      });
    }
  };

  useEffect(() => {
    const clearStatus = setTimeout(() => {
      setSubmitStatus({ ...submitStatus, status: '' });
    }, 30000);

    return () => {
      clearTimeout(clearStatus);
    };
  }, [submitStatus]);

  return (
    <div ref={ref} className="px-4">
      <form
        className="mx-auto my-10 w-full rounded-sm border border-solid border-[#B3B3B3] px-8 py-10 sm:w-[600px]"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormHeader>
          <BoldText color="#e50077">Interested?</BoldText> Get in touch!
        </FormHeader>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Controller
              name="first_name"
              control={control}
              render={({ field }) => (
                <TextField {...field} placeholder="First name" fullWidth />
              )}
            />
            <p className="error">{errors.first_name?.message}</p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Controller
              name="last_name"
              control={control}
              render={({ field }) => (
                <TextField {...field} placeholder="Last name" fullWidth />
              )}
            />
            <p className="error">{errors.last_name?.message}</p>
          </Grid>
        </Grid>
        <Controller
          name="company_name"
          control={control}
          render={({ field }) => (
            <TextField {...field} placeholder="Company name" fullWidth />
          )}
        />
        <p className="error">{errors.company_name?.message}</p>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField {...field} placeholder="Email" fullWidth />
          )}
        />
        <p className="error">{errors.email?.message}</p>
        <Controller
          name="occasion_type"
          control={control}
          render={({ field }) => (
            <TextArea
              {...field}
              placeholder="Tell us more (type of occasion–birthday, anniversary, etc)..."
              fullWidth
              multiline
              rows={4}
              type="textarea"
            />
          )}
        />
        <p className="error">{errors.occasion_type?.message}</p>

        {submitStatus.status && (
          <p className={submitStatus.ok ? 'success' : 'error'}>
            {submitStatus.status}
          </p>
        )}
        <Button
          type="submit"
          disabled={loading}
          variant="contained"
          color="primary"
          fullWidth={isMobile()}
        >
          {loading ? 'Submitting...' : 'Submit'}
        </Button>
      </form>
    </div>
  );
});
