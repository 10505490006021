/* eslint-disable no-plusplus */
import { Addon, AddonsUpdaterArgs } from '../interface';

export const MAX_ADDON_PER_PRODUCT = 3;

export const addonsUpdater = ({
  addon,
  action,
  selectedAddons,
}: AddonsUpdaterArgs): Addon[] => {
  const newAddons = [...selectedAddons];
  const index = newAddons.map((a) => a.product_id).indexOf(addon.product_id);

  switch (action) {
    case 'increase':
      if (index !== -1) {
        newAddons[index].quantity++;
      } else {
        newAddons.push({
          ...addon,
          quantity: 1,
        });
      }
      break;
    case 'decrease':
      if (newAddons[index].quantity > 1) {
        newAddons[index].quantity--;
      } else {
        newAddons.splice(index, 1);
      }
      break;
    default:
      return selectedAddons;
  }

  return newAddons;
};
