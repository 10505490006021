import React, { MouseEvent, useState } from 'react';
import { Typography, Popover } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

const InfoPopover = ({ text }: { text: string }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div style={{ height: 24, marginInline: 6 }}>
      <InfoIcon
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={(e) => handlePopoverOpen(e)}
        onMouseLeave={handlePopoverClose}
        style={{ color: '#FF99D0' }}
      />
      <Popover
        id="mouse-over-popover"
        style={{
          pointerEvents: 'none',
        }}
        PaperProps={{
          style: {
            padding: 10,
            border: '1px solid #CCC',
            backgroundColor: '#F3E8E2',
            textAlign: 'center',
            maxWidth: 290,
            marginLeft: 20,
          },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography variant="body2">{text}</Typography>
      </Popover>
    </div>
  );
};

export default InfoPopover;
