import { createTheme } from '@material-ui/core';
import { COLORS } from './colors';

const HEADER_COMMON_STYLE = {
  fontFamily: ['quincy-cf', 'Arial', 'sans-serif'].join(','),
  margin: 0,
  fontWeight: 400,
};

export default createTheme({
  palette: {
    primary: {
      main: COLORS.fluorescentPink,
    },
    secondary: {
      main: COLORS.black,
    },
    action: {
      disabled: COLORS.black,
      disabledOpacity: 50,
    },
    text: {
      primary: COLORS.textMain,
      secondary: COLORS.textSecondary,
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1200,
      xl: 1536,
    },
  },

  shape: {
    borderRadius: 4,
  },

  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },

  typography: {
    fontFamily: ['brandon-grotesque', 'Arial', 'sans-serif'].join(','),
    allVariants: {
      color: COLORS.textMain,
      textTransform: 'none',
    },
    h1: {
      ...HEADER_COMMON_STYLE,
      fontSize: 90,
      lineHeight: '104px',
    },
    h2: {
      ...HEADER_COMMON_STYLE,
      fontSize: 60,
      lineHeight: '72px',
    },
    h3: {
      ...HEADER_COMMON_STYLE,
      fontSize: 48,
      lineHeight: '56px',
    },
    h4: {
      ...HEADER_COMMON_STYLE,
      fontSize: 34,
      lineHeight: '40px',
    },
    h5: {
      ...HEADER_COMMON_STYLE,
      fontSize: 24,
      lineHeight: '30px',
    },
    h6: {
      ...HEADER_COMMON_STYLE,
      fontSize: 20,
      lineHeight: '24px',
    },
    subtitle1: {
      fontSize: 24,
      lineHeight: '28px',
      fontWeight: 420,
    },
    subtitle2: {
      fontSize: 18,
      lineHeight: '24px',
      fontWeight: 400,
    },
    caption: {
      fontSize: 14,
      lineHeight: '16px',
      fontWeight: 400,
    },
    overline: {
      fontSize: 12,
      lineHeight: '14px',
      textTransform: 'uppercase',
      fontWeight: 400,
    },
    body1: {
      fontSize: 20,
      lineHeight: '24px',
      fontWeight: 400,
    },
    body2: {
      fontSize: 16,
      lineHeight: '18px',
      fontWeight: 400,
    },
    button: {
      fontSize: 18,
      lineHeight: '22px',
      fontWeight: 420,
      textTransform: 'capitalize',
      letterSpacing: '1px',
    },
  },

  overrides: {
    MuiInputBase: {
      root: {
        height: 42,
      },
    },
    MuiFormHelperText: {
      root: {
        color: `${COLORS.fluorescentPink} !important`,
        fontStyle: 'italic',
        fontSize: 16,
        lineHeight: '18px',
        fontWeight: 400,
        marginLeft: '0 !important',
      },
    },
    MuiLink: {
      root: {
        fontSize: 16,
        lineHeight: '18px',
        color: COLORS.fluorescentPink,
        '&:hover': {
          color: COLORS.fluorescentPink,
        },
      },
    },
    // @ts-expect-error

    MuiPickersBasePicker: {
      container: {
        overflowX: 'auto',
        flexDirection: 'column-reverse',
        '& .bullet': {
          width: 10,
          height: 10,
          marginRight: 8,
          borderRadius: '50%',
          display: 'inline-block',
        },
      },
      pickerView: {
        width: 500,
        height: 'auto',
        maxWidth: '100%',
        padding: '12px 28px 4px',
        justifyContent: 'flex-start',
        '@media (max-width: 599px)': {
          paddingInline: 18,
        },
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        fontSize: 18,
        color: COLORS.black,
        width: '100%',
      },
      daysHeader: {
        userSelect: 'none',
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        gap: 4,
      },
      switchHeader: {
        maxWidth: 325,
        marginInline: 'auto',
        userSelect: 'none',
        '& p': {
          fontSize: 24,
          fontWeight: '600',
        },
        '& button.Mui-disabled': {
          pointerEvents: 'none',
          opacity: 0.1,
        },
      },
    },
    MuiPickersCalendar: {
      week: {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        gap: 4,
        marginBottom: 4,
        width: '100%',
      },
      transitionContainer: {
        height: 312,
        '@media (max-width: 599px)': {
          height: 270,
        },
      },
    },
    MuiPickersDay: {
      day: {
        width: '100%',
        height: 48,
        padding: 0,
        margin: 0,
        borderRadius: 1,
        color: COLORS.black,
        fontSize: 15,
        border: `1px solid ${COLORS.black20}`,
        '& > span': {
          fontSize: 15,
          display: 'block',
        },
        '@media (max-width: 599px)': {
          height: 41,
        },
      },
      daySelected: {
        color: COLORS.black,
        background: `${COLORS.quicksand} !important`,
        border: `1px solid ${COLORS.black40}`,
        '& p': {
          fontWeight: 900,
        },
      },
      current: {},
      dayDisabled: {
        opacity: 0.3,
        background: `linear-gradient(to bottom right, white, white 49%, ${COLORS.black30} 50%, ${COLORS.black30} 50%, white 51%, white)`,
      },
      hidden: {
        pointerEvents: 'none',
        visibility: 'hidden',
      },
    },
  },
});
