import React from 'react';

import LivstickVideoCheck from './LivstickVideoCheck';
import { CartPageContainer } from './styled';

interface CartProps {
  cartId: number;
  sessionId: string;
  length: number;
  livstickVideo: boolean;
  deliveryDate: string;
  formAuthenticityToken: string;
  isSubscription: boolean;
  onlyGiftCardsInCart: boolean;
}

const Cart = ({
  length,
  cartId,
  sessionId,
  livstickVideo,
  deliveryDate,
  formAuthenticityToken,
  isSubscription,
  onlyGiftCardsInCart,
}: CartProps) => (
  <CartPageContainer>
    {length === 1 && !isSubscription && !onlyGiftCardsInCart ? (
      <LivstickVideoCheck
        livstickChecked={livstickVideo}
        deliveryDate={deliveryDate}
        sessionId={sessionId}
        cartId={cartId}
        formAuthenticityToken={formAuthenticityToken}
      />
    ) : null}
  </CartPageContainer>
);

export default Cart;
