import React from 'react';

import { DeliveryDates } from '../Filters/DeliveryDates';
import { WhereToDeliver } from '../Filters/WhereToDeliver';
import { FiltersProps } from './interface';
import { FiltersContainer } from './styled';

export function Filters({ control }: FiltersProps) {
  return (
    <FiltersContainer>
      <WhereToDeliver control={control} />
      <DeliveryDates control={control} />
    </FiltersContainer>
  );
}
