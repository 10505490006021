import { Box, styled, Typography } from '@material-ui/core';

import { Button } from 'ui-components/v2/Button';
import { down } from 'utils/breakpointHelpers';

export const ContainerStyled = styled(Box)({
  padding: 20,
  paddingBottom: 32,
});

export const ModalActionButtonStyled = styled(Button)({
  width: 'auto',
  minWidth: '82px',
  [down('sm')]: {
    width: 'auto',
    minWidth: '78px',
    paddingInline: '20px',
  },
});

export const ModalTitleStyled = styled(Typography)({
  marginBottom: 20,
  color: '#000',
  fontSize: '34px',
  lineHeight: '40px',
  [down('md')]: {
    fontSize: '24px',
    lineHeight: '30px',
  },
  [down('sm')]: {
    fontSize: '20px',
    lineHeight: '24px',
    marginBottom: '14px',
  },
});

export const ModalBodyStyled = styled(Typography)({
  marginBottom: '18px',
  color: '#000',
  [down('sm')]: {
    fontSize: 16,
    lineHeight: '18px',
  },
});
