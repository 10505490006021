/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useState } from 'react';
import Close from '@material-ui/icons/Close';
import {
  PopupButtonStyled,
  PopupContainer,
  Popup,
  PopupTitle,
  PopupText,
  PopupLink,
  PopupClose,
} from './styled';

const ShippingPopup: FC = () => {
  const [showPopup, setShow] = useState(false);

  return (
    <>
      <PopupButtonStyled
        role="dialog"
        aria-label="expedited shipping"
        onClick={() => {
          setShow(true);
        }}
      >
        ?
      </PopupButtonStyled>
      {showPopup && (
        <PopupContainer>
          <Popup>
            <PopupClose aria-label="Close">
              <Close
                onClick={() => {
                  setShow(false);
                }}
              />
            </PopupClose>
            <PopupTitle>Expedited Shipping</PopupTitle>
            <PopupText tabIndex={0}>
              We ship all of our packages using UPS Next Day Air or Priority Air
              delivery (usually called Expedited delivery) to ensure the
              freshness of the flowers upon delivery. We use this shipping
              method to ensure a quick delivery while maintaining the quality of
              your flowers!
            </PopupText>
            <PopupLink
              target="_blank"
              href="https://farmgirlflowers.com/help-center?a=Why-Does-It-Say%3A-%22-Expedited-Shipping%22-at-Check-out---id--08NHT9bqQU66d4RzeOSFaw"
            >
              Read More
            </PopupLink>
          </Popup>
        </PopupContainer>
      )}
    </>
  );
};

export default ShippingPopup;
