import { styled } from '@material-ui/core';
import { down } from 'utils/breakpointHelpers';

export const Container = styled('div')({
  margin: '40px 0 20px',
});

export const Separator = styled('div')({
  width: '100%',
  height: 1,
  backgroundColor: '#ECECEC',
  marginBottom: '20px',
  [down('md')]: {
    margin: '16px 0',
  },
});
