import React, { ReactNode } from 'react';
import MUIModal from '@material-ui/core/Modal';
import Close from '@material-ui/icons/Close';

import {
  ModalActionsStyled,
  ModalClose,
  ModalContainerStyled,
  ModalContentStyled,
  RedesignedBackdrop,
} from './styled';

interface ModalProps {
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  children: ReactNode;
  primaryButton: ReactNode;
  secondaryButton?: ReactNode;
  style?: React.CSSProperties;
}

const Modal = ({
  open,
  setOpen,
  children,
  primaryButton,
  secondaryButton,
  style,
}: ModalProps) => (
  <MUIModal
    open={open}
    onClose={() => setOpen(false)}
    keepMounted
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    BackdropComponent={RedesignedBackdrop}
  >
    <ModalContainerStyled style={style}>
      <ModalClose className="close-icon" onClick={() => setOpen(false)}>
        <Close />
      </ModalClose>
      <ModalContentStyled>{children}</ModalContentStyled>
      {primaryButton ? (
        <ModalActionsStyled>
          {secondaryButton}
          {primaryButton}
        </ModalActionsStyled>
      ) : null}
    </ModalContainerStyled>
  </MUIModal>
);

export default Modal;
