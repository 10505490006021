import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Popover } from '@material-ui/core';
import { DatePicker as MUIDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { UNAVAILABLE_DAY_DATE_FORMAT } from 'constants/time';
import { getNextAvailableDeliveryDate } from '../helpers/getNextDeliveryDate';
import { DeliveryDatePickerProps } from './interface';
import { MaxDateOffset } from '../interface';

// Make sure the availability_checker.rb #order_availability
// method is matching the max date offset
const DEFAULT_MAX_DATE = dayjs().add(6, 'month');

function getMaxDate(maxDateOffset: MaxDateOffset | undefined) {
  if (maxDateOffset) {
    return dayjs().add(maxDateOffset.amount, maxDateOffset.unit);
  }

  return DEFAULT_MAX_DATE;
}

function DatePicker({
  unavailableDates,
  onDateChange,
  onDateAccept,
  date,
  mountKey,
  maxDateOffset,
  datesInfoVisible,
}: DeliveryDatePickerProps) {
  const [anchorEl, setAnchorEl] = useState<any>();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: Event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleChange = (newDate: MaterialUiPickersDate) => {
    onDateChange(newDate);
  };

  const handleAccept = (newDate: MaterialUiPickersDate) => {
    handleClose();
    onDateAccept(newDate);
  };

  const shouldDisableDate = useCallback(
    (date_: MaterialUiPickersDate) => {
      if (!unavailableDates || !date_) return false;

      return unavailableDates.includes(
        date_?.format(UNAVAILABLE_DAY_DATE_FORMAT),
      );
    },
    [unavailableDates],
  );

  const initiallyHighlightedDate = useMemo(() => {
    if (!unavailableDates) return dayjs();

    const highlightDate = getNextAvailableDeliveryDate(
      dayjs(),
      unavailableDates,
    );
    return highlightDate;
  }, [unavailableDates]);

  const open = Boolean(anchorEl);
  const id = anchorEl ? 'date-picker' : undefined;

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const deliveryDateButton = document.querySelector(
      `[data-react-key='${mountKey}']`,
    );
    if (!deliveryDateButton) return;

    deliveryDateButton.parentElement!.setAttribute(
      'style',
      datesInfoVisible ? 'display: none' : 'display:block',
    );
  }, [datesInfoVisible, mountKey]);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const deliveryDateButton = document.querySelector(
      `[data-react-key='${mountKey}']`,
    );
    if (!deliveryDateButton) return;

    deliveryDateButton.addEventListener('click', handleClick);
  }, [mountKey]);

  const maxDate = getMaxDate(maxDateOffset);

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <MUIDatePicker
        disableToolbar
        variant="static"
        value={date}
        onChange={handleChange}
        onAccept={handleAccept}
        maxDate={maxDate}
        disablePast
        initialFocusedDate={initiallyHighlightedDate}
        shouldDisableDate={shouldDisableDate}
      />
    </Popover>
  );
}

export default DatePicker;
