import { styled } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { COLORS } from 'theme/colors';

export const FiltersButtonContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '40px',
  borderRadius: '2px',
  backgroundColor: '#F3F3F3',
  padding: '10px 12px',
  gap: 16,
  cursor: 'pointer',
});

export const IconContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 18,
  height: 18,
  position: 'relative',
});

export const StyledIcon = styled(ExpandMoreIcon)({
  marginLeft: 6,
  color: COLORS.black,
  '&.MuiSelect-icon.Mui-disabled': {
    color: COLORS.textSecondaryLight,
  },
});
