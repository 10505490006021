import { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';

export const useControl = () => {
  const [control, setControl] = useState<Control<any> | null>(null);

  useEffect(() => {
    const handleControlUpdate = () => {
      if (window.control) {
        setControl(window.control);
      }
    };

    window.addEventListener('controlUpdate', handleControlUpdate);

    return () => {
      window.removeEventListener('controlUpdate', handleControlUpdate);
    };
  }, []);

  return control;
};
