import { Controller } from '@hotwired/stimulus';
import { setInputValueWithEvent } from '../lib/dom_utils';

export default class extends Controller {
  static get targets() {
    return [
      'input',
      'spinner',
      'suggestionsList',
      'address2',
      'zipCode',
      'city',
    ];
  }

  connect() {
    this.isActive = true;
    this.suggestionClicked = false;
    this.hideAutocomplete();
  }

  inputTargetConnected(target) {
    this.setupEventListeners(target);
  }

  zipCodeTargetConnected(target) {
    this.setupEventListeners(target);
  }

  setupEventListeners(input) {
    const listener = this.updateSuggestions.bind(this);
    input.addEventListener('input', listener);
    input.addEventListener('blur', () => {
      // Add small timeout to ensure click event on suggestion is triggered first
      setTimeout(() => {
        if (!this.suggestionClicked) {
          this.hideAutocomplete();
        }
        this.suggestionClicked = false;
      }, 500);
    });
  }

  updateSuggestions(selected = '') {
    if (!this.isActive) {
      return;
    }

    this.showAutocomplete();

    const zipCode = this.zipCodeTarget.value; // Fetch zip code from the input field
    const searchKey = this.inputTarget.value;
    const urlApiPath = '/api/addresses/autocomplete';

    if (zipCode.length >= 5 && searchKey.length > 2) {
      let url = `${urlApiPath}?zip_code=${zipCode}&search_key=${encodeURIComponent(
        searchKey,
      )}`;
      if (selected.length > 0) {
        url += `&select_key=${encodeURIComponent(selected)}`;
      }

      this.showSpinner();
      this.hideSuggestionsList();

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          this.hideSpinner();
          this.displaySuggestions(data.results);
        })
        .catch((error) => {
          console.error('Error:', error);
          this.hideAutocomplete();
        });
    } else {
      this.hideSuggestionsList();
    }
  }

  displaySuggestions(results) {
    if (results.length > 0) {
      this.suggestionsListTarget.innerHTML = results
        .map((result) => {
          const node = document.createElement('li');
          node.classList.add('py-1');
          node.dataset.action = 'click->address-autocomplete#selectSuggestion';
          node.dataset.streetLine = result.street_line;
          node.dataset.city = result.city;
          node.dataset.zipCode = result.zipcode;
          node.dataset.state = result.state;
          node.dataset.secondary = result.secondary || '';
          node.dataset.entries = result.entries > 1 ? result.entries : 0;
          node.innerHTML = [
            result.street_line,
            result.secondary,
            result.city,
            `${result.state} ${result.zipcode}`,
          ]
            .filter((line) => line && line.length > 0)
            .join(', ');
          if (result.entries && result.entries > 1) {
            node.innerHTML += ` (${result.entries} entries)`;
          }
          return node.outerHTML;
        })
        .join('');

      this.showSuggestionsList();
    } else {
      this.hideAutocomplete();
    }
  }

  showSpinner() {
    this.spinnerTarget.style.display = 'block';
  }

  hideSpinner() {
    this.spinnerTarget.style.display = 'none';
  }

  showAutocomplete() {
    this.suggestionsListTarget.parentElement.style.display = 'block';
  }

  hideAutocomplete() {
    this.suggestionsListTarget.parentElement.style.display = 'none';
  }

  showSuggestionsList() {
    this.suggestionsListTarget.style.display = 'block';
  }

  hideSuggestionsList() {
    this.suggestionsListTarget.style.display = 'none';
  }

  selectSuggestion(event) {
    this.suggestionClicked = true;

    setTimeout(() => {
      this.suggestionClicked = false;
    }, 500);

    let dataset = event.currentTarget.dataset;

    if (dataset.entries && dataset.entries > 1) {
      this.updateSuggestions(this.buildSelected(dataset));
    } else {
      this.fillInAddressFields(dataset);
    }
  }

  // @see https://www.smarty.com/docs/cloud/us-autocomplete-pro-api#pro-secondary-expansion
  // the format for selected param is:
  // street_line secondary (entries) city state zipcode
  buildSelected(dataset) {
    const { streetLine, secondary, city, state, zipCode, entries } = dataset;
    return `${streetLine} ${secondary} (${entries}) ${city} ${state} ${zipCode}`;
  }

  fillInAddressFields(dataset) {
    const { streetLine, secondary, city, zipCode } = dataset;

    this.isActive = false;
    setInputValueWithEvent(this.inputTarget, streetLine); // Update delivery_address_1
    setInputValueWithEvent(this.address2Target, secondary); // Update delivery_address_2
    setInputValueWithEvent(this.cityTarget, city); // Update city
    setInputValueWithEvent(this.zipCodeTarget, zipCode); // Update zip code

    this.hideAutocomplete();
    this.isActive = true;
  }
}
