import { styled } from '@material-ui/core/styles';
import { down } from 'utils/breakpointHelpers';

export const PageContainer = styled('main')({
  padding: '20px 44px',
  [down('sm')]: {
    padding: 16,
  },
});

export const PageContent = styled('section')({
  display: 'flex',
  gap: 32,
  [down('lg')]: {
    display: 'block',
  },
});

export const TopSection = styled('section')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 15,
  [down('sm')]: {
    marginBottom: 0,
  },
});

export const Separator = styled('div')({
  width: '100%',
  height: 1,
  backgroundColor: '#ECECEC',
  marginBottom: '20px',
  [down('md')]: {
    margin: '16px 0',
  },
});
