import React, { FC, useState } from 'react';
import Close from '@material-ui/icons/Close';
import { isDeliveryWindowDisabled } from 'utils/dateHelpers';
import {
  PopupButtonStyled,
  PopupContainer,
  Popup,
  PopupTitle,
  PopupText,
  PopupLink,
  PopupClose,
} from './styled';

const DeliveryWindowPopup: FC = () => {
  const [showPopup, setShow] = useState(false);
  if (isDeliveryWindowDisabled()) return null;

  return (
    <>
      <PopupButtonStyled
        onClick={() => {
          setShow(true);
        }}
      >
        ?
      </PopupButtonStyled>
      {showPopup && (
        <PopupContainer>
          <Popup>
            <PopupClose
              aria-label="Close"
              onClick={() => {
                setShow(false);
              }}
            >
              <Close />
            </PopupClose>
            <PopupTitle>What is "Delivery Window"?</PopupTitle>
            <PopupText>
              Rather than selecting a single date, you will now see the option
              to select a two-day delivery window for your flowers. Select the
              two-day delivery window that works best for you and your
              recipient, and we will deliver on either the first or second day
              in your window. We do our very best to aim to deliver on the first
              day of the delivery window however, your order may still arrive on
              the second day of the delivery window.
            </PopupText>
            <PopupLink
              target="_blank"
              href="https://farmgirlflowers.com/help-center?a=What-is-a-delivery-window---id--96VTewyASqqWt7R0LkIsvA"
            >
              Read More
            </PopupLink>
          </Popup>
        </PopupContainer>
      )}
    </>
  );
};

export default DeliveryWindowPopup;
