import { useReducer, useEffect, useMemo, useState } from 'react';
import { FieldValues, UseFormWatch } from 'react-hook-form';
import useFetch, { CachePolicies } from 'use-http';

import { ApiRoutes } from 'constants/api';
import { safeJSONParse } from 'utils/safeJSONParse';
import { debounce } from 'utils/debounce';

import {
  ProductsReducerState,
  GetProductsResponseData,
  ProductsReducerAction,
} from './interface';
import { getFiltersQs } from './getFiltersQs';
import {
  ProductsReducerActionTypes,
  ProductsFormFieldNames as FIELD_NAMES,
} from './constants';

const initialState: ProductsReducerState = {
  products: [],
  page: 1,
  preventRequest: false,
  hasLoadedOnce: false,
};

function getInitialState(products_ssr: string): ProductsReducerState {
  const parsedProducts = safeJSONParse(products_ssr);
  let res = initialState;

  if (parsedProducts) {
    res = {
      ...res,
      products: parsedProducts.products ?? [],
      meta: parsedProducts.meta ?? {},
      preventRequest: true,
      hasLoadedOnce: true,
      page: parsedProducts.meta ? parsedProducts.meta.current_page : 1,
    };
  }

  return res;
}

function reducer(
  state: ProductsReducerState,
  action: ProductsReducerAction,
): ProductsReducerState {
  switch (action.type) {
    case ProductsReducerActionTypes.addProducts: {
      const { products, meta, page } = action.payload;

      return {
        ...state,
        products: [...state.products, ...products],
        meta,
        page,
        hasLoadedOnce: true,
      };
    }

    case ProductsReducerActionTypes.enableRequests:
      return { ...state, preventRequest: false };

    case ProductsReducerActionTypes.refetchProducts:
      return {
        ...state,
        products: [],
        page: 1,
      };

    case ProductsReducerActionTypes.refetchProductsSuccess: {
      const { products, meta } = action.payload;

      return {
        ...state,
        products,
        meta,
        page: 1,
        hasLoadedOnce: true,
      };
    }

    case ProductsReducerActionTypes.refetchProductsFail:
      return {
        ...state,
        products: [],
        meta: {
          current_page: 0,
          next_page: null,
          prev_page: null,
          total_pages: 1,
          total_records: 0,
        },
      };

    default:
      throw new Error();
  }
}

// Hook for Shop page Results list logic
export const useProducts = (
  watch: UseFormWatch<FieldValues>,
  products_ssr: string,
) => {
  const [state, dispatch] = useReducer(reducer, getInitialState(products_ssr));
  const [hasAborted, setAborted] = useState(false);

  const {
    get,
    loading,
    response,
    data: productsData,
    abort,
  } = useFetch<GetProductsResponseData>(ApiRoutes.Shop, {
    cachePolicy: CachePolicies.CACHE_AND_NETWORK,
  });

  useEffect(() => {
    if (productsData) {
      setAborted(false);
      if (!response.ok) {
        dispatch({
          type: ProductsReducerActionTypes.refetchProductsFail,
          payload: {},
        });

        return;
      }
      if (productsData.message) {
        dispatch({
          type: ProductsReducerActionTypes.refetchProductsSuccess,
          payload: { products: [] },
        });

        return;
      }

      const currentPage = productsData.meta?.current_page;

      if (currentPage === 1) {
        dispatch({
          type: ProductsReducerActionTypes.refetchProductsSuccess,
          payload: { products: productsData.products, meta: productsData.meta },
        });
      } else if (currentPage > 1 && !state.preventRequest) {
        dispatch({
          type: ProductsReducerActionTypes.addProducts,
          payload: {
            products: productsData.products,
            meta: productsData.meta,
            page: currentPage,
          },
        });
      }
    }
  }, [productsData, response]);

  const form = watch();

  const qs = useMemo(() => getFiltersQs(form), [JSON.stringify(form)]);

  const [resWithSoonestDate, setResWithSoonestDate] = useState(false);

  const refresh = () => {
    if (form[FIELD_NAMES.zipCode]) {
      setResWithSoonestDate(true);
    } else {
      setResWithSoonestDate(false);
    }

    dispatch({
      type: ProductsReducerActionTypes.refetchProducts,
      payload: {},
    });
  };

  // auto update results start
  const abortCurrentRequest = () => {
    abort();
    setAborted(true);
  };

  const autoUpdate = (queryString: string) => {
    if (form[FIELD_NAMES.zipCode]) {
      setResWithSoonestDate(true);
    } else {
      setResWithSoonestDate(false);
    }

    /** Auto scroll to top on filter applied */
    window?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    dispatch({
      type: ProductsReducerActionTypes.refetchProducts,
      payload: {},
    });

    get(queryString);
  };

  const debounced = useMemo(
    () => debounce((query: string) => autoUpdate(query), 500),
    [],
  );

  useEffect(() => {
    if (!state.preventRequest) {
      const params = new URLSearchParams(qs);
      params.delete(FIELD_NAMES.zipCode);
      params.delete(FIELD_NAMES.marketingCategory);
      const urlQs = params.toString();

      if (urlQs !== window.location.search) {
        window.history.replaceState(
          null,
          '',
          `${window.location.pathname}?${urlQs}`,
        );
      }

      abortCurrentRequest();
      debounced(`${qs}&page=1`);
    }
  }, [qs]);
  // auto update results end

  useEffect(() => {
    const pageQs = `page=${state.page}`;

    if (state.page > 1 && !state.preventRequest) get(`${qs}&${pageQs}`);
  }, [state.page]);

  useEffect(() => {
    const { preventRequest } = state;
    /**
     * If we have default state from SSR, prevent the current request
     * and allow subsequent requests
     *  */
    if (preventRequest) {
      dispatch({
        type: ProductsReducerActionTypes.enableRequests,
        payload: { preventRequest: false },
      });
    }
  }, []);

  const loadMore = () => {
    dispatch({
      type: ProductsReducerActionTypes.addProducts,
      payload: {
        products: [],
        meta: state.meta,
        page: state.page + 1,
      },
    });
  };

  return {
    products: state.products,
    loadMore,
    refresh,
    loading,
    hasLoadedOnce: state.hasLoadedOnce,
    hasMore: state.meta?.next_page || false,
    totalProducts: state.meta?.total_records || 0,
    resWithSoonestDate,
    hasAborted,
  };
};
