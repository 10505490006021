import React from 'react';
import { useMediaQuery } from '@material-ui/core';

import { down } from 'utils/breakpointHelpers';
import { HeaderContainer, PageTitle, Separator } from './styled';
import { PageHeaderProps } from './interface';
import { Filters } from './Filters';

const PageHeader = ({ title, control }: PageHeaderProps) => {
  const matches = useMediaQuery(down('sm'));

  return (
    <HeaderContainer>
      <PageTitle variant="h1">{title || 'Shop All'}</PageTitle>
      {matches && <Separator />}
      <Filters control={control} />
    </HeaderContainer>
  );
};

export default PageHeader;
