import React, { useCallback, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import Popover from '@material-ui/core/Popover';
import { DatePicker as MUIDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { UNAVAILABLE_DAY_DATE_FORMAT } from 'constants/time';
import { DeliveryDatePickerProps } from './interface';
import { getNextAvailableDeliveryDate } from '../helpers/getNextDeliveryDate';

const MAX_DATE = dayjs().add(6, 'month');

function DatePicker({
  date,
  mountKey,
  onDateChange,
  onDateAccept,
  unavailableDates,
}: DeliveryDatePickerProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | EventTarget>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: Event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleChange = (newDate: MaterialUiPickersDate) => {
    onDateChange(newDate);
  };

  const handleAccept = (newDate: MaterialUiPickersDate) => {
    handleClose();
    onDateAccept(newDate);
  };

  const shouldDisableDate = useCallback(
    (date_: MaterialUiPickersDate) => {
      if (!unavailableDates || !date_) return false;

      return unavailableDates.includes(
        date_?.format(UNAVAILABLE_DAY_DATE_FORMAT),
      );
    },
    [unavailableDates],
  );

  const initiallyHighlightedDate = useMemo(() => {
    if (!unavailableDates) return dayjs();

    const highlightDate = getNextAvailableDeliveryDate(
      dayjs(),
      unavailableDates,
    );
    return highlightDate;
  }, [unavailableDates]);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const deliveryDateButton = document.querySelector(
      `[data-react-key='${mountKey}']`,
    );
    if (!deliveryDateButton) return;

    deliveryDateButton.addEventListener('click', handleClick);
  }, [mountKey]);

  const open = Boolean(anchorEl);
  const id = anchorEl ? 'date-picker' : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl as HTMLElement}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <MUIDatePicker
        disablePast
        disableToolbar
        variant="static"
        value={date}
        maxDate={MAX_DATE}
        onChange={handleChange}
        onAccept={handleAccept}
        shouldDisableDate={shouldDisableDate}
        initialFocusedDate={initiallyHighlightedDate}
      />
    </Popover>
  );
}

export default DatePicker;
