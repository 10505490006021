import React, { useRef } from 'react';
import { Typography } from '@material-ui/core';
import { HERO_IMAGE } from './constants';
import {
  useCorporateGiftingStyles,
  StyledLink,
  OrderButton,
  HeroWrapper,
  CorporateGiftingWrapper,
  HeroImage,
  InquiriesInfoSection,
  InquiriesHeader,
  InquiriesInfo,
  HeroHeader,
  BoldText,
} from './styled';
import { ContactForm } from './contactForm';

const CorporateGifting = () => {
  const styles = useCorporateGiftingStyles();
  const formRef = useRef<HTMLDivElement>(null);

  const scrollToForm = () => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <CorporateGiftingWrapper>
      <HeroWrapper>
        <HeroImage src={HERO_IMAGE} alt="Corporate Gifting" />
        <div className={styles.heroText}>
          <HeroHeader variant="h1" className="!font-quincy !font-black">
            Why Farmgirl for your business?
          </HeroHeader>

          <Typography variant="body1" className={styles.paragraph}>
            We've been working with corporate accounts from our early days back
            in San Francisco. From recurring, weekly deliveries to add a little
            color to your lobby or reception desk to celebrating employee
            birthdays, work anniversaries and accomplishments, we have the
            perfect floral way to celebrate your team!
          </Typography>

          <OrderButton
            onClick={scrollToForm}
            variant="contained"
            color="primary"
          >
            Order Now
          </OrderButton>
        </div>
      </HeroWrapper>
      <InquiriesInfoSection>
        <InquiriesHeader>
          <BoldText>Got questions?</BoldText> We’ve got answers!
        </InquiriesHeader>
        <InquiriesInfo variant="body1">
          Send your inquiries to{' '}
          <StyledLink
            href="mailto:corphelp@farmgirlflowers.com"
            className="pink"
          >
            corphelp@farmgirlflowers.com
          </StyledLink>
          .
        </InquiriesInfo>
      </InquiriesInfoSection>

      {/* <div className={styles.description}>
              <Typography variant="h1">
                  <i>How it works? It's easy as...</i>
              </Typography>

              <Typography className={styles.paragraph}>
                  Got questions? We've got answers! Send your inquiries to{' '}
                  <StyledLink href="mailto:corphelp@farmgirlflowers.com" className="pink">
                    corphelp@farmgirlflowers.com
                  </StyledLink>.
              </Typography>

              <div className={styles.howItWorks}>
                  <div>
                      <div className={styles.infoBox}>
                          <div className={styles.infoNumber}>
                              <Typography variant="h1">1</Typography>
                          </div>
                          <Typography className={`${styles.paragraph} info-box`}>
                              <BoldText>
                                Select your preferred product and delivery window.
                              </BoldText>
                              We have an order minimum of 10 for bulk orders without customization
                              and a 50 minimum for orders including customizations.
                          </Typography>
                      </div>
                      <div className={styles.iconBox}>
                          <img src={SELECT_ICON} alt="select" width="80px" height="80px" />
                          <Typography className={`${styles.paragraph} info-box`}>
                              <BoldText>
                                  FIND OUR FULL SELECTION OF PRODUCTS ON OUR WEBSITE
                              </BoldText>
                          </Typography>
                      </div>
                  </div>
                  <div>
                      <div className={styles.infoBox}>
                          <div className={styles.infoNumber}>
                              <Typography variant="h1">2</Typography>
                          </div>
                          <Typography className={`${styles.paragraph} info-box`}>
                              Fill out the{' '}
                              <StyledLink
                                href="/bulk-order-form.xlsx"
                                download
                                target="_blank"
                                className="pink"
                              >
                                  bulk order address form
                              </StyledLink>
                              {' '}and send it to us at corphelp@farmgirlflowers.com
                          </Typography>
                      </div>
                      <div className={styles.iconBox}>
                          <img src={MONEY_ICON} alt="select" width="80px" height="80px" />
                          <Typography className={`${styles.paragraph} info-box`}>
                              <BoldText>
                                  WE OFFER TIERED DISCOUNT PRICING FOR ALL BULK ORDERS
                              </BoldText>
                          </Typography>
                      </div>
                  </div>
                  <div>
                      <div className={styles.infoBox}>
                          <div className={styles.infoNumber}>
                              <Typography variant="h1">3</Typography>
                          </div>
                          <Typography className={`${styles.paragraph} info-box`}>
                              <BoldText>
                                  Complete payment of the invoice sent to you via email and
                                  we'll process your order once payment has been received.
                              </BoldText>
                              {' '}We'll follow up with tracking numbers for your orders once they
                              have been shipped.
                          </Typography>
                      </div>
                      <div className={styles.iconBox}>
                          <img src={HEY_ICON} alt="select" width="80px" height="80px" />
                          <Typography className={`${styles.paragraph} info-box`}>
                              <BoldText>
                                  REACH OUT TO US IF YOU ARE INTERESTED IN CUSTOMIZATIONS
                              </BoldText><br />
                              Like add-on gifts, custom ribbon colors and custom branded
                              notecards!
                          </Typography>
                      </div>
                  </div>
              </div>
          </div> */}
      {/* <div className={styles.carouselArea}>
              <>
                  <Carousel images={PLANTS_CAROUSEL} />
                  <Typography variant="h1"><i>PLANTS</i></Typography>
                  <Typography className={`${styles.paragraph} carousel-desc`}>
                      Cure Zoom background fatigue (the Golden Gate Bridge? Again?) with a
                      colorful, chlorophyll companion!
                  </Typography>
              </>
              <>
                  <Carousel images={MARKET_BOUQUET_CAROUSEL} />
                  <Typography variant="h1"><i>MARKET BOUQUETS</i></Typography>
                  <Typography className={`${styles.paragraph} carousel-desc`}>
                      Tulips, ranunculus and roses - oh my. If we're talking the language of
                      flowers get straight to the thank you's and congratulations! with these
                      single variety grower bunches.
                  </Typography>
              </>
              <>
                  <Carousel images={HAND_TIED_ARRANGEMENT_CAROUSEL} />
                  <Typography variant="h1"><i>HAND-TIED ARRANGEMENTS</i></Typography>
                  <Typography className={`${styles.paragraph} carousel-desc`}>
                      Our tried and trues for your tried and trues! Celebrate the big milestones
                      with the signature burlap-wrapped bouquets and shippable vases that have built
                      our reputation for over a decade.
                  </Typography>
              </>
          </div> */}

      <ContactForm ref={formRef} />
    </CorporateGiftingWrapper>
  );
};

export default CorporateGifting;
