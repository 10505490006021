import { Backdrop, styled } from '@material-ui/core';
import { up } from 'utils/breakpointHelpers';

export const RedesignedBackdrop = styled(Backdrop)({
  background: 'rgba(0, 0, 0, 0.6)',
  '-webkit-backdrop-filter': 'blur(7px)',
  backdropFilter: 'blur(7px)',
});

export const ModalStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '1.5em',
  position: 'fixed',
  backgroundColor: '#fff',
  width: '350px',
  [up('sm')]: {
    width: '500px',
  },
  height: 'auto',
  maxHeight: '520px',
  borderRadius: '0.25em',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'auto',
  zIndex: 1000,
  textAlign: 'left',
});

export const ModalTitle = styled('h2')({
  color: '#000',
  margin: 0,
  marginBottom: '0.75em',
});
