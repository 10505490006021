/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="admin-line-item-editor"
export default class extends Controller {
  static get targets() {
    return ['inputToSubmitOnChange', 'btnRemoveItem'];
  }

  static get values() {
    return {
      url: String,
    };
  }

  static get outlets() {
    return ['select-two'];
  }

  // Product select2 outlet connected
  selectTwoOutletConnected(outlet, element) {
    outlet.select2.on('change', (event) => {
      this.submitForm();
    });
  }

  inputToSubmitOnChangeTargetConnected(target) {
    target.addEventListener('change', () => {
      this.submitForm();
    });
  }

  btnRemoveItemTargetConnected(element) {
    element.addEventListener('click', () => {
      // TODO: A way to do this in a more conventional Stimulus way?
      element.closest('.js-line-item-container').remove();
      this.submitForm();
    });
  }

  get form() {
    return this.element.closest('form');
  }

  // Submits the form using the urlValue defined on this controller.
  submitForm() {
    this.form.requestSubmit(this.hiddenButton);
  }

  /**
   * This adds a hidden button to the DOM and returns it, so it can be used with
   * requestSubmit() to submit the form to a different URL (as defined by the
   * urlValue).
   */
  get hiddenButton() {
    if (!this._hiddenButton) {
      // Add custom formaction to hidden button and requestSubmit()
      const hiddenButton = document.createElement('input');
      hiddenButton.formAction = this.urlValue;
      hiddenButton.formMethod = 'post';
      hiddenButton.type = 'submit';
      hiddenButton.name = 'commit';
      hiddenButton.value = '1';
      hiddenButton.style.display = 'none';
      const randomId = Math.random().toString(36);
      hiddenButton.id = `hidden-button-${randomId}`;
      this.form.appendChild(hiddenButton);
      this._hiddenButton = document.getElementById(hiddenButton.id);
    }

    return this._hiddenButton;
  }
}
