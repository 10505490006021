import React, { useState } from 'react';
import * as yup from 'yup';
import useFetch from 'use-http';
import { useForm } from 'react-hook-form';
import { Check } from '@material-ui/icons';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  CheckMobile,
  ErrorText,
  FormContainer,
  FormDisclaimer,
  FormHeader,
  FormInfo,
  InputSection,
  MaxContainer,
  SignUpFormsContainer,
  StyledButton,
  StyledInput,
  SubmitArrrow,
  SubmitText,
  SubscriptionSuccess,
} from './styled';
import { FormInput, SubscribeResponse } from './interface';

const schema = yup.object({
  email: yup.string().email('Please provide a valid email').nullable(),
  phone: yup
    .string()
    .test('Number', 'Input only numbers', (val) =>
      val?.length! > 0 ? /^[0-9]+$/.test(String(val)) : true,
    )
    .max(10, 'Maximum of 10 digits allowed')
    .nullable(),
});

const SignUpForms = () => {
  const {
    register,
    formState: { errors },
    resetField,
    handleSubmit,
    watch,
  } = useForm<FormInput>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const [errMsg, setMsg] = useState('');
  const [success, setSuccess] = useState({
    email: false,
    phone: false,
  });

  const { post } = useFetch<SubscribeResponse>();
  const { get: postEmail } = useFetch<SubscribeResponse>(
    `https://farmgirlflowers.us2.list-manage.com/subscribe/post-json?c=jQuery311007648359565875129_1673435499746&u=9044fc338d6c0b94f474622bf&id=ae21f72903&EMAIL=${
      watch()?.email
    }&b_9044fc338d6c0b94f474622bf_f97054b542=&_=1673435499748`,
    {
      mode: 'no-cors',
    },
  );

  const onSubmit = (data: FormInput, currentField: 'email' | 'phone') => {
    if (currentField === 'email' && data.email) {
      postEmail().then(() => {
        resetField(currentField);
        setSuccess((prev) => ({ ...prev, [currentField]: true }));
      });
    }
    if (currentField === 'phone' && data.phone) {
      post('/landing_pages/register_sms_sign_up', {
        phone: data.phone,
        authenticity_token: window?.FORM_AUTHENTICITY_TOKEN,
      }).then((res) => {
        if (res.result === 'success') {
          setMsg('');
          resetField(currentField);
          setSuccess((prev) => ({ ...prev, [currentField]: true }));
          return;
        }
        if (res.result === 'invalid') {
          setMsg('The submitted phone number is invalid.');
          return;
        }
        setMsg('There was an issue with your request, try again later.');
      });
    }
  };

  const disableEmailBtn = 'email' in errors;
  const disablePhoneBtn = 'phone' in errors;

  return (
    <SignUpFormsContainer>
      <MaxContainer>
        <FormContainer
          className="left"
          onSubmit={handleSubmit((data) => onSubmit(data, 'email'))}
        >
          <div>
            <FormHeader variant="body1">You've got (e)mail</FormHeader>
            <FormInfo variant="body2">
              Sign up for our newsletter to hear about our Deals of the Week,
              special sales and more.
            </FormInfo>
            <FormDisclaimer variant="body2">
              By submitting this form, you agree to receive recurring automated
              promotional and personalized marketing emails (e.g. cart
              reminders) from Farmgirl Flowers at the email address used when
              signing up. Consent is not a condition of any purchase. You may
              unsubscribe at any time.
            </FormDisclaimer>
            <InputSection>
              <StyledInput
                aria-label="Email Address"
                placeholder="Enter Email Address"
                type="email"
                {...register('email')}
              />
              <StyledButton
                disabled={disableEmailBtn}
                variant="contained"
                color="primary"
                type="submit"
              >
                <SubmitText>
                  {success.email ? (
                    <SubscriptionSuccess>
                      Done! <Check />
                    </SubscriptionSuccess>
                  ) : (
                    'Submit'
                  )}
                </SubmitText>
                {success.email ? <CheckMobile /> : <SubmitArrrow />}
              </StyledButton>
            </InputSection>
            <ErrorText>{errors.email?.message}</ErrorText>
          </div>
        </FormContainer>
        <FormContainer
          onSubmit={handleSubmit((data) => onSubmit(data, 'phone'))}
        >
          <div>
            <FormHeader variant="body1">Text us, maybe?</FormHeader>
            <FormInfo variant="body2">
              Get early access to collection launches and secret sales.
            </FormInfo>
            <FormDisclaimer variant="body2">
              By submitting this form, you agree to receive recurring automated
              promotional and personalized marketing text messages (e.g. cart
              reminders) from Farmgirl Flowers at the cell number used when
              signing up. Consent is not a condition of any purchase. Reply HELP
              for help and STOP to cancel. Msg frequency varies. Msg & data
              rates may apply. View <a href="https://attnl.tv/t/kPc">Terms</a> &{' '}
              <a href="https://attnl.tv/p/kPc">Privacy</a>.
            </FormDisclaimer>
            <InputSection>
              <StyledInput
                placeholder="Enter Phone Number"
                aria-label="Phone Number"
                {...register('phone')}
              />
              <StyledButton
                disabled={disablePhoneBtn}
                variant="contained"
                color="primary"
                type="submit"
              >
                <SubmitText>
                  {success.phone ? (
                    <SubscriptionSuccess>
                      Done! <Check />
                    </SubscriptionSuccess>
                  ) : (
                    'Submit'
                  )}
                </SubmitText>
                {success.phone ? <CheckMobile /> : <SubmitArrrow />}
              </StyledButton>
            </InputSection>
            <ErrorText>{errors.phone?.message || errMsg}</ErrorText>
          </div>
        </FormContainer>
      </MaxContainer>
    </SignUpFormsContainer>
  );
};

export default SignUpForms;
