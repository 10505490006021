import React from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox } from 'ui-components/v2/Checkbox';
import { FormControlLabel } from 'ui-components/v2/FormControlLabel';

import { CheckboxGroupProps } from './interface';
import { CheckboxGroupStyled } from './styled';

export const CheckboxGroup = ({
  fields,
  control,
  category,
}: CheckboxGroupProps): JSX.Element | null => {
  if (!fields) {
    return null;
  }

  return (
    <CheckboxGroupStyled>
      {fields.map((field, index) => {
        const id = `${category}.${index}.checked`;

        return (
          <div key={field.id}>
            <section>
              <Controller
                render={({ field: controllerField }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...controllerField}
                        checked={!!controllerField.value}
                      />
                    }
                    label={field.name}
                  />
                )}
                name={id}
                control={control}
              />
            </section>
          </div>
        );
      })}
    </CheckboxGroupStyled>
  );
};
